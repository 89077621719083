import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";
import { IconProps } from "./types";
export const PlaceMarkerIcon: FC<IconProps> = ({ width, height, color, viewBox, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "100"}
      height={height ? height : "104.036"}
      viewBox={viewBox ? viewBox : "0 0 100 104.036"}
      {...props}
    >
      <path
        id='icons8-place-marker'
        d='M51,.062A29.074,29.074,0,0,0,21.964,29.1c0,13.265,6.958,28.475,13.8,40.56A204.719,204.719,0,0,0,49.438,90.688a1.929,1.929,0,0,0,3.125,0A210.543,210.543,0,0,0,66.234,69.464c6.836-12.158,13.8-27.376,13.8-40.365A29.074,29.074,0,0,0,51,.062Zm0,3.906A25.083,25.083,0,0,1,76.13,29.1c0,11.572-6.576,26.562-13.281,38.477A187.048,187.048,0,0,1,51,86.2,178.488,178.488,0,0,1,39.151,67.771C32.453,55.938,25.87,40.98,25.87,29.1A25.083,25.083,0,0,1,51,3.969Zm0,12.7A14.518,14.518,0,1,0,65.518,31.182,14.552,14.552,0,0,0,51,16.664ZM51,20.7A10.482,10.482,0,1,1,40.518,31.182,10.444,10.444,0,0,1,51,20.7ZM30.492,71.091c-8.333,1.221-15.381,3.149-20.573,5.664a21.7,21.7,0,0,0-6.315,4.3A8.612,8.612,0,0,0,1,86.911c0,2.979,1.9,5.526,4.622,7.552a36.9,36.9,0,0,0,10.872,5.078C25.455,102.374,37.589,104.1,51,104.1s25.545-1.725,34.505-4.557a36.9,36.9,0,0,0,10.872-5.078C99.1,92.437,101,89.89,101,86.911a8.628,8.628,0,0,0-2.6-5.859,21.7,21.7,0,0,0-6.315-4.3c-5.192-2.515-12.24-4.443-20.573-5.664a2.109,2.109,0,1,0-.651,4.167c8.04,1.18,14.811,3.052,19.4,5.273A18.471,18.471,0,0,1,95.4,83.982a4.211,4.211,0,0,1,1.432,2.93c0,1.2-.8,2.572-2.93,4.167a33.535,33.535,0,0,1-9.635,4.492C75.886,98.215,64.053,99.932,51,99.932S26.114,98.215,17.732,95.57A33.535,33.535,0,0,1,8.1,91.078c-2.132-1.6-2.93-2.97-2.93-4.167A4.211,4.211,0,0,1,6.6,83.982a18.471,18.471,0,0,1,5.143-3.451c4.59-2.222,11.361-4.093,19.4-5.273a2.109,2.109,0,1,0-.651-4.167Z'
        transform='translate(-1 -0.063)'
        fill={color ? color : palette.primary[1200]}
      />
    </SvgIcon>
  );
};
