import React, { FC, MouseEvent } from "react";
import { useTranslation } from "@toolkit/i18n";
import { ConsumerCustomDialog } from "../../ConsumerCustomDialog/ConsumerCustomDialog";
import { ConsumerVariantProps } from "../types";
import { ConsumerErrorIcon } from "../../../../icons";

export const ConsumerDeleteConfirmationDialog: FC<ConsumerVariantProps> = ({
  isOpen,
  onClose,
  onDelete,
  isDeleteButtonDisabled = false,
  button,
  text,
  classes,
}) => {
  const { t } = useTranslation();
  const onConfirmationClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onDelete();
  };

  return (
    <ConsumerCustomDialog
      button={button}
      isDoButtonDisabled={isDeleteButtonDisabled}
      isOpen={isOpen}
      handleClose={onClose}
      handleDo={onConfirmationClick}
      DoTitle={t("Delete")}
      icon={<ConsumerErrorIcon />}
      color='error'
      text={{
        title: text?.title || t("Delete"),
        body: text?.body || t("Are you sure you want to delete this item?"),
      }}
      classes={classes}
    />
  );
};
