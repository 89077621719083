import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const HealthProgramBenefitGroupsAutocompleteDocument = gql `
    query HealthProgramBenefitGroupsAutocomplete($filter: BenefitFilterInput) {
  healthProgramBenefitGroups(filter: $filter) {
    id
    name
  }
}
    `;
/**
 * __useHealthProgramBenefitGroupsAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthProgramBenefitGroupsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramBenefitGroupsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramBenefitGroupsAutocompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramBenefitGroupsAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(HealthProgramBenefitGroupsAutocompleteDocument, options);
}
export function useHealthProgramBenefitGroupsAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(HealthProgramBenefitGroupsAutocompleteDocument, options);
}
