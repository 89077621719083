/* eslint-disable max-lines */
import {
  getHealthProgramBenefitGroupsAutocompleteFilter,
  getHealthProgramMemberListsAutocompleteFilter,
  getHealthProgramNetworksAutocompleteFilter,
  getSystemCodeAutocompleteFilter,
} from "@health/autocompletes";
import { CodeSystemCode, HealthProgram, HealthProgramSortingField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, IconButton, ProgramLogicIcon, TruncateTypography } from "@toolkit/ui";
import { healthProgramPaths } from "pages/HealthProgram/constants/health-program-paths";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ActiveToggleSwitch from "./ActiveToggle";
import { getAutocompleteEnumFilter } from "@health/enum-options";

export type HealthProgramsColumnTypes = CustomTableColumnProps<HealthProgram>;
export type HealthProgramsColumnsTypes = HealthProgramsColumnTypes[];

export const useHealthProgramsColumns = (canManageActions: boolean): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const handleNavigate = (id: string) => () => {
    navigate(healthProgramPaths.programLogicPath.fullPathWithParams(id));
  };

  const handleGoToNetwork = (id: string, programId: string) => () => {
    navigate(networksPaths.providersProgramPath.fullPathWithParams(id, programId));
  };

  const handleGoToMembers = (id: string, programId: string) => () => {
    navigate(`${programId}/member-lists/${id}/members`);
  };

  return useMemo(() => {
    return [
      {
        key: "Program_Name",
        header: t("Program Name"),
        accessor: ({ name }) => <TruncateTypography text={name!} />,
        type: "string",
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramSortingField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "description",
        header: t("Description"),
        type: "string",
        showOnlyForFilterField: true,
        filter: { type: "string", name: "description_Icontains" },
      },
      {
        key: "code",
        header: t("Program Code"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "network",
        header: t("Networks"),
        accessor: row =>
          row?.healthProgramNetworks?.edges?.map(edge => (
            <Box
              key={edge?.node?.id}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleGoToNetwork(edge?.node?.id, row?.id)}
            >
              <TruncateTypography text={edge?.node?.name!} />
            </Box>
          )),
        type: "string",
        filter: getHealthProgramNetworksAutocompleteFilter({ name: "healthProgramNetworks", multiple: true }),
      },
      {
        key: "specializations",
        header: t("Specializations"),
        type: "string",
        showOnlyForFilterField: true,
        filter: getSystemCodeAutocompleteFilter({
          name: "specializations",
          multiple: true,
          isCodeHidden: true,
          queryVariables: { codeSystemCode: CodeSystemCode.Speciality },
        }),
      },
      {
        key: "benefitGroups",
        header: t("Benefit Groups"),
        showOnlyForFilterField: true,
        filter: getHealthProgramBenefitGroupsAutocompleteFilter({ name: "benefitGroups", multiple: true }),
      },
      {
        key: "member_list",
        header: t("Member Lists"),
        accessor: row =>
          row?.healthProgramMemberLists?.edges?.map(edge => (
            <Box
              key={edge?.node?.id}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleGoToMembers(edge?.node?.id, row?.id)}
            >
              <TruncateTypography text={edge?.node?.name!} />
            </Box>
          )),
        type: "string",
        filter: getHealthProgramMemberListsAutocompleteFilter({ name: "healthProgramMemberLists", multiple: true }),
      },
      {
        key: "ProgramLogicParameters",
        header: t("Program Logic Parameters"),
        accessor: ({ id }) => {
          return (
            <IconButton onClick={handleNavigate(id)} sx={{ display: "flex", justifyContent: "center" }}>
              <ProgramLogicIcon />
            </IconButton>
          );
        },
        type: "string",
      },
      {
        key: "isActive",
        header: t("Active"),
        type: "string",
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramSortingField.IsActive,
        accessor: row => <ActiveToggleSwitch isDisabled={!canManageActions} row={row} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, []);
};
