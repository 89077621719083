import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramMemberListsAutocompleteQuery } from "./gql";
export const createHealthProgramMemberListAutocompleteOption = (memberList) => {
    return createAutocompleteOption({ id: memberList === null || memberList === void 0 ? void 0 : memberList.id, name: memberList === null || memberList === void 0 ? void 0 : memberList.name }, "id", "name");
};
export const getHealthProgramMemberListsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "healthProgramMemberLists",
        query: useHealthProgramMemberListsAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
