import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";
export const MoneyIconHealth: FC<IconProps> = ({ width = "26", height = "24.01", viewBox = "0 0 26 24.01", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-money'
        d='M4,4a2,2,0,0,0-.387.039l-.031.008A2,2,0,0,0,2,6V18a2,2,0,0,0,.039.387c0,.011.006.021.008.031A2,2,0,0,0,4,20H26a2,2,0,0,0,.387-.039l.031-.008A2,2,0,0,0,28,18V6a2,2,0,0,0-.039-.387c0-.011-.006-.021-.008-.031A2,2,0,0,0,26,4ZM6,6H24a2,2,0,0,0,2,2v8a2,2,0,0,0-2,2H6a2,2,0,0,0-2-2V8A2,2,0,0,0,6,6Zm9,2a4.272,4.272,0,0,0-4,4.5A4.694,4.694,0,0,0,12.514,16h4.973A4.694,4.694,0,0,0,19,12.5,4.272,4.272,0,0,0,15,8ZM7,11a1,1,0,1,0,1,1A1,1,0,0,0,7,11Zm16,0a1,1,0,1,0,1,1A1,1,0,0,0,23,11Zm2.984,10.986A1,1,0,0,0,25.84,22H25v2a2,2,0,0,0-1.852,1.248c-.956-.356-2.151-.776-3.7-1.211A57.027,57.027,0,0,0,4,22a1,1,0,1,0,0,2,54.988,54.988,0,0,1,14.908,1.963c1.743.49,3.1.978,4.059,1.346.48.184.86.338,1.15.451.145.057.266.1.383.143a1.1,1.1,0,0,0,.418.09c.028,0,.054.018.082.018A2,2,0,0,0,26.99,26.1a1,1,0,0,0,.01-.141V22h-.846a1,1,0,0,0-.17-.014Z'
        transform='translate(-2 -4)'
        fill='#e67e22'
      />
    </SvgIcon>
  );
};
