import { TeamMemberPosition, VendorUserTypes } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
import { pick } from "lodash";
export const teamMemberPositionOptionsMap = {
    [TeamMemberPosition.TeamAdmin]: {
        key: TeamMemberPosition.TeamAdmin,
        get label() {
            return i18n.t("Team Coordinator", { ns: "domains" });
        },
        value: TeamMemberPosition.TeamAdmin,
    },
    [TeamMemberPosition.TeamLeader]: {
        key: TeamMemberPosition.TeamLeader,
        get label() {
            return i18n.t("Team Leader", { ns: "domains" });
        },
        value: TeamMemberPosition.TeamLeader,
    },
    [TeamMemberPosition.Physician]: {
        key: TeamMemberPosition.Physician,
        get label() {
            return i18n.t("Clinician", { ns: "domains" });
        },
        value: TeamMemberPosition.Physician,
    },
    [TeamMemberPosition.DentalHygienist]: {
        key: TeamMemberPosition.DentalHygienist,
        get label() {
            return i18n.t("Dental Hygienist", { ns: "domains" });
        },
        value: TeamMemberPosition.DentalHygienist,
    },
    [TeamMemberPosition.DiabetesEducator]: {
        key: TeamMemberPosition.DiabetesEducator,
        get label() {
            return i18n.t("Diabetes Educator", { ns: "domains" });
        },
        value: TeamMemberPosition.DiabetesEducator,
    },
    [TeamMemberPosition.FitnessCoach]: {
        key: TeamMemberPosition.FitnessCoach,
        get label() {
            return i18n.t("Fitness Coach", { ns: "domains" });
        },
        value: TeamMemberPosition.FitnessCoach,
    },
    [TeamMemberPosition.Nutritionist]: {
        key: TeamMemberPosition.Nutritionist,
        get label() {
            return i18n.t("Nutritionist", { ns: "domains" });
        },
        value: TeamMemberPosition.Nutritionist,
    },
    [TeamMemberPosition.Optometrist]: {
        key: TeamMemberPosition.Optometrist,
        get label() {
            return i18n.t("Optometrist", { ns: "domains" });
        },
        value: TeamMemberPosition.Optometrist,
    },
    [TeamMemberPosition.PodiatricMedicalAssistant]: {
        key: TeamMemberPosition.PodiatricMedicalAssistant,
        get label() {
            return i18n.t("Podiatric Medical Assistant", { ns: "domains" });
        },
        value: TeamMemberPosition.PodiatricMedicalAssistant,
    },
    [TeamMemberPosition.Psychologist]: {
        key: TeamMemberPosition.Psychologist,
        get label() {
            return i18n.t("Psychologist", { ns: "domains" });
        },
        value: TeamMemberPosition.Psychologist,
    },
    [TeamMemberPosition.SocialWorker]: {
        key: TeamMemberPosition.SocialWorker,
        get label() {
            return i18n.t("Social Worker", { ns: "domains" });
        },
        value: TeamMemberPosition.SocialWorker,
    },
    [TeamMemberPosition.Nurse]: {
        key: TeamMemberPosition.Nurse,
        get label() {
            return i18n.t("Nurse", { ns: "domains" });
        },
        value: TeamMemberPosition.Nurse,
    },
};
export const teamMemberPositionOptions = Object.values(teamMemberPositionOptionsMap);
export const teamMemberNonManagementOptions = Object.values(teamMemberPositionOptionsMap).filter(item => !(item.value === TeamMemberPosition.TeamLeader || item.value === TeamMemberPosition.TeamAdmin));
export const teamMemberWithSpecializationOptions = Object.values(pick(teamMemberPositionOptionsMap, [TeamMemberPosition.TeamLeader, TeamMemberPosition.Physician]));
export const teamMemberPositionToVendorUserTypeMap = {
    [TeamMemberPosition.TeamLeader]: [VendorUserTypes.Doctor],
    [TeamMemberPosition.TeamAdmin]: [VendorUserTypes.Manager, VendorUserTypes.Nurse, VendorUserTypes.Receptionist],
    [TeamMemberPosition.Physician]: [VendorUserTypes.Doctor],
    [TeamMemberPosition.Nurse]: [VendorUserTypes.Nurse],
    [TeamMemberPosition.DentalHygienist]: [VendorUserTypes.DentalHygienist],
    [TeamMemberPosition.DiabetesEducator]: [VendorUserTypes.DiabetesEducator],
    [TeamMemberPosition.FitnessCoach]: [VendorUserTypes.FitnessCoach],
    [TeamMemberPosition.Nutritionist]: [VendorUserTypes.Nutritionist],
    [TeamMemberPosition.Optometrist]: [VendorUserTypes.Optometrist],
    [TeamMemberPosition.PodiatricMedicalAssistant]: [VendorUserTypes.PodiatricMedicalAssistant],
    [TeamMemberPosition.Psychologist]: [VendorUserTypes.Psychologist],
    [TeamMemberPosition.SocialWorker]: [VendorUserTypes.SocialWorker],
};
