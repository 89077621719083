import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { ImageUpload } from "../ImageUploads";
import { IImageUploadController } from "./types";
import { getImage } from "./utils";

export const ImageUploadController: FC<IImageUploadController> = ({
  control,
  name,
  defaultValue,
  rules,
  label,
  ratioImage = {
    width: 320,
    height: 450,
  },
  error,
  helperText,
  validationRation = false,
  canDeleteImage = false,
  handleDelete = () => {},
  token,
  hasMultipleImages,
  acceptedTypes,
}) => {
  const { t } = useTranslation();
  const defaultRules = {
    required: { value: false, message: t("required", "required") },
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules || defaultRules}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, ...props }) => {
        return (
          <ImageUpload
            {...props}
            label={label!}
            token={token}
            acceptsTypes={acceptedTypes}
            id={`${name}-id`}
            errorImage={error}
            helperText={helperText}
            ratioImage={ratioImage}
            validationRation={validationRation}
            // eslint-disable-next-line react/jsx-handler-names
            handleImageUploaded={fileName => {
              onChange(fileName);
            }}
            image={getImage(value) || null}
            canDeleteImage={canDeleteImage}
            onDelete={handleDelete}
            hasMultipleImages={hasMultipleImages}
          />
        );
      }}
    />
  );
};
