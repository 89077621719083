import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useUsersAutocompleteQuery } from "./gql";
export const createUserAutocompleteOption = (user) => {
    return createAutocompleteOption({ id: user === null || user === void 0 ? void 0 : user.id, fullName: user === null || user === void 0 ? void 0 : user.fullName }, "id", "fullName");
};
export const getUsersAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "users",
        query: useUsersAutocompleteQuery,
        labelBy: "fullName",
        backendAccessor: "id",
    });
};
