import React, { FC } from "react";
import type { SvgIconProps } from "../../base/mui";

export const LinearIcon: FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width ? width : 300} height={height ? height : 300} {...props}>
      <linearGradient id='linearColors' x1='1' y1='1' x2='0' y2='0'>
        <stop offset='20%' stopColor='#2F4646' />
        <stop offset='90%' stopColor='#82D047' />
        <stop offset='50%' stopColor='#517D47' />
      </linearGradient>
    </svg>
  );
};
