import { RequestStatus } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const requestStatusOptionsMap = {
    [RequestStatus.Approved]: {
        key: RequestStatus.Approved,
        get label() {
            return i18n.t("Approved", { ns: "domains" });
        },
        value: RequestStatus.Approved,
    },
    [RequestStatus.Pending]: {
        key: RequestStatus.Pending,
        get label() {
            return i18n.t("Pending", { ns: "domains" });
        },
        value: RequestStatus.Pending,
    },
    [RequestStatus.Rejected]: {
        key: RequestStatus.Rejected,
        get label() {
            return i18n.t("Rejected", { ns: "domains" });
        },
        value: RequestStatus.Rejected,
    },
};
export const requestStatusOptions = Object.values(requestStatusOptionsMap);
