import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramMembersAutocompleteQuery } from "./gql";
export const createHealthProgramMemberAutocompleteOption = (member) => {
    return createAutocompleteOption({
        id: member === null || member === void 0 ? void 0 : member.id,
        firstName: member === null || member === void 0 ? void 0 : member.firstName,
        lastName: member === null || member === void 0 ? void 0 : member.lastName,
    }, "id", item => `${item === null || item === void 0 ? void 0 : item.firstName} ${item === null || item === void 0 ? void 0 : item.lastName}`);
};
export const getHealthProgramMembersAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "healthProgramMembers",
        query: useHealthProgramMembersAutocompleteQuery,
        labelBy: option => `${option === null || option === void 0 ? void 0 : option.firstName} ${option === null || option === void 0 ? void 0 : option.lastName}`,
        backendAccessor: "id",
        filterSearchKey: "name",
    });
};
