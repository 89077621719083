import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PlusRemoveIcon: FC<SvgIconProps> = ({ width, height, fill, viewBox, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "29.602"}
      height={height ? height : "29.602"}
      viewBox={viewBox ? viewBox : "0 0 29.602 29.602"}
      {...props}
    >
      <path
        id='icons8-plus_1_'
        data-name='icons8-plus (1)'
        d='M10.466,0A10.466,10.466,0,1,0,20.932,10.466,10.466,10.466,0,0,0,10.466,0ZM15.7,11.338H11.338V15.7a.872.872,0,1,1-1.744,0V11.338H5.233a.872.872,0,0,1,0-1.744H9.594V5.233a.872.872,0,0,1,1.744,0V9.594H15.7a.872.872,0,0,1,0,1.744Z'
        transform='translate(29.602 14.801) rotate(135)'
        fill={fill ? fill : palette.stale.main}
      />
    </SvgIcon>
  );
};
