import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useSystemCodeAutocompleteQuery } from "./gql";
export const createSystemCodeAutocompleteOption = (systemCode, isCodeHidden = false) => {
    return createAutocompleteOption({ code: systemCode === null || systemCode === void 0 ? void 0 : systemCode.code, display: systemCode === null || systemCode === void 0 ? void 0 : systemCode.display }, "code", item => isCodeHidden ? item === null || item === void 0 ? void 0 : item.display : `${item === null || item === void 0 ? void 0 : item.code} - ${item.display}`);
};
export const getSystemCodeAutocompleteFilter = ({ name, isCodeHidden, multiple, queryFilter, queryVariables, }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "codeSystemConceptSearch",
        query: useSystemCodeAutocompleteQuery,
        labelBy: isCodeHidden ? "display" : option => `${option === null || option === void 0 ? void 0 : option.code} - ${option === null || option === void 0 ? void 0 : option.display}`,
        backendAccessor: "code",
        isFilterSearchKeyAQueryVariable: true,
        filterSearchKey: "keyword",
        filtersForQuery: queryFilter,
        queryVariables,
    });
};
