import { PathDefinition } from "./types";

export const replacePathParams = (path: string, params: { [key: string]: string }, prefix = ":") => {
  let newPath = path;

  Object.entries(params).forEach(([key, value]) => {
    newPath = newPath.replace(prefix + key, value || "");
  });

  return newPath;
};

export const createPathDefinition = <T extends Record<string, string>>(baseRoute: string, subRoutes: { [K in keyof T]: T[K] }) => {
  const normalizedBaseRoute = baseRoute?.replace(/\/$/, "");

  const result = {} as { [K in keyof T]: PathDefinition<T[K]> };

  Object.keys(subRoutes).forEach(key => {
    const route = subRoutes[key as keyof T];
    const fullPath = normalizedBaseRoute + (route ? `/${route}` : "");

    result[key as keyof T] = {
      route,
      fullPath,
      fullPathWithParams(params) {
        return replacePathParams(this.fullPath, params);
      },
    };
  });

  return result;
};
