import React, { FC } from "react";
import { CustomMuiDialog } from "../CustomMuiDialog/CustomMuiDialog.component";
import { useConsumerCustomDialogStyles } from "./ConsumerCustomDialogStyle";
import { Box, Button, DialogContent, Typography } from "@mui/material";
import { useTranslation } from "@toolkit/i18n";

type ConsumerCustomDialogProps = {
  isOpen: boolean;
  handleClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDo: (e: React.MouseEvent<HTMLButtonElement>) => void;
  DoTitle: string;
  isDoButtonDisabled?: boolean;
  text: {
    title: string;
    body: string;
  };
  button?: React.ReactNode;
  icon?: React.ReactNode;
  color: "warning" | "success" | "info" | "error";
  classes?: Partial<ReturnType<typeof useConsumerCustomDialogStyles>["classes"]>;
};

export const ConsumerCustomDialog: FC<ConsumerCustomDialogProps> = (props: ConsumerCustomDialogProps) => {
  const { isOpen, handleClose, handleDo, isDoButtonDisabled, text, button, icon, DoTitle, color, classes: _classes = {} } = props;

  const { classes, cx } = useConsumerCustomDialogStyles();
  const { t } = useTranslation();
  return (
    <CustomMuiDialog
      button={React.isValidElement(button) ? button : undefined}
      maxWidth={"xs"}
      open={isOpen}
      onClose={handleClose}
      DialogTitleProps={{
        hidden: true,
      }}
      DialogActionsProps={{
        className: cx(classes.actionsContainer, _classes.actionsContainer),
        children: (
          <>
            <Button
              color={color}
              className={cx(classes.button, _classes.button)}
              variant='contained'
              onClick={handleDo}
              disabled={isDoButtonDisabled}
            >
              {DoTitle ?? t("Do")}
            </Button>
            <Button className={cx(classes.closeButton, _classes.closeButton)} variant='text' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </>
        ),
      }}
    >
      <DialogContent className={cx(classes.contentContainer, _classes.contentContainer)}>
        <Box className={cx(classes.iconWrapper, _classes.iconWrapper)}>{icon}</Box>
        <Typography className={cx(classes.title, _classes.title)}>{text.title}</Typography>
        <Typography className={cx(classes.body, _classes.body)}>{text.body}</Typography>
      </DialogContent>
    </CustomMuiDialog>
  );
};
