import React, { FC } from "react";
import { DeleteConfirmationDialogProps } from "./types";
import { ConsumerDeleteConfirmationDialog } from "./variants/ConsumerDeleteConfirmationDialog";
import { DefaultDeleteConfirmationDialog } from "./variants/DefaultDeleteConfirmationDialog";

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = props => {
  if (props.variant === "consumer") {
    return <ConsumerDeleteConfirmationDialog {...props} />;
  }

  return <DefaultDeleteConfirmationDialog {...props} />;
};
