import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useMarketplaceProductCategoriesAutocompleteQuery } from "./gql";
export const createMarketplaceProductCategoryAutocompleteOption = (category) => {
    return createAutocompleteOption({
        id: category === null || category === void 0 ? void 0 : category.id,
        name: category === null || category === void 0 ? void 0 : category.name,
        nameAr: category === null || category === void 0 ? void 0 : category.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getMarketplaceProductCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "marketplaceProductCategories",
        query: useMarketplaceProductCategoriesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
        filterSearchKey: "nameContains",
    });
};
