import React, { FC, MouseEvent } from "react";
import { ConsumerVariantProps } from "../types";
import { useTranslation } from "@toolkit/i18n";
import { ConsumerWarningIcon } from "../../../../icons";
import { ConsumerCustomDialog } from "../../ConsumerCustomDialog/ConsumerCustomDialog";

export const ConsumerWarningConfirmationDialog: FC<ConsumerVariantProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirmButtonDisabled = false,
  button,
  text,
  classes,
}) => {
  const { t } = useTranslation();

  const onConfirmationClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onConfirm();
  };

  return (
    <ConsumerCustomDialog
      button={button}
      isDoButtonDisabled={isConfirmButtonDisabled}
      isOpen={isOpen}
      handleClose={onClose}
      handleDo={onConfirmationClick}
      DoTitle={t("confirm")}
      icon={<ConsumerWarningIcon />}
      color='warning'
      text={{
        title: text.title,
        body: text.body,
      }}
      classes={classes}
    />
  );
};
