import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useCustomForm } from "@toolkit/core";
import { FC, useEffect } from "react";
import { City } from "@health/queries/types/types";
import { FormActions, FormCard, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { citiesPaths } from "../../constants";
import { convertCityToFormValues } from "../../others";
import { cityUpsertFormSchema, cityUpsertFormDefaultValues, ICityUpsertFormValues } from "./CityUpsertFormSchema";

export type ICityUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: ICityUpsertFormValues;
  };
};

type CityUpsertFormProps = {
  city?: City;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: ICityUpsertFormEvent) => void;
};

export const CityUpsertForm: FC<CityUpsertFormProps> = props => {
  const { city, buttonLabel, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const form = useCustomForm<ICityUpsertFormValues>({
    defaultValues: cityUpsertFormDefaultValues,
    schema: cityUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: ICityUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(citiesPaths.list.fullPath);
  };

  useEffect(() => {
    if (city) {
      const _city = convertCityToFormValues(city);
      setValues(_city);
    }
  }, [city, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
              onNavigation={handleCancel}
            />
          }
        >
          <FormCard title={t("City Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"nameAr"} label={t("Name (Arabic)")} placeholder={t("Name (Arabic)")} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"area"} label={t("Area")} placeholder={t("Area")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
