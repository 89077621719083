import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { usePatientsAutocompleteQuery } from "./gql";
export const createPatientAutocompleteOption = (patient) => {
    return createAutocompleteOption({
        id: patient === null || patient === void 0 ? void 0 : patient.id,
        firstName: patient === null || patient === void 0 ? void 0 : patient.firstName,
        lastName: patient === null || patient === void 0 ? void 0 : patient.lastName,
    }, "id", item => `${item === null || item === void 0 ? void 0 : item.firstName} ${item === null || item === void 0 ? void 0 : item.lastName}`);
};
export const getPatientsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "patients",
        query: usePatientsAutocompleteQuery,
        labelBy: option => `${option === null || option === void 0 ? void 0 : option.firstName} ${option === null || option === void 0 ? void 0 : option.lastName}`,
        backendAccessor: "id",
        filterSearchKey: "firstName_Icontains",
    });
};
