import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
export const medicalFormClassificationSchema = createZodAutocompleteFromType({
    id: z.string(),
    code: z.string().nullish(),
    name: z.string().nullish(),
    nameAr: z.string().nullish(),
    medicalFormClassifications: z
        .array(z.object({
        id: z.string(),
        name: z.string(),
        rangeFrom: z.number().nullable(),
        rangeTo: z.number().nullable(),
    }))
        .nullish(),
});
