import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useVendorsAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const createVendorAutocompleteOption = (vendor) => {
    return createAutocompleteOption({
        id: vendor === null || vendor === void 0 ? void 0 : vendor.id,
        name: vendor === null || vendor === void 0 ? void 0 : vendor.name,
        nameAr: vendor === null || vendor === void 0 ? void 0 : vendor.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getVendorsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "vendors",
        query: useVendorsAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
    });
};
