import { HealthProgramNetwork, HealthProgramNetworkSortingField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, TruncateTypography, useTheme } from "@toolkit/ui";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveToggleSwitch } from "./ActiveToggle";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { getHealthProgramsAutocompleteFilter, getVendorsAutocompleteFilter } from "@health/autocompletes";

export type HealthProgramNetworkColumnTypes = CustomTableColumnProps<HealthProgramNetwork>;
export type HealthProgramsColumnsTypes = HealthProgramNetworkColumnTypes[];

export const useHealthProgramNetworksColumns = ({ canManageActions }): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const theme = useTheme();
  const handleGoToProviders = id => () => {
    navigate(networksPaths.providersPath.fullPathWithParams(id));
  };

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, id }) => {
          return (
            <Box sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleGoToProviders(id)}>
              <TruncateTypography text={name!} />
            </Box>
          );
        },

        type: "string",
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramNetworkSortingField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "Operator_Name",
        header: t("Operator Name"),
        accessor: ({ createdByUser }) => <TruncateTypography text={createdByUser?.firstName!} />,
        type: "string",
      },
      {
        key: "categoryCode",
        header: t("Category Code"),
        accessor: ({ categoryCode }) => <TruncateTypography text={categoryCode!} />,
        type: "string",
      },
      {
        key: "policyNumber",
        header: t("Policy Number"),
        accessor: ({ policyNumber }) => <TruncateTypography text={policyNumber!} />,
        type: "string",
      },
      {
        key: "Providers",
        header: t("Providers"),
        accessor: ({ id, providerCount }) => (
          <Box
            onClick={handleGoToProviders(id)}
            sx={{
              padding: "5px 10px",
              cursor: "pointer",
              textDecoration: "underline",
              "&:hover": {
                background: theme.palette.gray.light,
                width: "fit-content",
                borderRadius: "50%",
              },
            }}
          >
            {providerCount}
          </Box>
        ),
        type: "string",
      },
      {
        key: "isActive",
        header: t("Active"),
        type: "string",
        accessor: row => {
          return (
            <Box sx={{ display: "flex" }}>
              <ActiveToggleSwitch isDisabled={!canManageActions} id={row?.id!} name={row?.name!} isActive={row?.isActive!} />
            </Box>
          );
        },
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramNetworkSortingField.IsActive,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "health program list",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: getHealthProgramsAutocompleteFilter({ name: "healthPrograms", multiple: true }),
      },
      {
        key: "Vendors",
        header: t("Providers"),
        showOnlyForFilterField: true,
        filter: getVendorsAutocompleteFilter({ name: "providers", multiple: true }),
      },
    ];
  }, []);
};
