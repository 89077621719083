import { useEffect, useMemo, useState } from "react";
import { SelectionStatus, TableRowsSelectionStatus } from "../constants";
import { produce } from "immer";
import { get } from "lodash";
import { RowSelectionStatus } from "../types";

export interface UseTableSelectionProps<RowType> {
  rows: RowType[];
  isSelectVisible?: boolean;
  isRowSelectable?: boolean | ((row: RowType) => boolean);
  rowId?: string | ((row: RowType) => string);
  handleRowsSelectionStatusChange?: (rowsSelectionStatus: Record<string, RowSelectionStatus<RowType>>) => void;
  getRowSelectionStatus?: (rowId: string) => SelectionStatus | undefined;
}
export const useTableSelection = <RowType,>({
  rows,
  isRowSelectable,
  isSelectVisible,
  rowId,
  handleRowsSelectionStatusChange,
  getRowSelectionStatus,
}: UseTableSelectionProps<RowType>) => {
  const [rowsSelectionStatus, setSelectedRowIndex] = useState<Record<number, SelectionStatus>>({});
  const onRowSelectionStatusChange = (rowIndex: number, event: React.ChangeEvent<HTMLInputElement>): void => {
    const _rowIdentifier = typeof rowId === "function" ? rowId?.(rows[rowIndex]) : get(rows[rowIndex], rowId!);
    rowsSelectionStatus?.[rowIndex] !== SelectionStatus.Disabled &&
      setSelectedRowIndex(
        produce(rowsSelectionStatus, draft => {
          draft[rowIndex] = event?.target.checked ? SelectionStatus.Selected : SelectionStatus.NotSelected;
        })
      );
    handleRowsSelectionStatusChange?.({
      [_rowIdentifier]: {
        status: event.target.checked ? SelectionStatus.Selected : SelectionStatus.NotSelected,
        row: rows[rowIndex],
      },
    });
  };
  const onAllCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsStatusByIdentifiers: Record<string, RowSelectionStatus<RowType>> = {};
    const newSelectedRowIndex = produce(rowsSelectionStatus, draft => {
      rows.forEach((row, index) => {
        if (draft[index] !== SelectionStatus.Disabled) {
          draft[index] = event.target.checked ? SelectionStatus.Selected : SelectionStatus.NotSelected;
          const _rowIdentifier = typeof rowId === "function" ? rowId?.(rows[index]) : (get(rows[index], rowId!) as string);
          rowsStatusByIdentifiers[_rowIdentifier] = {
            status: event.target.checked ? SelectionStatus.Selected : SelectionStatus.NotSelected,
            row: row,
          };
        }
      });
    });
    setSelectedRowIndex(newSelectedRowIndex);
    handleRowsSelectionStatusChange?.(rowsStatusByIdentifiers);
  };
  const allRowsSelectionStatus = useMemo<TableRowsSelectionStatus>(() => {
    const rowsStatusesArray = Object.values(rowsSelectionStatus);
    const unSelectableRowsCount = rowsStatusesArray.filter(status => status === SelectionStatus.Disabled).length;
    const selectedRowsCount = rowsStatusesArray.filter(status => status === SelectionStatus.Selected).length;

    if (!selectedRowsCount) return TableRowsSelectionStatus.None;
    if (selectedRowsCount === rows.length - unSelectableRowsCount) return TableRowsSelectionStatus.All;
    return TableRowsSelectionStatus.Some;
  }, [rowsSelectionStatus]);

  useEffect(() => {
    const rowsStatusWithIdentifiers: Record<string, SelectionStatus> = {};
    if (isSelectVisible) {
      setSelectedRowIndex(
        produce(rowsStatusWithIdentifiers, draft => {
          rows.forEach((_, index) => {
            const _isRowSelectable = typeof isRowSelectable === "function" ? isRowSelectable(rows[index]) : isRowSelectable;
            const _rowIdentifier = typeof rowId === "function" ? rowId?.(rows[index]) : (get(rows[index], rowId!) as string);
            if (_isRowSelectable) {
              draft[index] = getRowSelectionStatus?.(_rowIdentifier) || SelectionStatus.NotSelected;
            } else {
              draft[index] = SelectionStatus.Disabled;
            }
          });
        })
      );
    }
  }, [rows, isRowSelectable, isSelectVisible]);
  return {
    onRowSelectionStatusChange,
    allRowsSelectionStatus,
    onAllCheckboxChange,
    rowsSelectionStatus,
  };
};
