import { useTranslation } from "@toolkit/i18n";
import { get } from "lodash";
import React, { ChangeEventHandler } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "../../base/mui";
import { EmptyDataIcon, SearchIcon } from "../../icons/icons";
import { Loader } from "../ContentLoader";
import { FormCard } from "../FormInputs";
import { useCustomListComponentStyle } from "./CustomTransferList.styles";

export const ListComponent = <T extends object>({
  items,
  handleToggle,
  checked,
  handleSearch,
  searchedValue,
  loading,
  isError,
  helperText,
}: {
  items: T | any | undefined;
  handleToggle: (value: T) => () => void;
  checked: T | any | undefined;
  handleSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  searchedValue: any;
  loading: boolean;
  isError?: boolean;
  helperText?: string;
}) => {
  const { t } = useTranslation();
  const { classes, theme } = useCustomListComponentStyle();
  return (
    <List
      sx={{
        bgcolor: theme.palette.background.paper,
        overflow: "scroll",
        height: "578px",
      }}
      dense
      component='div'
      role='list'
    >
      <ListItem role='listitem' className={classes.listItem}>
        <TextField
          sx={{
            width: "calc(90% + 14px)",
            padding: "10px",
            color: theme.palette.stale.main,
          }}
          hiddenLabel
          value={searchedValue}
          onChange={handleSearch}
          placeholder={t("Search")}
          disabled={loading}
          InputProps={{
            startAdornment: (
              <InputAdornment position='end'>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ListItem>
      {loading && <Loader />}
      {!loading &&
        (items as T[])?.map((value: T) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          // / todo fix this key
          return (
            <ListItem key={"" + value} role='listitem' className={classes.listItem} onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} id={labelId} primary={`${get(value, "name", "")}`} />
            </ListItem>
          );
        })}
      {!loading && !items.length && isError && (
        <FormCard doYouHaveData={true} loading={false}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "70px" }}>
            <EmptyDataIcon />
            <Typography
              color={theme.palette.error[900]}
              fontSize={theme.mixins.fonts.fontSize.lg}
              fontWeight={theme.mixins.fonts.fontWeight.medium}
            >
              {helperText}
            </Typography>
          </Box>
        </FormCard>
      )}
      <ListItem />
    </List>
  );
};

ListComponent.defaultProps = {
  isError: false,
  helperText: "",
};
