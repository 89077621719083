import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useMarketplaceHealthPackageCategoriesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const createMarketplaceHealthPackageCategoryAutocompleteOption = (category) => {
    return createAutocompleteOption({
        id: category === null || category === void 0 ? void 0 : category.id,
        name: category === null || category === void 0 ? void 0 : category.name,
        nameAr: category === null || category === void 0 ? void 0 : category.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getMarketplaceHealthPackageCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "marketplaceHealthPackageCategories",
        query: useMarketplaceHealthPackageCategoriesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
        filterSearchKey: "nameContains",
    });
};
