import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useMarketplaceBrandsAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const createMarketplaceBrandAutocompleteOption = (brand) => {
    return createAutocompleteOption({
        id: brand === null || brand === void 0 ? void 0 : brand.id,
        name: brand === null || brand === void 0 ? void 0 : brand.name,
        nameAr: brand === null || brand === void 0 ? void 0 : brand.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getMarketplaceBrandsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "marketplaceBrands",
        query: useMarketplaceBrandsAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
        filterSearchKey: "nameContains",
    });
};
