import React, { FC } from "react";

import { WarningConfirmationDialogProps } from "./types";
import { ConsumerWarningConfirmationDialog } from "./Variants/ConsumerWarningConfirmationDialog";
import { DefaultWarningConfirmationDialog } from "./Variants/DefaultWarningConfirmationDialog";

export const WarningConfirmationDialog: FC<WarningConfirmationDialogProps> = props => {
  if (props.variant === "consumer") {
    return <ConsumerWarningConfirmationDialog {...props} />;
  }
  return <DefaultWarningConfirmationDialog {...props} />;
};
