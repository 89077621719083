import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramsAutocompleteQuery } from "./gql";
export const createHealthProgramAutocompleteOption = (program) => {
    return createAutocompleteOption({ id: program === null || program === void 0 ? void 0 : program.id, name: program === null || program === void 0 ? void 0 : program.name }, "id", "name");
};
export const getHealthProgramsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "healthPrograms",
        query: useHealthProgramsAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
