/* eslint-disable max-lines */
import { H_OrderDirection, HealthProgramMembershipRequest, HealthProgramMembershipRequestSortingField } from "@health/queries/types";
import { toPascalCase, toTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";
import { ApproveInsuranceCardDialog } from "./components/ApproveInsuranceCardDialog";
import RejectInsuranceCardDialog from "./components/RejectInsuranceCardDialog";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { getPatientsAutocompleteFilter } from "@health/autocompletes";

export type InsuranceApprovalsColumnTypes = CustomTableColumnProps<HealthProgramMembershipRequest>[];

const formatDate = (createdDate?: null | string, i18n?: any) =>
  createdDate ? moment(createdDate).locale(i18n.language).format("DD MMM YYYY") : "";

export const useInsuranceCardsListColumns = (): InsuranceApprovalsColumnTypes => {
  const { t, i18n } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "insuranceId",
        header: t("Insurance Id"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMembershipRequestSortingField.InsuranceId,
        accessor: row => row?.insuranceId,
        filter: {
          type: "string",
          name: "insuranceId",
        },
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMembershipRequestSortingField.Name,
        accessor: ({ user }) => <TruncateTypography text={user?.fullName!} />,
        filter: getPatientsAutocompleteFilter({ name: "patientId" }),
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <TruncateTypography text={t(toPascalCase(status?.toLowerCase()!))} />,
        filter: getAutocompleteEnumFilter("RequestStatus", "status"),
      },
      {
        key: "coverageBy",
        header: t("Coverage By"),
        accessor: row => <Box sx={{ textAlign: "center" }}>{t(toTitleCase(row?.healthProgramMember?.coverageBy || "-"))}</Box>,
        filter: getAutocompleteEnumFilter("CoverageByType", "coverageBys"),
      },
      {
        key: "membershipStartFilterFrom",
        header: t("Membership Start Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipStart.gte",
        },
      },
      {
        key: "membershipStartFilterTo",
        header: t("Membership Start Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipStart.lte",
        },
      },
      {
        key: "membershipEndFilterFrom",
        header: t("Membership End Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipEnd.gte",
        },
      },
      {
        key: "membershipEndFilterTo",
        header: t("Membership End Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "membershipEnd.lte",
        },
      },
      {
        key: "created",
        header: t("Created"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMembershipRequestSortingField.Created,
        accessor: row => formatDate(row?.created, i18n),
      },
      {
        key: "membershipStartDate",
        header: t("Membership Start Date"),
        accessor: row => <Box sx={{ textAlign: "center" }}>{formatDate(row?.healthProgramMember?.membershipStart, i18n) || "-"}</Box>,
      },
      {
        key: "membershipEndDate",
        header: t("Membership End Date"),
        accessor: row => <Box sx={{ textAlign: "center" }}>{formatDate(row?.healthProgramMember?.membershipEnd, i18n) || "-"}</Box>,
      },
      {
        key: "approve",
        header: t("Approve"),
        accessor: row => <ApproveInsuranceCardDialog healthProgramMembershipRequest={row} />,
      },
      {
        key: "reject",
        header: t("Reject"),
        accessor: row => <RejectInsuranceCardDialog healthProgramMembershipRequest={row} />,
      },
      {
        key: "insuranceCompanyLicenseNumbers",
        header: t("Insurance Company License Number"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "insuranceCompanyLicenseNumbers",
          getValueForBackend: value => (value ? [value] : null),
        },
      },
    ];
  }, []);
};
