import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";
export const StackOfMoney: FC<IconProps> = ({ width = "26", height = "20", viewBox = "0 0 26 20", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-stack-of-money'
        d='M4,5a2,2,0,0,0-.387.039l-.031.008A2,2,0,0,0,2,7V19a2,2,0,0,0,.039.387c0,.011.006.021.008.031A2,2,0,0,0,4,21H26a2,2,0,0,0,.387-.039l.031-.008A2,2,0,0,0,28,19V7a2,2,0,0,0-.039-.387c0-.011-.006-.021-.008-.031A2,2,0,0,0,26,5ZM6,7H24a2,2,0,0,0,2,2v8a2,2,0,0,0-2,2H6a2,2,0,0,0-2-2V9A2,2,0,0,0,6,7Zm9,2a4.272,4.272,0,0,0-4,4.5A4.694,4.694,0,0,0,12.514,17h4.973A4.694,4.694,0,0,0,19,13.5,4.272,4.272,0,0,0,15,9ZM7,12a1,1,0,1,0,1,1A1,1,0,0,0,7,12Zm16,0a1,1,0,1,0,1,1A1,1,0,0,0,23,12ZM4,23a1,1,0,1,0,0,2H26a1,1,0,1,0,0-2Z'
        transform='translate(-2 -5)'
        fill='#e67e22'
      />
    </SvgIcon>
  );
};
