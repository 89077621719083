/* eslint-disable sonarjs/cognitive-complexity */
import { ApolloQueryResult, DefaultContext, NetworkStatus, OperationVariables, QueryHookOptions, QueryResult } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import { ExtractFilter, ExtractNodeType, ResponseDataAccessor } from "../types";

export type IRelayStyleConnectionProps<T, V extends OperationVariables> = {
  useQuery: (baseOptions?: QueryHookOptions<T, V>) => QueryResult<T, V>;
  variables?: V;
  searchKey?: keyof ExtractFilter<V>;
  skip?: boolean;
  context?: DefaultContext;
};

export type IRelayStyleConnectionReturn<T, V extends OperationVariables> = {
  data: ExtractNodeType<T>[];
  loading: boolean;
  hasMore: boolean;
  refetch: (variables?: Partial<V>) => Promise<ApolloQueryResult<T>>;
  handleFetchMore: () => void;
  handleSearch: (value: string) => void;
};

export const useRelayStyleConnection = <T, V extends OperationVariables>(
  props: IRelayStyleConnectionProps<T, V>
): IRelayStyleConnectionReturn<T, V> => {
  const { useQuery, variables, searchKey, skip, context } = props;

  const [search, setSearch] = useState<string | undefined>(undefined);

  const memoizedVariables = useMemo(() => {
    const localVariables = { first: 10, ...variables! };

    if ("filter" in localVariables) {
      return { ...localVariables!, filter: { ...localVariables?.filter, [searchKey!]: search } };
    }

    return { ...localVariables, [searchKey!]: search };
  }, [variables, searchKey, search]);

  const {
    data: queryData,
    loading: isQueryLoading,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery({
    variables: memoizedVariables,
    skip: skip || !memoizedVariables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    context,
  });

  const responseDataAccessorKey = queryData ? (Object.keys(queryData)[0] as keyof T) : undefined;
  const responseDataAccessor = responseDataAccessorKey ? (queryData?.[responseDataAccessorKey] as ResponseDataAccessor<T>) : undefined;
  const data = responseDataAccessor?.edges?.map(item => item?.node) || [];

  const hasMore = !!responseDataAccessor?.pageInfo?.hasNextPage;
  const loading = isQueryLoading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  const handleFetchMore = useCallback(() => {
    if (hasMore && !isQueryLoading) {
      fetchMore({
        variables: {
          ...variables,
          after: responseDataAccessor?.pageInfo?.endCursor,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousQueryResult;
          }

          const fetchMoreResultDataAccessorKey = queryData ? (Object.keys(fetchMoreResult)[0] as keyof T) : undefined;

          const fetchMoreResultDataAccessor = fetchMoreResultDataAccessorKey
            ? (queryData?.[fetchMoreResultDataAccessorKey] as ResponseDataAccessor<T>)
            : undefined;

          return {
            ...fetchMoreResult,
            [responseDataAccessorKey!]: {
              ...fetchMoreResult?.[responseDataAccessorKey!],
              edges: [...(fetchMoreResultDataAccessor?.edges || []), ...(fetchMoreResultDataAccessor?.edges || [])],
            },
          };
        },
      });
    }
  }, [fetchMore, hasMore, loading, variables]);

  const handleSearch = (value: string) => {
    if (!searchKey) return;
    setSearch(value);
  };

  return {
    data,
    loading,
    hasMore,
    refetch,
    handleFetchMore,
    handleSearch,
  };
};
