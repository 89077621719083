import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormDatePickerField, FormMDTextEditor, FormTextField, Grid } from "@toolkit/ui";
import React, { FC } from "react";

export const PromotionInformationForm: FC = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"name"} label={t("Name")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"nameAr"} label={t("Arabic Name")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"description"} label={t("Description")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"descriptionAr"} label={t("Arabic Description")} />
      </Grid>
      <Grid item xs={12}>
        <FormMDTextEditor name={"content"} label={t("Content")} />
      </Grid>
      <Grid item xs={12}>
        <FormMDTextEditor name={"contentAr"} label={t("Arabic Content")} />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <FormDatePickerField name='startDate' label={t("Start Date")} placeholder={t("Start Date")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormDatePickerField name='endDate' label={t("End Date")} placeholder={t("End Date")} />
      </Grid>
    </Grid>
  );
};
