import { OperationVariables } from "@apollo/client";
import React from "react";
import { GridContext } from "./GridContext";
import { useDataGridState } from "./hooks/useDataGridState";
import { useDeleteTableRow } from "./hooks/useDeleteTableRow";
import { useGridFilter } from "./hooks/useGridFilter";
import { IGridProviderBase } from "./types";

const useDefaultDeleteTableRow = () => {
  return {
    handleDeleteItem: (row: unknown) => {
      console.log("handleDeleteItem is not implemented", row);
    },
  };
};
export const GridProviderBase = <RowType extends object = any, TData = any, TVar extends OperationVariables = Record<string, any>>(
  props: IGridProviderBase<RowType, TData, TVar>
) => {
  const {
    children,
    query,
    columns: defaultColumns,
    deleteItemProps,
    dataAccessor,
    extractCountableConnectionData,
    tableAction,
    filterInput,
    variables,
    onCompleted,
    onError,
    pageSize,
    context,
    skipCall = false,
    fetchPolicy,
    nextFetchPolicy,
    hideFilterInput = false,
    hasSavedInput = false,
    isCardView,
    selectionProps,
    ...tablePreference
  } = props;
  const {
    customTableProps,
    data: queryResponse,
    handleFilter,
    refetch,
    isLoading,
    selectedRows,
    onRemoveSelectedRow,
    onResetSelectedRows,
  } = useDataGridState({
    onCompleted: onCompleted,
    useCustomQuery: query,
    onError: onError,
    dataAccessor: dataAccessor,
    extractCountableConnectionData,
    columns: defaultColumns,
    tablePreference,
    skip: skipCall,
    fetchPolicy,
    nextFetchPolicy,
    size: pageSize,
    tableAction,
    hasSavedInput,
    context,
    selectionProps,
    input: {
      first: pageSize ? pageSize : 10,
      filter: filterInput ? filterInput : undefined,
      ...variables,
    } as any,
  });

  const fields = defaultColumns?.filter(item => item?.filter);
  const { staticFiltersProps, activeFiltersProps } = useGridFilter(handleFilter, filterInput!, hideFilterInput);

  const { handleDeleteItem } = deleteItemProps ? useDeleteTableRow(deleteItemProps) : useDefaultDeleteTableRow();

  const tableProps = {
    ...customTableProps,
    isCardView,
    onDeleteRow: deleteItemProps ? handleDeleteItem : undefined,
  };

  return (
    <GridContext.Provider
      value={{
        customTableProps: tableProps as any,
        staticFiltersProps,
        activeFiltersProps,
        tablePreference: {
          isColumnsLoading: !!tablePreference.isColumnsLoading,
          onColumnsChanged: tablePreference?.onColumnsChanged!,
          reOrderColumns: tablePreference.reOrderColumns,
        },
        fields,
        refetch,
        queryResponse,
        isLoading,
        rowId: selectionProps?.rowId,
        selectedRows,
        onRemoveSelectedRow,
        onResetSelectedRows,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
