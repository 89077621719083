/* eslint-disable react/no-unknown-property */
import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const VisaCardIcon: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='56.652' height='38.626' viewBox='0 0 56.652 38.626' {...props}>
      <defs>
        <linearGradient id='linear-gradient' x1='0.418' y1='45.202' x2='0.608' y2='46.198' gradientUnits='objectBoundingBox'>
          <stop offset='0' stop-color='#00b3ee' />
          <stop offset='1' stop-color='#0082d8' />
        </linearGradient>
      </defs>
      <g id='Group_5806' data-name='Group 5806' transform='translate(-174.5 -179)'>
        <path
          id='Path_3432'
          data-name='Path 3432'
          d='M54.95,9H5.7A3.7,3.7,0,0,0,2,12.7V43.925a3.7,3.7,0,0,0,3.7,3.7H54.95a3.7,3.7,0,0,0,3.7-3.7V12.7A3.7,3.7,0,0,0,54.95,9Z'
          transform='translate(172.5 170)'
          fill='url(#linear-gradient)'
        />
        <path
          id='Path_3433'
          data-name='Path 3433'
          d='M32.2,35.026a12.988,12.988,0,0,1-4.831-.912l-.847-.39-.229,1.069H19.8l2.374-11.1-4.651,11.1H11.581L8.246,21.9a1.6,1.6,0,0,0-.133-.412A1.2,1.2,0,0,0,7.96,21.4a14.746,14.746,0,0,0-3.474-1.146l-1.25-.272.572-2.708H11.43a3.065,3.065,0,0,1,3.045,2.561l.806,4.282,2.709-6.843H30.041l-.491,2.3A8.326,8.326,0,0,1,36.036,17a11.512,11.512,0,0,1,4.057.742l1.053.385L39.9,23.959l-1.461-.671a6.267,6.267,0,0,0-2.609-.588,4.106,4.106,0,0,0-.5.028,2.009,2.009,0,0,0-.223.04c.294.18.684.388.918.51,1.383.725,3.345,1.754,3.989,3.848l3.337-7.967a3.029,3.029,0,0,1,2.833-1.886H50.53l3.659,17.52H47.983l-.467-2.231h-3.01L43.7,34.793H36.8l.415-.99a9.813,9.813,0,0,1-5.009,1.223ZM29.154,28.5a6.809,6.809,0,0,0,3.274.825h.134a2.483,2.483,0,0,0,.95-.189,6.622,6.622,0,0,0-1.19-.686c-2.078-1.011-3.375-2.263-3.874-3.736l-.672,3.147Z'
          transform='translate(172.855 172.3)'
          opacity='0.05'
        />
        <path
          id='Path_3434'
          data-name='Path 3434'
          d='M32.031,34.238a12.291,12.291,0,0,1-4.562-.852L27,33.17l.977-4.569.733.342a7.451,7.451,0,0,0,3.546.886h.14c.671-.01,1.674-.239,1.682-.827,0-.242-.2-.567-1.644-1.272-.936-.456-3.782-1.844-3.744-4.654.024-3.281,2.976-5.574,7.174-5.574a10.887,10.887,0,0,1,3.837.7l.527.192-.964,4.5-.73-.336a6.916,6.916,0,0,0-2.878-.648,4.723,4.723,0,0,0-.581.033c-.784.1-1.076.431-1.082.627-.006.306.865.765,1.566,1.133,1.631.855,3.864,2.028,3.852,4.778-.015,3.442-2.978,5.754-7.375,5.754h-.006ZM48.335,34l-.467-2.231H43.883L43.076,34H37.6l6.175-14.741a2.394,2.394,0,0,1,2.239-1.491h3.824L53.227,34H48.335Zm-1.376-6.583L46.413,24.8l-.95,2.618ZM20.422,34l3.472-16.232h5.181L25.6,34Zm-8.513,0L8.7,21.6c-.16-.626-.237-.711-.6-.909a15.3,15.3,0,0,0-3.644-1.208l-.624-.135.332-1.571h7.1a2.436,2.436,0,0,1,2.414,2.042l1.217,6.465,3.366-8.507h5.466L16.921,34Z'
          transform='translate(173.027 172.444)'
          opacity='0.07'
        />
        <path
          id='Path_3435'
          data-name='Path 3435'
          d='M29.164,22.939c-.027,2.153,1.918,3.353,3.384,4.067,1.505.733,2.011,1.2,2.006,1.858-.012,1-1.2,1.446-2.315,1.463a8.077,8.077,0,0,1-3.969-.944l-.7,3.274a11.648,11.648,0,0,0,4.3.793c4.06,0,6.717-2,6.731-5.112.015-3.944-5.455-4.163-5.418-5.925.013-.534.523-1.1,1.64-1.25a7.278,7.278,0,0,1,3.811.668l.68-3.167A10.344,10.344,0,0,0,35.7,18c-3.821,0-6.51,2.032-6.532,4.939m16.679-4.666a1.764,1.764,0,0,0-1.645,1.1L38.4,33.217h4.057l.807-2.231h4.958l.469,2.231h3.575L49.144,18.273h-3.3m.568,4.038,1.17,5.612H44.374l2.037-5.612M24.246,18.273l-3.2,14.944h3.866l3.2-14.944H24.246m-5.721,0L14.5,28.445,12.873,19.8a1.8,1.8,0,0,0-1.783-1.523H4.51l-.09.434a16.107,16.107,0,0,1,3.815,1.272,1.636,1.636,0,0,1,.918,1.313l3.084,11.926h4.087l6.264-14.944H18.525'
          transform='translate(173.195 172.588)'
          fill='#fff'
        />
      </g>
    </SvgIcon>
  );
};
