import { City, CityInput } from "@health/queries/types";
import { ICityUpsertFormValues } from "../forms/CityUpsert/CityUpsertFormSchema";

export const convertCityToFormValues = (city: City): ICityUpsertFormValues => {
  return {
    name: city?.name,
    nameAr: city?.nameAr!,
    area: city?.area!,
  };
};

export const convertCityFormValuesToBackEndValues = (values: ICityUpsertFormValues): CityInput => {
  return {
    name: values?.name,
    nameAr: values?.nameAr,
    area: values?.area,
  };
};
