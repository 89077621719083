import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
export const doctorSchema = createZodAutocompleteFromType({
    id: z.string(),
    user: z
        .object({
        id: z.string(),
        fullName: z.string().nullish(),
    })
        .nullish(),
});
