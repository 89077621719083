import { IFilter } from "../components";
import { IAutocompleteLabelBy, IAutocompleteOption, IAutocompleteQueryFilter } from "../types";
import { get } from "lodash";
import { OperationVariables } from "@apollo/client";
import { ExtractNodeType } from "@toolkit/apollo";

export const getAutocompleteLabel = <T,>(item: T, labelBy: IAutocompleteLabelBy<T>) => {
  return typeof labelBy === "function" ? labelBy(item) : get(item, labelBy);
};

export const createAutocompleteOption = <T,>(item: T, keyBy: keyof T, labelBy: IAutocompleteLabelBy<T>): IAutocompleteOption<T> => {
  return {
    key: item[keyBy] as string,
    label: getAutocompleteLabel(item, labelBy),
    value: item,
  };
};

export const createAutocompleteStringOption = (item: string, labelBy?: (item: string) => string): IAutocompleteOption<string> => {
  return {
    key: item.toUpperCase(),
    label: labelBy ? labelBy(item) : item,
    value: item,
  };
};

export const mapStringsToAutocompleteOptions = (items: string[], labelBy?: (item: string) => string): IAutocompleteOption<string>[] => {
  if (!items || !items?.length) return [];
  return items.map(item => createAutocompleteStringOption(item, labelBy));
};

export const mapToAutocompleteOptions = <T extends object>(
  items: T[],
  keyBy: keyof T,
  labelBy: IAutocompleteLabelBy<T>
): IAutocompleteOption<T>[] => {
  if (!items || !items?.length) return [];
  return items.map(item => createAutocompleteOption(item, keyBy, labelBy));
};

export const getAutocompleteFilter = <T, V extends OperationVariables>(options: IAutocompleteQueryFilter<T, V>): IFilter => {
  const { labelBy, backendAccessor, ...rest } = options;

  const getOptionLabel = (option: ExtractNodeType<T>): string => {
    return getAutocompleteLabel(option, labelBy!);
  };

  const getValueForBackend = (option: ExtractNodeType<T> | ExtractNodeType<T>[]) => {
    return getAutocompleteFilterValueForBackend(option, backendAccessor, options?.multiple);
  };

  return {
    ...rest,
    type: "autocomplete" as const,
    getOptionLabel,
    getValueForBackend,
  };
};

const getAutocompleteFilterValueForBackend = <T,>(option: T | T[], dataAccessor: IAutocompleteLabelBy<T>, multiple?: boolean) => {
  if (multiple) {
    return Array.isArray(option) ? option.map(item => getBackendAccessorValue(item, dataAccessor)) : undefined;
  } else {
    return getBackendAccessorValue(option as T, dataAccessor);
  }
};

const getBackendAccessorValue = <T,>(option: T, dataAccessor: IAutocompleteLabelBy<T>) => {
  if (typeof dataAccessor === "function") {
    return dataAccessor(option);
  } else {
    return option?.[dataAccessor];
  }
};
