import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { usePayersAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const createPayerAutocompleteOption = (payer) => {
    return createAutocompleteOption({
        id: payer === null || payer === void 0 ? void 0 : payer.id,
        name: payer === null || payer === void 0 ? void 0 : payer.name,
        nameAr: payer === null || payer === void 0 ? void 0 : payer.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getPayersAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "payers",
        query: usePayersAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
        filtersForQuery: {
            isActive: true,
        },
    });
};
