/* eslint-disable react/boolean-prop-naming */
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Divider } from "../../base/mui";
import { useCustomListStyle } from "./CustomTransferList.styles";
import { ListComponent } from "./List.component";

type CustomListProps<T> = {
  title: string;
  items: T[] | undefined;
  handleToggle: (value: T) => () => void;
  checked: T[] | undefined;
  handleSearch: (value: string) => void;
  searchedValue: string;
  loading: boolean;
  isError?: boolean;
  helperText?: string;
};

export const CustomList = <T extends object>({
  title,
  items,
  handleToggle: onToggle,
  checked,
  handleSearch: onSearch,
  searchedValue,
  loading,
  isError,
  helperText,
}: CustomListProps<T>) => {
  const { classes } = useCustomListStyle();
  const [itemsWithSearch, setItemsWithSearch] = useState(items);

  useEffect(() => {
    if (searchedValue) {
      const _items = items?.filter(el => (get(el, "name", "") as string).toLowerCase().includes(searchedValue.toLowerCase())) ?? [];
      setItemsWithSearch(_items.slice(0));
    } else {
      setItemsWithSearch(items);
    }
  }, [searchedValue, JSON.stringify(items)]);

  return (
    <Card className={isError ? classes.errorCardStyle : ""}>
      <CardHeader
        sx={{ px: 2, py: 2 }}
        title={title}
        classes={{
          title: classes.title,
        }}
      />
      <Divider className={classes.divider} />
      <React.Fragment>
        <ListComponent<T>
          items={itemsWithSearch}
          handleToggle={onToggle}
          checked={checked}
          handleSearch={e => onSearch(e.target.value)}
          searchedValue={searchedValue}
          loading={loading}
          isError={isError}
          helperText={helperText}
        />
      </React.Fragment>
    </Card>
  );
};

CustomList.defaultProps = {
  isError: false,
  helperText: "",
};
