import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const CreateMessageIcon: FC<SvgIconProps> = ({ fill, width, height, viewBox, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20.179"}
      height={height ? height : "18.627"}
      viewBox={viewBox ? viewBox : "0 0 20.179 18.627"}
      {...props}
    >
      <path
        id='icons8-create'
        d='M22.807,2a1.368,1.368,0,0,0-.97.4l-.408.408,1.94,1.94.408-.408A1.372,1.372,0,0,0,22.807,2ZM5.552,3.552A1.552,1.552,0,0,0,4,5.1v13.97a1.552,1.552,0,0,0,1.552,1.552h13.97a1.552,1.552,0,0,0,1.552-1.552V9.24l-1.552,1.552v8.283H5.552V8.209h8.283l4.657-4.657Zm14.779.355-8.2,8.2-.368,2.309,2.309-.368,8.2-8.2Z'
        transform='translate(-4 -2)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
