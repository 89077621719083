import { makeStyles } from "../../../base/mui";

export const useConsumerCustomDialogStyles = makeStyles()(theme => ({
  contentContainer: {
    padding: "10px",
    paddingTop: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
  actionsContainer: {
    height: "74px",
    padding: "10px",
    border: "none",
    boxShadow: theme.mixins.shadows.xs,
    display: "flex",
    justifyContent: "space-between",
  },
  iconWrapper: {
    width: "77px",
    height: "77px",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "center",
    marginTop: "5px",
  },
  body: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    textAlign: "center",
  },
  button: {
    height: "48px",
    paddingInline: "30px",
    borderRadius: "24px",
  },
  closeButton: {
    height: "48px",
    paddingInline: "30px",
    borderRadius: "24px",
    border: "1px solid",
    borderColor: theme.palette.gray[500],
    color: theme.palette.common.black,
  },
}));
