/* eslint-disable react/boolean-prop-naming */
import { Box, Dialog, DialogContent, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useStyles } from "./BottomSheetDialogStyle";

export type BottomSheetDialogProps = {
  open: boolean;
  handleClose?: () => void;
  title?: string;
  footer?: ReactNode;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
  children?: ReactNode;
  hasDragBar?: boolean;
};

export const BottomSheetDialog: React.FC<BottomSheetDialogProps> = ({
  open,
  handleClose,
  children,
  title,
  footer,
  classes: _classes,
  hasDragBar = true,
}) => {
  const { classes, cx } = useStyles();
  const [initialTouchY, setInitialTouchY] = useState<number | null>(null);
  const [dragDistance, setDragDistance] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const lastPositionBeforeClose = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!open) {
      lastPositionBeforeClose.current = undefined;
    }
  }, [open]);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setInitialTouchY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const currentTouchY = e.touches[0].clientY;
    const distance = currentTouchY - (initialTouchY ?? 0);
    if (distance > 0) {
      setDragDistance(distance);
    }
  };

  const handleTouchEnd = () => {
    if (ref.current && dragDistance > (ref.current?.offsetHeight ?? 1) / 4) {
      handleClose?.();
      lastPositionBeforeClose.current = dragDistance;
    }
    setDragDistance(0);
    setInitialTouchY(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: cx(classes.paper, _classes?.paper), container: cx(classes.container, _classes?.container) }}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" } as TransitionProps}
      keepMounted
      PaperProps={{
        style: {
          transform: `translateY(${lastPositionBeforeClose.current ?? dragDistance}px)`,
        },
      }}
    >
      <Box ref={ref}>
        {hasDragBar && (
          <Box
            className={cx(classes.dragHandle, _classes?.dragHandle)}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <Box className={cx(classes.dragBar, _classes?.dragBar)} />
          </Box>
        )}

        {title && <Box className={cx(classes.title, _classes?.title)}>{title}</Box>}

        <DialogContent className={cx(classes.content, _classes?.content)}>{children}</DialogContent>

        {footer && <Box className={cx(classes.footer, _classes?.footer)}>{footer}</Box>}
      </Box>
    </Dialog>
  );
};
