import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";

export const Trust: FC<IconProps> = ({ width = "26", height = "22", viewBox = "0 0 26 22", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-trust'
        d='M17.273,4A3.273,3.273,0,0,0,14,7.273c0,3.137,3.624,6.129,5.334,7.473q.027.023.055.045l.01.008a1,1,0,0,0,1.2,0l.014-.012.006,0,.014-.012c1.7-1.334,5.365-4.345,5.365-7.5A3.273,3.273,0,0,0,22.727,4,3.564,3.564,0,0,0,20,5.5,3.564,3.564,0,0,0,17.273,4ZM8,14a3,3,0,0,0-1.117.217L2,16v8l4.221-1.834a2.013,2.013,0,0,1,1.564-.018l9.064,3.7h0a1.481,1.481,0,0,0,1.385-.049h.006l8.986-4.992,0,0a1.5,1.5,0,0,0-1.365-2.664v0L19,21H17.5a1.5,1.5,0,0,0,.465-2.926v0L9.271,14.281H9.26A3,3,0,0,0,8,14Z'
        transform='translate(-2 -4)'
        fill='#e67e22'
      />
    </SvgIcon>
  );
};
