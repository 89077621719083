import { createAutocompleteOption } from "@toolkit/ui";
import { useListFieldsAutocompleteQuery } from "./gql";
export const createListFieldAutocompleteOption = (unit) => {
    return createAutocompleteOption({
        id: unit === null || unit === void 0 ? void 0 : unit.id,
        code: unit === null || unit === void 0 ? void 0 : unit.code,
        display: unit === null || unit === void 0 ? void 0 : unit.display,
    }, "id", "display");
};
export const getListFieldsAutocompleteFilter = ({ name, multiple }) => {
    return {
        name,
        multiple,
        type: "autocomplete",
        accessor: "getAllListFields",
        query: useListFieldsAutocompleteQuery,
        getOptionLabel: option => option === null || option === void 0 ? void 0 : option.display,
        getValueForBackend: options => options === null || options === void 0 ? void 0 : options.id,
    };
};
