import { actionFieldTypeOptions, appointmentFrequencyTypeOptions, appointmentPaymentTypeOptions, appointmentStatusOption, appointmentTypeOptions, appRoleTypeOptions, assigneeTypeOptions, bulkProcessingStatusOptions, bulkTemplateCodeOptions, callbackRequestStatusOptions, careTypeOptions, chartTypeOptions, codeSystemCodeOptions, conditionOperationOptions, coverageOptions, coverageByTypeOptions, customerSupportOrderStatusOptions, fieldStandardOptions, guidedCareActivityTypeOptions, guidedCareJourneyItemSourceOptions, guidedCareTypeOptions, healthConditionTypeOptions, healthProgramSubscriptionPeriodOptions, idTypeOptions, marketplaceApprovalStatusNoDraftOptions, marketplaceApprovalStatusOptions, marketplaceDiscountTypeOptions, marketplaceGenderOptions, marketplaceOrderStatusOptions, marketplaceOrderTypeOptions, medicalFormCategoryOptions, medicalFormTypeOptions, medicalMessageCategoryOptions, medicalMessageTypeOptions, operatorOptions, orderStatusActiveOptions, orderStatusOptions, payerTpoOptions, payerTypeOptions, paymentSourceOptions, paymentStatusOptions, paymentTypeOptions, planPeriodOptions, priorityOptions, providerGuidedCareTeamStatusOptions, questionTypeOptions, requestedByOptions, sourceOptions, surveyStatusOptions, taskDefinitionStatusOptions, taskWorkflowTypeOptions, teamMemberPositionOptions, templateFieldTypeOptions, userGenderOptions, userStatusOptions, vendorTypeOptions, vendorUserTypeOptions, visitCareTypeOptions, visitDiagnosisTypeOptions, visitStatusOptions, visitTypeOptions, yesNoOptions, requestStatusOptions, } from "../enums";
export const enumOptionsMapper = {
    ActionFieldType: actionFieldTypeOptions,
    AppointmentFrequencyType: appointmentFrequencyTypeOptions,
    AppointmentPaymentType: appointmentPaymentTypeOptions,
    AppointmentStatus: appointmentStatusOption,
    AppointmentType: appointmentTypeOptions,
    AppRoleType: appRoleTypeOptions,
    AssigneeType: assigneeTypeOptions,
    BulkProcessingStatus: bulkProcessingStatusOptions,
    BulkTemplateCode: bulkTemplateCodeOptions,
    CallbackRequestStatus: callbackRequestStatusOptions,
    CareType: careTypeOptions,
    ChartType: chartTypeOptions,
    CodeSystemCode: codeSystemCodeOptions,
    ConditionOperation: conditionOperationOptions,
    Coverage: coverageOptions,
    CoverageByType: coverageByTypeOptions,
    CustomerSupportOrderStatus: customerSupportOrderStatusOptions,
    FieldStandard: fieldStandardOptions,
    GuidedCareActivityType: guidedCareActivityTypeOptions,
    GuidedCareJourneyItemSource: guidedCareJourneyItemSourceOptions,
    GuidedCareType: guidedCareTypeOptions,
    HealthConditionType: healthConditionTypeOptions,
    HealthProgramSubscriptionPeriod: healthProgramSubscriptionPeriodOptions,
    IdType: idTypeOptions,
    MarketplaceApprovalStatus: marketplaceApprovalStatusOptions,
    MarketplaceApprovalStatusNoDraft: marketplaceApprovalStatusNoDraftOptions,
    MarketplaceDiscountType: marketplaceDiscountTypeOptions,
    MarketplaceGender: marketplaceGenderOptions,
    MarketplaceOrderStatus: marketplaceOrderStatusOptions,
    MarketplaceOrderType: marketplaceOrderTypeOptions,
    MedicalFormCategory: medicalFormCategoryOptions,
    MedicalFormType: medicalFormTypeOptions,
    MedicalMessageCategory: medicalMessageCategoryOptions,
    MedicalMessageType: medicalMessageTypeOptions,
    Operator: operatorOptions,
    OrderStatus: orderStatusOptions,
    OrderStatusActive: orderStatusActiveOptions,
    PayerTpo: payerTpoOptions,
    PayerType: payerTypeOptions,
    PaymentSource: paymentSourceOptions,
    PaymentStatus: paymentStatusOptions,
    PaymentType: paymentTypeOptions,
    PlanPeriod: planPeriodOptions,
    Priority: priorityOptions,
    ProviderGuidedCareTeamStatus: providerGuidedCareTeamStatusOptions,
    QuestionType: questionTypeOptions,
    RequestedBy: requestedByOptions,
    RequestStatus: requestStatusOptions,
    Source: sourceOptions,
    SurveyStatus: surveyStatusOptions,
    TaskDefinitionStatus: taskDefinitionStatusOptions,
    TaskWorkflowType: taskWorkflowTypeOptions,
    TeamMemberPosition: teamMemberPositionOptions,
    TemplateFieldType: templateFieldTypeOptions,
    UserGender: userGenderOptions,
    UserStatus: userStatusOptions,
    VendorType: vendorTypeOptions,
    VendorUserType: vendorUserTypeOptions,
    VisitCareType: visitCareTypeOptions,
    VisitDiagnosisType: visitDiagnosisTypeOptions,
    VisitStatus: visitStatusOptions,
    VisitType: visitTypeOptions,
    YesNo: yesNoOptions,
};
