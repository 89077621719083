import { enumOptionsMapper } from "./mapper";
import { YesNo } from "../enums";
export const getAutocompleteEnumFilter = (enumType, name, properties) => {
    return {
        type: "autocomplete",
        name,
        multiple: properties === null || properties === void 0 ? void 0 : properties.multiple,
        options: enumOptionsMapper[enumType],
        getOptionLabel: option => option === null || option === void 0 ? void 0 : option.label,
        getValueForBackend: option => {
            if (option) {
                if (enumType === "YesNo") {
                    if (properties === null || properties === void 0 ? void 0 : properties.reverse) {
                        return (option === null || option === void 0 ? void 0 : option.value) !== YesNo.Yes;
                    }
                    else {
                        return (option === null || option === void 0 ? void 0 : option.value) === YesNo.Yes;
                    }
                }
                else {
                    if (Array.isArray(option)) {
                        return (option === null || option === void 0 ? void 0 : option.map(item => item === null || item === void 0 ? void 0 : item.value)) || [];
                    }
                    return option === null || option === void 0 ? void 0 : option.value;
                }
            }
            else {
                return undefined;
            }
        },
    };
};
