import React, { FC, useState } from "react";
import moment from "moment";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { LocalizationProvider, TextField, InputAdornment, IconButton, type TextFieldProps, FormHelperText } from "../../base/mui";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Example icon
import { MomentUtils } from "../dependencies";
import { StaticTimePickerProps, StaticTimePicker } from "@mui/x-date-pickers";

export type CustomStaticTimePickerProps = TextFieldProps & {
  label?: string;
  control: Control<Record<string, unknown>>;
  name: string;
  placeholder?: string;
  format?: string;
  helperText?: string;
  error?: string | boolean;
  controllerProps?: Omit<ControllerProps, "render" | "name">;
  timePickerProps?: Omit<StaticTimePickerProps, "onChange" | "value" | "renderInput">;
};

export const CustomStaticTimePicker: FC<CustomStaticTimePickerProps> = ({
  format = "HH:mm",
  label,
  control,
  name,
  placeholder,
  helperText,
  error,
  controllerProps,
  timePickerProps,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <Controller
        name={name}
        control={controllerProps?.control || control}
        defaultValue={controllerProps?.defaultValue || props.defaultValue}
        rules={controllerProps?.rules}
        render={({ field: { onChange, value } }) => {
          const handleSelectedTime = (time: unknown) => {
            onChange(moment(time as string)?.isValid() ? moment(time as string).format(format) : null);
          };

          return (
            <StaticTimePicker
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              label={label}
              value={value ? moment(value, format) : null}
              onChange={handleSelectedTime}
              inputFormat={format}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label={label}
                  error={!!error}
                  helperText={helperText}
                  placeholder={placeholder}
                  onClick={handleOpen}
                  sx={{
                    width: "100%",
                    minWidth: "100px",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handleOpen} edge='end'>
                          <AccessTimeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {...timePickerProps}
            />
          );
        }}
      />
      {!!error && <FormHelperText error>{helperText}</FormHelperText>}
    </LocalizationProvider>
  );
};

CustomStaticTimePicker.defaultProps = {
  format: "HH:mm",
  helperText: undefined,
  error: undefined,
  timePickerProps: {},
  controllerProps: {},
};
