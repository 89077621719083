import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PriorityIcon: FC<SvgIconProps> = ({ ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      sx={{
        width: 44,
        height: 44,
      }}
      viewBox='0 0 44 44'
      {...props}
    >
      <g id='Group_9475' data-name='Group 9475' transform='translate(-328.066 -431.064)'>
        <circle id='Ellipse_2662' data-name='Ellipse 2662' cx='22' cy='22' r='22' transform='translate(328.066 431.064)' fill='#1d2344' />
        <path
          id='icons8-guru'
          d='M14,3a2.727,2.727,0,1,0,2.727,2.727A2.727,2.727,0,0,0,14,3Zm0,7.273q-.045,0-.091,0H13.9a6.644,6.644,0,0,0-1,.089,4.715,4.715,0,0,0-3.544,2.864L7.837,16.883,4.5,18.55a.909.909,0,1,0,.813,1.626l3.636-1.818a.909.909,0,0,0,.433-.463l.978-2.351V19.5L7.489,21.535a1.813,1.813,0,0,0-.646,2.113,1.894,1.894,0,0,0,1.8,1.17h1.9a2.438,2.438,0,0,1,.545-2.63,2.685,2.685,0,0,1,2.207-1,2.224,2.224,0,0,1,.71.144c.162.055,3.008.957,3.008.957L13.023,23a.909.909,0,0,0,.067,1.815h6.374A1.716,1.716,0,0,0,21,23.653a.909.909,0,0,0,.268-.643,1.816,1.816,0,0,0-.76-1.475h0L17.636,19.5V15.544l.978,2.351a.909.909,0,0,0,.433.463l3.636,1.818A.909.909,0,1,0,23.5,18.55l-3.334-1.667-1.522-3.654A4.715,4.715,0,0,0,15.1,10.365h0a6.621,6.621,0,0,0-1.012-.089q-.042,0-.083,0Z'
          transform='translate(336 437)'
          fill={palette.common.white}
        />
      </g>
    </SvgIcon>
  );
};
