import { EnumLike, z, ZodNativeEnum, ZodObject, ZodRawShape, ZodString } from "zod";
import { TypeToZod } from "../types";

export function createZodAutocomplete(): ZodObject<{ key: ZodString; label: ZodString; value: ZodString }>;

export function createZodAutocomplete<T extends EnumLike>(
  value: T
): ZodObject<{ key: ZodNativeEnum<T>; label: ZodString; value: ZodNativeEnum<T> }>;

export function createZodAutocomplete<T extends ZodRawShape>(
  schema: ZodObject<T>
): ZodObject<{ key: ZodString; label: ZodString; value: ZodObject<T> }>;

export function createZodAutocomplete(schema?: any): any {
  if (!schema) {
    return z.object({
      key: z.string(),
      label: z.string(),
      value: z.string(),
    });
  }

  if (schema instanceof ZodObject) {
    return z.object({
      key: z.string(),
      label: z.string(),
      value: schema,
    });
  }

  return z.object({
    key: z.nativeEnum(schema),
    label: z.string(),
    value: z.nativeEnum(schema),
  });
}

export const createZodSchemaFromType = <T>(schema: TypeToZod<T>) => {
  return z.object(schema);
};

export const createZodAutocompleteFromType = <T>(schema: TypeToZod<T>) => {
  return z.object({
    key: z.string(),
    label: z.string(),
    value: createZodSchemaFromType(schema),
  });
};
