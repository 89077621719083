import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const CategoryUpsertFormSchema = z.object({
  name: z.string().min(3).max(255),
  nameAr: z.string().min(3).max(255).nullish(),
  description: z.string().nullish(),
  descriptionAr: z.string().nullish(),
  parentCategory: zodSchema.marketplaceProductCategory.nullish(),
  image: z.string().nullish(),
});

export type ICategoryUpsertFormValues = z.infer<typeof CategoryUpsertFormSchema>;

export const categoryUpsertFormSchemaDefaultValues: Partial<ICategoryUpsertFormValues> = {
  name: undefined,
  nameAr: undefined,
  description: undefined,
  descriptionAr: undefined,
  parentCategory: undefined,
  image: undefined,
};
