import { z } from "zod";
import { i18n } from "@toolkit/i18n";
import { EntityType } from "@health/queries/types";
import { hideDecisionConditionOperator } from "../../../Decisions/others/utils";
import { createZodAutocomplete } from "@toolkit/core";
import { zodEnumSchema } from "@health/enum-options";
export const DecisionConditionsFormSchema = z
    .object({
    id: z.string(),
    category: createZodAutocomplete(z.object({ code: z.string(), display: z.string() })),
    field: createZodAutocomplete(z.object({
        id: z.string(),
        code: z.string(),
        display: z.string(),
        type: z.nativeEnum(EntityType),
    })),
    operator: zodEnumSchema.operator,
    valueNumber: z.number().nonnegative().nullish(),
    valueDate: z.string().nullish(),
    valueString: z.string().or(createZodAutocomplete()).nullish(),
    valueID: createZodAutocomplete(z.object({
        id: z.string(),
        name: z.string(),
        nameAr: z.string().nullish(),
    })).nullish(),
    valueList: createZodAutocomplete(z.object({
        id: z.string(),
        display: z.string(),
    })).nullish(),
})
    .superRefine((data, ctx) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    if (!hideDecisionConditionOperator((_a = data === null || data === void 0 ? void 0 : data.operator) === null || _a === void 0 ? void 0 : _a.value)) {
        if (((_c = (_b = data === null || data === void 0 ? void 0 : data.field) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.type) === EntityType.String && !data.valueString) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("Required", { ns: "domains" }),
                path: ["valueString"],
            });
        }
        else if (((_e = (_d = data === null || data === void 0 ? void 0 : data.field) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.type) === EntityType.Number && (data.valueNumber === undefined || isNaN(data.valueNumber))) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("Required", { ns: "domains" }),
                path: ["valueNumber"],
            });
        }
        else if (((_g = (_f = data === null || data === void 0 ? void 0 : data.field) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.type) === EntityType.Id && !data.valueID) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("Required", { ns: "domains" }),
                path: ["valueID"],
            });
        }
        else if (((_j = (_h = data === null || data === void 0 ? void 0 : data.field) === null || _h === void 0 ? void 0 : _h.value) === null || _j === void 0 ? void 0 : _j.type) === EntityType.List && !data.valueList) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("Required", { ns: "domains" }),
                path: ["valueList"],
            });
        }
        else if (((_l = (_k = data === null || data === void 0 ? void 0 : data.field) === null || _k === void 0 ? void 0 : _k.value) === null || _l === void 0 ? void 0 : _l.type) === EntityType.Date && !data.valueDate) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t("Required", { ns: "domains" }),
                path: ["valueDate"],
            });
        }
    }
});
