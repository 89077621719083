import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramNetworksAutocompleteQuery } from "./gql";
export const createHealthProgramNetworkAutocompleteOption = (healthProgramNetwork) => {
    return createAutocompleteOption({ id: healthProgramNetwork === null || healthProgramNetwork === void 0 ? void 0 : healthProgramNetwork.id, name: healthProgramNetwork === null || healthProgramNetwork === void 0 ? void 0 : healthProgramNetwork.name }, "id", "name");
};
export const getHealthProgramNetworksAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "healthProgramNetworks",
        query: useHealthProgramNetworksAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
