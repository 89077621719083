/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import { NetworkStatus, OperationVariables } from "@apollo/client";
import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import { CustomTableProps, SelectionTableProps } from "../../Table/types";
import { CustomTableColumnProps, GetQueryVars, GQuery, UseQGridStateProps } from "../types";
import { extractCountableConnectionData as extractCountableConnectionDataDefault } from "../utils/countableConnection";
import { defaultVariables } from "../utils/defaultValues";
import { useGridSelection } from "./useGridSelection";
import { useTablePagination } from "./useTablePagination";
import { useTableSort } from "./useTableSort";

export const useDataGridState = <Z extends object = any, TData = any, TVar extends OperationVariables = Record<string, any>>(
  props: UseQGridStateProps<Z, TData, TVar>
) => {
  const {
    input = defaultVariables as GetQueryVars<GQuery<TData, TVar>>,
    dataAccessor,
    size = 10,
    columns: defaultColumns,
    onError,
    onCompleted,
    useCustomQuery,
    extractCountableConnectionData = extractCountableConnectionDataDefault,
    tableAction,
    tablePreference,
    skip,
    hasSavedInput,
    context,
    fetchPolicy = "cache-first",
    nextFetchPolicy = "cache-first",
    selectionProps,
  } = props;
  const [variables, setVariables] = useState<TVar>(input);
  const { formatSelectedRow } = selectionProps || {};
  const { data, loading, networkStatus, fetchMore, refetch, ...queryResult } = useCustomQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: onCompleted! || noop,
    onError: onError! || noop,
    errorPolicy: "ignore",
    fetchPolicy,
    nextFetchPolicy,
    variables,
    skip,
    context,
  });
  const isLoading: boolean = loading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  const list = defaultColumns?.filter(item => !item?.showOnlyForFilterField);
  const [columns, setColumns] = useState<CustomTableColumnProps<Z>[]>(list);
  const { nodes, pageInfo, totalCount } = extractCountableConnectionData({
    responseData: data,
    entityName: typeof data === "object" ? (Object.keys(data ?? {}).filter(k => k != "__typename")[0] as any) : (dataAccessor as any),
  });

  const { pageSize, handleGoToNext, handleGoToPrevious, handleChangePageSize } = useTablePagination<GetQueryVars<GQuery<TData, TVar>>>(
    size,
    pageInfo!,
    setVariables
  );
  const { handleSortData } = useTableSort<GetQueryVars<GQuery<TData, TVar>>, Z>(variables, pageSize, setVariables, setColumns);
  const { handleRowsSelectionStatusChange, getRowSelectionStatus, selectedRows, onRemoveSelectedRow, onResetSelectedRows } =
    useGridSelection({ formatSelectedRow });
  const _selectionProps: SelectionTableProps<Z> | undefined = selectionProps
    ? {
        rowId: selectionProps.rowId,
        isSelectVisible: selectionProps.isSelectVisible,
        isRowSelectable: selectionProps.isRowSelectable,
        handleRowsSelectionStatusChange,
        getRowSelectionStatus,
      }
    : undefined;
  const handleFilter = (filter: Record<string, any>, _input?: React.SetStateAction<GetQueryVars<GQuery<TData, TVar>>>) => {
    setVariables(
      _input ||
        (state => ({
          ...state,
          first: pageSize,
          after: null,
          last: null,
          before: null,
          filter: typeof filter === "object" ? { ...(filter as Record<string, any>) } : filter,
        }))
    );
  };

  const customTableProps: CustomTableProps<Z> = {
    pageSize,
    totalCount: totalCount!,
    pageIndex: 1,
    data: nodes || [],
    isLoading: isLoading || tablePreference?.isColumnsLoading,
    columns: tablePreference?.reOrderColumns || columns || [],
    defaultColumns: columns || [],
    pagesCount: Math.ceil((totalCount || pageSize) / pageSize),
    hasNextPage: pageInfo?.hasNextPage,
    hasPreviousPage: pageInfo?.hasPreviousPage,
    onGoToNext: handleGoToNext,
    onGoToPrevious: handleGoToPrevious,
    onSortColumn: handleSortData,
    onUpdateGrid: refetch,
    selectionProps: _selectionProps,
    ...tableAction,
  };

  useEffect(() => {
    setVariables(
      hasSavedInput
        ? {
            ...input,
            filter: {
              ...variables?.filter,
              ...input?.filter,
            },
          }
        : input
    );
    setColumns(list);
  }, [JSON.stringify(input), JSON.stringify(defaultColumns)]);

  useEffect(() => {
    !skip &&
      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, fetchMoreResult);
        },
      });
  }, [variables, skip]);

  return {
    ...queryResult,
    customTableProps,
    nodes,
    data,
    pageInfo,
    networkStatus,
    totalCount,
    variables,
    isLoading,
    pageSize,
    columns,
    defaultColumns,
    refetch,
    fetchMore,
    setColumns,
    setVariables,
    doFilter: handleFilter,
    doChangePageSize: handleChangePageSize,
    handleFilter,
    handleGoToNext,
    handleSortData,
    handleGoToPrevious,
    handleChangePageSize,
    onResetSelectedRows,
    onRemoveSelectedRow,
    selectedRows,
  };
};
