import { HealthProgramVendorTotalPointsTrackingResponse } from "@health/queries/types";
import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, CustomTableColumnProps, Typography, useTheme } from "@toolkit/ui";
import moment from "moment";
import { callsPerVendorPaths } from "pages/HealthProgramCallsPerVendor/constants/calls-per-vendor-paths";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getHealthProgramsAutocompleteFilter, getVendorsAutocompleteFilter } from "@health/autocompletes";

export type HealthProgramVendorTotalPointsReportColumnTypes = CustomTableColumnProps<HealthProgramVendorTotalPointsTrackingResponse>;

export const useHealthProgramVendorTotalPointsReportColumns = (): HealthProgramVendorTotalPointsReportColumnTypes[] => {
  const { t } = useTranslation("admin");

  const navigate = useNavigate();

  const handleClick = (healthProgramId, healthProgramName, vendor) => () => {
    navigate(callsPerVendorPaths.listPath.fullPathWithParams(healthProgramId, vendor?.id), {
      state: {
        healthProgramName,
        vendor,
      },
    });
  };
  const theme = useTheme();
  return useMemo(() => {
    return [
      {
        key: "provider",
        header: t("Provider"),
        accessor: ({ vendor, healthProgramId, healthProgramName }) =>
          vendor ? (
            <Box display='flex' alignItems='center' onClick={handleClick(healthProgramId, healthProgramName, vendor)}>
              <Avatar src={getMediaLink(vendor?.logo!)} />
              <Typography
                sx={{
                  paddingInline: 1,
                  textDecoration: "underline",
                  cursor: "pointer",
                  width: "fit-content",
                  borderRadius: "25px",
                  fontSize: theme.mixins.fonts.fontSize.sm,
                }}
              >
                {vendor?.name}
              </Typography>
            </Box>
          ) : (
            "-"
          ),
        type: "string",
      },
      {
        key: "healthProgramName",
        header: t("Health Program Name"),
        accessor: ({ healthProgramName, vendor, healthProgramId }) => (
          <Box
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              paddingInline: "10px",
              width: "fit-content",
              borderRadius: "25px",
            }}
            onClick={handleClick(healthProgramId, healthProgramName, vendor)}
          >
            {healthProgramName}
          </Box>
        ),
        type: "string",
      },
      {
        key: "percentage",
        header: t("Percentage"),
        accessor: "percentage",
        type: "string",
      },
      {
        key: "profitShare",
        header: t("Profit Share"),
        accessor: "profitShare",
      },
      {
        key: "totalPoints",
        header: t("Total Points"),
        accessor: "totalPoints",
      },
      {
        key: "month",
        header: t("Month"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "month",
          min: 1,
          max: 12,
          initialValue: moment().get("month"),
        },
      },
      {
        key: "year",
        header: t("Year"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "year",
          min: 2022,
          max: moment().get("year") + 1,
          initialValue: moment().get("year"),
        },
      },
      {
        key: "Vendors",
        header: t("Vendors"),
        showOnlyForFilterField: true,
        filter: getVendorsAutocompleteFilter({ name: "vendorId" }),
      },
      {
        key: "healthPrograms",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: getHealthProgramsAutocompleteFilter({ name: "healthProgramId" }),
      },
    ];
  }, []);
};
