import {
  getHealthProgramMembersAutocompleteFilter,
  getHealthProgramsAutocompleteFilter,
  getPayersAutocompleteFilter,
} from "@health/autocompletes";
import { HealthProgramMemberList, HealthProgramMemberListSortingField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { memberListPaths } from "pages/HealthProgramMemberLists/constants/member-list-paths";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveToggleSwitch } from "./ActiveToggle";
import { getAutocompleteEnumFilter } from "@health/enum-options";

export type HealthProgramNetworkColumnTypes = CustomTableColumnProps<HealthProgramMemberList>;
export type HealthProgramsColumnsTypes = HealthProgramNetworkColumnTypes[];

export const useHealthProgramMemberListsColumns = ({ canManageActions }): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const handleGoToMembers = id => () => {
    navigate(memberListPaths.membersPath.fullPathWithParams(id));
  };

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => <TruncateTypography text={name!} />,
        type: "string",
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramMemberListSortingField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "members",
        header: t("Members"),
        accessor: ({ healthProgramMembers, id }) => (
          <Box sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleGoToMembers(id)}>
            {healthProgramMembers?.totalCount}
          </Box>
        ),
        type: "string",
        filter: getHealthProgramMembersAutocompleteFilter({ name: "members", multiple: true }),
      },
      {
        key: "health program list",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: getHealthProgramsAutocompleteFilter({ name: "healthPrograms", multiple: true }),
      },
      {
        key: "description",
        header: t("Description"),
        accessor: ({ description }) => <TruncateTypography text={description!} />,
        type: "string",
      },
      {
        key: "active",
        header: t("Active"),
        type: "string",
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramMemberListSortingField.IsActive,
        accessor: row => {
          return (
            <ActiveToggleSwitch
              isDisabled={!canManageActions}
              id={row?.id!}
              updateRequiredInput={{
                name: row?.name!,
              }}
              isActive={row?.isActive!}
            />
          );
        },
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "payer",
        header: t("Payer"),
        showOnlyForFilterField: true,
        filter: getPayersAutocompleteFilter({ name: "payers", multiple: true }),
      },
    ];
  }, []);
};
