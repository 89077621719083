"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsentType = exports.ConsentStatus = exports.ConsentLevel = exports.ConditionOperation = exports.Community = exports.CodeSystemCode = exports.CityOrderField = exports.ChatSortingField = exports.ChatErrorCode = exports.ChartType = exports.CareType = exports.CallbackRequestStatus = exports.CallSummaryAuditRecordType = exports.CallRecordStatus = exports.CalculatedPaymentStatusForSubscription = exports.BulkTemplateCode = exports.BulkProcessingStatus = exports.BulkFilesOrderDirection = exports.BulkFileSortField = exports.BulkErrorCodes = exports.BranchType = exports.BranchOrderField = exports.BlockOrderField = exports.BlockErrorCode = exports.AuthorizationStatus = exports.AuditLogOperationType = exports.AssigneeType = exports.ArticleSortingField = exports.ArticleCategorySortingField = exports.AppointmentTypeEnum = exports.AppointmentType = exports.AppointmentStatus = exports.AppointmentSortingField = exports.AppointmentPaymentType = exports.AppointmentNotificationType = exports.AppointmentFrequencyType = exports.AppointmentCreationSource = exports.AppointmentCategory = exports.AppTypes = exports.AppRoleTypes = exports.AnxietyFrequency = exports.AmountSourceType = exports.AffectedType = exports.AdminPromotionSortingField = exports.AddressTypeEnum = exports.Actor = exports.ActivityType = exports.ActivityLevel = exports.ActionFieldType = exports.AccountErrorCode = void 0;
exports.H_IdTypes = exports.H_Gender = exports.H_EntityErrorCode = exports.H_DayOfWeek = exports.GuidedCareType = exports.GuidedCareTaskSortingField = exports.GuidedCareJourneyStatus = exports.GuidedCareJourneySortField = exports.GuidedCareJourneyItemStatus = exports.GuidedCareJourneyItemSource = exports.GuidedCareJourneyItemPriority = exports.GuidedCareHealthProgramSortingField = exports.GuidedCareCareJourneyItemStatus = exports.GuidedCareActivityType = exports.Gender = exports.FlowrseIntegrationSortDirection = exports.FileType = exports.FieldStandard = exports.ExerciseType = exports.ExerciseDurationUnit = exports.ErrorType = exports.ErrorDetail = exports.EntityType = exports.EducationLevel = exports.DoctorSeniorityEnum = exports.DoctorOrderField = exports.DoctorErrorCode = exports.DoctorAvailabilityStatusEnum = exports.DoctorAvailabilityStatus = exports.DietType = exports.DietQuality = exports.DiagnosisType = exports.DiagnosisPrescriptionErrorCode = exports.DiagnoseType = exports.DiabetesRisk = exports.DestinationType = exports.DepressionFrequency = exports.DeliveryType = exports.DefaultSortingField = exports.DecisionSortField = exports.DecisionPlanCategory = exports.DecisionMakerOrderDirection = exports.DecisionMakerErrorCodes = exports.DayOfWeekEnum = exports.CustomerSupportOrderStatusEnum = exports.CustomerEventsEnum = exports.Currency = exports.CoverageByType = exports.Coverage = exports.ConsumerViewPreferenceErrorCode = void 0;
exports.MedicalMessageType = exports.MedicalMessageSortField = exports.MedicalMessageCategory = exports.MedicalFormType = exports.MedicalFormSortingField = exports.MedicalFormCategory = exports.MarketplaceSortDirection = exports.MarketplacePromotionSortingField = exports.MarketplaceProductSortingField = exports.MarketplacePaymentMethod = exports.MarketplaceOrderType = exports.MarketplaceOrderStatus = exports.MarketplaceOrderSortingField = exports.MarketplaceHealthPackageSortingField = exports.MarketplaceGender = exports.MarketplaceDiscountType = exports.MarketplaceDiscountSortingField = exports.MarketplaceDefaultSortingField = exports.MarketplaceContentType = exports.MarketplaceApprovalStatus = exports.MaritalStatusesEnum = exports.LanguageCodeEnum = exports.LabTemplateSortingField = exports.KeywordSearch = exports.JourneyItemClassification = exports.ItemType = exports.InterventionActivationType = exports.InsuranceSortField = exports.HealthSymptomOrderField = exports.HealthSymptomErrorCode = exports.HealthProgramType = exports.HealthProgramTemplateSortingField = exports.HealthProgramSubscriptionPeriod = exports.HealthProgramSortingField = exports.HealthProgramOptionType = exports.HealthProgramNetworkSortingField = exports.HealthProgramMembershipRequestSortingField = exports.HealthProgramMemberSortingField = exports.HealthProgramMemberListSortingField = exports.HealthProgramBenefitOptionCode = exports.HealthParameterType = exports.HealthParameterSortingField = exports.HealthHistoryType = exports.HealthConditionType = exports.HealthConditionSortingField = exports.HealthConditionDiagnosisSortingField = exports.HealthChannelSubscriptionType = exports.H_ReportingPeriod = exports.H_RelationTypes = exports.H_OrderDirection = void 0;
exports.PriceRangeEnum = exports.PrescriptionSortField = exports.PrescriptionDispenseStatusEnum = exports.PrescriptionDispenseStatus = exports.PlanPeriod = exports.PlanOrderField = exports.PersonGenderEnum = exports.PermissionGroupSortField = exports.PermissionGroupErrorCode = exports.PermissionEnum = exports.PaymentType = exports.PaymentStatus = exports.PaymentSource = exports.PaymentOperationLogSortField = exports.PaymentOperation = exports.PaymentMethod = exports.PaymentErrorCodes = exports.PaymentBrand = exports.PayerTypeEnum = exports.PayerType = exports.PayerTpo = exports.PayerOrderField = exports.PayerErrorCode = exports.PatientMaritalStatus = exports.PatientIdentifierType = exports.PatientIdTypeEnum = exports.PatientIdType = exports.PatientHealthParameterSortField = exports.PatientErrorCode = exports.PasswordResetMethodEnum = exports.OutPatientJourneyStatus = exports.OrderTypesEnum = exports.OrderType = exports.OrderStatusEnum = exports.OrderStatus = exports.OrderSourcesEnum = exports.OrderSortField = exports.OrderPaymentMethod = exports.OrderEventType = exports.OrderDirection = exports.OrderDeliveryStatus = exports.OptimaValidationRequestsSortingField = exports.OptimaUse = exports.OptimaPriority = exports.Operator = exports.OperationType = exports.OperationStatus = exports.NotificationType = exports.NotificationChannel = exports.MedicalMessageVariantSortField = void 0;
exports.TrackerIntakeInstructions = exports.TrackerFrequencyType = exports.TrackerDurationType = exports.TrackerDayOfWeek = exports.TrackerColor = exports.TrackerActivityType = exports.TextSummaryErrorCode = exports.TerminologyErrorCodes = exports.TemplateFieldType = exports.TemplateActivityEventItemSortingField = exports.TeamMemberPosition = exports.TaskWorkflowType = exports.TaskDefinitionStatus = exports.TaskDefinitionSortingField = exports.TaskDefinitionPriority = exports.TaskDefinitionAction = exports.Tpo = exports.SurveyStatus = exports.SurveySortingField = exports.SurveyGender = exports.SurveyErrorCodes = exports.SubscriptionErrorCodes = exports.SubscriptionErrorCode = exports.SubListSortField = exports.SubListItemSortField = exports.SpecializationErrorCode = exports.Source = exports.SortDirection = exports.SmokingHabit = exports.SiteErrorCode = exports.ServiceDescription = exports.RiskLevel = exports.RiskFactorTemplateSortingField = exports.ReviewQuestionValueType = exports.ResidencyTypesEnum = exports.RequestedBy = exports.RequestStatus = exports.ReportingPeriod = exports.RelationTypes = exports.RefundTarget = exports.RefundStatus = exports.ReferralOrderDirection = exports.ReferralErrorCodes = exports.RatingActionEnum = exports.QuestionType = exports.ProviderGuidedCareTeamStatus = exports.ProviderGuidedCareHealthProgramTeamSortingField = exports.ProfileType = exports.ProcessingStatus = exports.Priority = void 0;
exports.Period = exports.WeightUnit = exports.VisitType = exports.VisitTranscriptionProcessingStatus = exports.VisitStatus = exports.VisitSortField = exports.VisitReferralSortField = exports.VisitPrescriptionActivityType = exports.VisitPrescriptionActivityFrequencyType = exports.VisitDiagnosisType = exports.VisitCareType = exports.VisitAttachmentType = exports.VendorUserTypes = exports.VendorTypeEnum = exports.VendorType = exports.VendorOrderField = exports.VendorErrorCode = exports.VendorDepartmentOrderField = exports.VendorBranchTypeEnum = exports.UserVendorUserType = exports.UserStatus = exports.UserSortField = exports.UserNotificationField = exports.UserGender = exports.UnverifiedNationalsStatusesEnum = exports.UnverifiedNationalsStatus = exports.UnverifiedNationalsIdType = exports.Type = exports.TranscribeErrorCode = exports.TransactionVisitPrescriptionSortField = exports.TransactionTransactionStatus = exports.TransactionSource = exports.TransactionSortField = exports.TransactionSortDirection = exports.TransactionOrderDirection = exports.TransactionFrequencyType = exports.TransactionErrorCodes = exports.TransactionClaimSortField = exports.TransactionClaimDateType = exports.TransactionAuthorizationResult = exports.TransactionAuthStatus = exports.TrackerTimeEnum = exports.TrackerStrengthUnit = exports.TrackerSortDirection = exports.TrackerReminderStatusUpdate = exports.TrackerReminderStatus = exports.TrackerReminderSortingField = exports.TrackerReminderSettingsSortingField = void 0;
var AccountErrorCode;
(function (AccountErrorCode) {
    AccountErrorCode["ActivateOwnAccount"] = "ACTIVATE_OWN_ACCOUNT";
    AccountErrorCode["ActivateSuperuserAccount"] = "ACTIVATE_SUPERUSER_ACCOUNT";
    AccountErrorCode["DeactivateOwnAccount"] = "DEACTIVATE_OWN_ACCOUNT";
    AccountErrorCode["DeactivateSuperuserAccount"] = "DEACTIVATE_SUPERUSER_ACCOUNT";
    AccountErrorCode["DeleteConsumerAccount"] = "DELETE_CONSUMER_ACCOUNT";
    AccountErrorCode["DeleteNonStaffUser"] = "DELETE_NON_STAFF_USER";
    AccountErrorCode["DeleteOwnAccount"] = "DELETE_OWN_ACCOUNT";
    AccountErrorCode["DeleteStaffAccount"] = "DELETE_STAFF_ACCOUNT";
    AccountErrorCode["DeleteSuperuserAccount"] = "DELETE_SUPERUSER_ACCOUNT";
    AccountErrorCode["DeleteSupervendorAccount"] = "DELETE_SUPERVENDOR_ACCOUNT";
    AccountErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    AccountErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    AccountErrorCode["IntegrationTokenError"] = "INTEGRATION_TOKEN_ERROR";
    AccountErrorCode["Invalid"] = "INVALID";
    AccountErrorCode["InvalidCredentials"] = "INVALID_CREDENTIALS";
    AccountErrorCode["InvalidPassword"] = "INVALID_PASSWORD";
    AccountErrorCode["LeftNotManageablePermission"] = "LEFT_NOT_MANAGEABLE_PERMISSION";
    AccountErrorCode["NotFound"] = "NOT_FOUND";
    AccountErrorCode["OutOfScopeGroup"] = "OUT_OF_SCOPE_GROUP";
    AccountErrorCode["OutOfScopePermission"] = "OUT_OF_SCOPE_PERMISSION";
    AccountErrorCode["OutOfScopeServiceAccount"] = "OUT_OF_SCOPE_SERVICE_ACCOUNT";
    AccountErrorCode["OutOfScopeUser"] = "OUT_OF_SCOPE_USER";
    AccountErrorCode["PasswordEntirelyNumeric"] = "PASSWORD_ENTIRELY_NUMERIC";
    AccountErrorCode["PasswordTooCommon"] = "PASSWORD_TOO_COMMON";
    AccountErrorCode["PasswordTooShort"] = "PASSWORD_TOO_SHORT";
    AccountErrorCode["PasswordTooSimilar"] = "PASSWORD_TOO_SIMILAR";
    AccountErrorCode["Required"] = "REQUIRED";
    AccountErrorCode["Unique"] = "UNIQUE";
})(AccountErrorCode || (exports.AccountErrorCode = AccountErrorCode = {}));
var ActionFieldType;
(function (ActionFieldType) {
    ActionFieldType["Boolean"] = "BOOLEAN";
    ActionFieldType["Date"] = "DATE";
    ActionFieldType["List"] = "LIST";
    ActionFieldType["Number"] = "NUMBER";
    ActionFieldType["String"] = "STRING";
})(ActionFieldType || (exports.ActionFieldType = ActionFieldType = {}));
var ActivityLevel;
(function (ActivityLevel) {
    ActivityLevel["HeavyActive"] = "HEAVY_ACTIVE";
    ActivityLevel["LightlyActive"] = "LIGHTLY_ACTIVE";
    ActivityLevel["ModeratelyActive"] = "MODERATELY_ACTIVE";
    ActivityLevel["Sedentary"] = "SEDENTARY";
    ActivityLevel["VeryHeavyActive"] = "VERY_HEAVY_ACTIVE";
})(ActivityLevel || (exports.ActivityLevel = ActivityLevel = {}));
var ActivityType;
(function (ActivityType) {
    ActivityType["GenericDrug"] = "GENERIC_DRUG";
    ActivityType["TradeDrug"] = "TRADE_DRUG";
})(ActivityType || (exports.ActivityType = ActivityType = {}));
var Actor;
(function (Actor) {
    Actor["Doctor"] = "DOCTOR";
    Actor["Patient"] = "PATIENT";
})(Actor || (exports.Actor = Actor = {}));
var AddressTypeEnum;
(function (AddressTypeEnum) {
    AddressTypeEnum["Billing"] = "BILLING";
    AddressTypeEnum["Shipping"] = "SHIPPING";
})(AddressTypeEnum || (exports.AddressTypeEnum = AddressTypeEnum = {}));
var AdminPromotionSortingField;
(function (AdminPromotionSortingField) {
    AdminPromotionSortingField["Created"] = "CREATED";
    AdminPromotionSortingField["EndDate"] = "END_DATE";
    AdminPromotionSortingField["Name"] = "NAME";
    AdminPromotionSortingField["NameAr"] = "NAME_AR";
    AdminPromotionSortingField["Published"] = "PUBLISHED";
    AdminPromotionSortingField["StartDate"] = "START_DATE";
})(AdminPromotionSortingField || (exports.AdminPromotionSortingField = AdminPromotionSortingField = {}));
var AffectedType;
(function (AffectedType) {
    AffectedType["Appointment"] = "APPOINTMENT";
    AffectedType["Visit"] = "VISIT";
    AffectedType["VisitCall"] = "VISIT_CALL";
})(AffectedType || (exports.AffectedType = AffectedType = {}));
var AmountSourceType;
(function (AmountSourceType) {
    AmountSourceType["Appointment"] = "APPOINTMENT";
    AmountSourceType["Doctor"] = "DOCTOR";
    AmountSourceType["HealthProgram"] = "HEALTH_PROGRAM";
    AmountSourceType["MarketplaceOrder"] = "MARKETPLACE_ORDER";
    AmountSourceType["Order"] = "ORDER";
    AmountSourceType["OrderCheckoutToken"] = "ORDER_CHECKOUT_TOKEN";
    AmountSourceType["OutPatientJourney"] = "OUT_PATIENT_JOURNEY";
    AmountSourceType["PrescriptionOrder"] = "PRESCRIPTION_ORDER";
    AmountSourceType["Visit"] = "VISIT";
})(AmountSourceType || (exports.AmountSourceType = AmountSourceType = {}));
var AnxietyFrequency;
(function (AnxietyFrequency) {
    AnxietyFrequency["MoreThanHalfTheDays"] = "MORE_THAN_HALF_THE_DAYS";
    AnxietyFrequency["NearlyEveryDay"] = "NEARLY_EVERY_DAY";
    AnxietyFrequency["NotAtAll"] = "NOT_AT_ALL";
    AnxietyFrequency["SeveralDays"] = "SEVERAL_DAYS";
})(AnxietyFrequency || (exports.AnxietyFrequency = AnxietyFrequency = {}));
var AppRoleTypes;
(function (AppRoleTypes) {
    AppRoleTypes["Admin"] = "ADMIN";
    AppRoleTypes["User"] = "USER";
})(AppRoleTypes || (exports.AppRoleTypes = AppRoleTypes = {}));
var AppTypes;
(function (AppTypes) {
    AppTypes["Admin"] = "ADMIN";
    AppTypes["Aggregator"] = "AGGREGATOR";
    AppTypes["Customer"] = "CUSTOMER";
    AppTypes["Payer"] = "PAYER";
    AppTypes["Vendor"] = "VENDOR";
})(AppTypes || (exports.AppTypes = AppTypes = {}));
var AppointmentCategory;
(function (AppointmentCategory) {
    AppointmentCategory["Consultation"] = "CONSULTATION";
    AppointmentCategory["Lab"] = "LAB";
    AppointmentCategory["Rad"] = "RAD";
})(AppointmentCategory || (exports.AppointmentCategory = AppointmentCategory = {}));
var AppointmentCreationSource;
(function (AppointmentCreationSource) {
    AppointmentCreationSource["Automatic"] = "AUTOMATIC";
    AppointmentCreationSource["Other"] = "OTHER";
    AppointmentCreationSource["Personalized"] = "PERSONALIZED";
})(AppointmentCreationSource || (exports.AppointmentCreationSource = AppointmentCreationSource = {}));
var AppointmentFrequencyType;
(function (AppointmentFrequencyType) {
    AppointmentFrequencyType["Day"] = "Day";
    AppointmentFrequencyType["Month"] = "Month";
    AppointmentFrequencyType["Week"] = "Week";
    AppointmentFrequencyType["Year"] = "Year";
})(AppointmentFrequencyType || (exports.AppointmentFrequencyType = AppointmentFrequencyType = {}));
var AppointmentNotificationType;
(function (AppointmentNotificationType) {
    AppointmentNotificationType["Confirmation"] = "CONFIRMATION";
    AppointmentNotificationType["Reminder"] = "REMINDER";
})(AppointmentNotificationType || (exports.AppointmentNotificationType = AppointmentNotificationType = {}));
var AppointmentPaymentType;
(function (AppointmentPaymentType) {
    AppointmentPaymentType["CashOnly"] = "CASH_ONLY";
    AppointmentPaymentType["InsuranceCoverage"] = "INSURANCE_COVERAGE";
    AppointmentPaymentType["PrePaid"] = "PRE_PAID";
})(AppointmentPaymentType || (exports.AppointmentPaymentType = AppointmentPaymentType = {}));
var AppointmentSortingField;
(function (AppointmentSortingField) {
    /** Sort appointment by confirmed start time. */
    AppointmentSortingField["ConfirmedStartDate"] = "CONFIRMED_START_DATE";
    /** Sort appointments by creation date. */
    AppointmentSortingField["Created"] = "CREATED";
    /** Sort appointments by last modification date. */
    AppointmentSortingField["LastModifiedDate"] = "LAST_MODIFIED_DATE";
})(AppointmentSortingField || (exports.AppointmentSortingField = AppointmentSortingField = {}));
var AppointmentStatus;
(function (AppointmentStatus) {
    AppointmentStatus["Approved"] = "APPROVED";
    AppointmentStatus["CallInProgress"] = "CALL_IN_PROGRESS";
    AppointmentStatus["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    AppointmentStatus["CanceledByDoctor"] = "CANCELED_BY_DOCTOR";
    AppointmentStatus["Cancelled"] = "CANCELLED";
    AppointmentStatus["ChatInProgress"] = "CHAT_IN_PROGRESS";
    AppointmentStatus["ComingSoon"] = "COMING_SOON";
    AppointmentStatus["Confirmed"] = "CONFIRMED";
    AppointmentStatus["FinishedSuccessfully"] = "FINISHED_SUCCESSFULLY";
    AppointmentStatus["OutPatientJourneyAssessmentDone"] = "OUT_PATIENT_JOURNEY_ASSESSMENT_DONE";
    AppointmentStatus["OutPatientJourneyAssessmentPending"] = "OUT_PATIENT_JOURNEY_ASSESSMENT_PENDING";
    AppointmentStatus["OutPatientJourneyCheckedIn"] = "OUT_PATIENT_JOURNEY_CHECKED_IN";
    AppointmentStatus["OutPatientJourneyConsultationDone"] = "OUT_PATIENT_JOURNEY_CONSULTATION_DONE";
    AppointmentStatus["OutPatientJourneyConsultationPending"] = "OUT_PATIENT_JOURNEY_CONSULTATION_PENDING";
    AppointmentStatus["OutPatientJourneyPending"] = "OUT_PATIENT_JOURNEY_PENDING";
    AppointmentStatus["OutPatientJourneyTreatmentDone"] = "OUT_PATIENT_JOURNEY_TREATMENT_DONE";
    AppointmentStatus["PaymentFailed"] = "PAYMENT_FAILED";
    AppointmentStatus["PaymentPending"] = "PAYMENT_PENDING";
    AppointmentStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    AppointmentStatus["PendingApproval"] = "PENDING_APPROVAL";
    AppointmentStatus["Rejected"] = "REJECTED";
    AppointmentStatus["Request"] = "REQUEST";
    AppointmentStatus["RescheduledByConsumer"] = "RESCHEDULED_BY_CONSUMER";
    AppointmentStatus["RescheduledByDoctor"] = "RESCHEDULED_BY_DOCTOR";
    AppointmentStatus["Tentative"] = "TENTATIVE";
})(AppointmentStatus || (exports.AppointmentStatus = AppointmentStatus = {}));
var AppointmentType;
(function (AppointmentType) {
    AppointmentType["AtHome"] = "AT_HOME";
    AppointmentType["Diagnostic"] = "DIAGNOSTIC";
    AppointmentType["Online"] = "ONLINE";
    AppointmentType["Onsite"] = "ONSITE";
})(AppointmentType || (exports.AppointmentType = AppointmentType = {}));
var AppointmentTypeEnum;
(function (AppointmentTypeEnum) {
    AppointmentTypeEnum["AtHome"] = "AT_HOME";
    AppointmentTypeEnum["Diagnostic"] = "DIAGNOSTIC";
    AppointmentTypeEnum["Online"] = "ONLINE";
    AppointmentTypeEnum["Onsite"] = "ONSITE";
})(AppointmentTypeEnum || (exports.AppointmentTypeEnum = AppointmentTypeEnum = {}));
var ArticleCategorySortingField;
(function (ArticleCategorySortingField) {
    ArticleCategorySortingField["Code"] = "CODE";
    ArticleCategorySortingField["Created"] = "CREATED";
    ArticleCategorySortingField["Id"] = "ID";
    ArticleCategorySortingField["Modified"] = "MODIFIED";
})(ArticleCategorySortingField || (exports.ArticleCategorySortingField = ArticleCategorySortingField = {}));
var ArticleSortingField;
(function (ArticleSortingField) {
    ArticleSortingField["Created"] = "CREATED";
    ArticleSortingField["Id"] = "ID";
    ArticleSortingField["Modified"] = "MODIFIED";
    ArticleSortingField["Priority"] = "PRIORITY";
    ArticleSortingField["Title"] = "TITLE";
})(ArticleSortingField || (exports.ArticleSortingField = ArticleSortingField = {}));
var AssigneeType;
(function (AssigneeType) {
    AssigneeType["Patient"] = "PATIENT";
    AssigneeType["TeamMember"] = "TEAM_MEMBER";
})(AssigneeType || (exports.AssigneeType = AssigneeType = {}));
var AuditLogOperationType;
(function (AuditLogOperationType) {
    AuditLogOperationType["Create"] = "CREATE";
    AuditLogOperationType["Delete"] = "DELETE";
    AuditLogOperationType["Update"] = "UPDATE";
})(AuditLogOperationType || (exports.AuditLogOperationType = AuditLogOperationType = {}));
var AuthorizationStatus;
(function (AuthorizationStatus) {
    AuthorizationStatus["Approved"] = "APPROVED";
    AuthorizationStatus["New"] = "NEW";
    AuthorizationStatus["PartialApproved"] = "PARTIAL_APPROVED";
    AuthorizationStatus["Rejected"] = "REJECTED";
})(AuthorizationStatus || (exports.AuthorizationStatus = AuthorizationStatus = {}));
var BlockErrorCode;
(function (BlockErrorCode) {
    BlockErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    BlockErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    BlockErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    BlockErrorCode["Invalid"] = "INVALID";
    BlockErrorCode["NotFound"] = "NOT_FOUND";
    BlockErrorCode["Required"] = "REQUIRED";
    BlockErrorCode["Unique"] = "UNIQUE";
})(BlockErrorCode || (exports.BlockErrorCode = BlockErrorCode = {}));
var BlockOrderField;
(function (BlockOrderField) {
    BlockOrderField["Created"] = "CREATED";
    BlockOrderField["Name"] = "NAME";
})(BlockOrderField || (exports.BlockOrderField = BlockOrderField = {}));
var BranchOrderField;
(function (BranchOrderField) {
    BranchOrderField["Created"] = "CREATED";
    BranchOrderField["LastMessageSent"] = "LAST_MESSAGE_SENT";
    BranchOrderField["Name"] = "NAME";
    BranchOrderField["Nearest"] = "NEAREST";
})(BranchOrderField || (exports.BranchOrderField = BranchOrderField = {}));
var BranchType;
(function (BranchType) {
    BranchType["Clinic"] = "CLINIC";
    BranchType["DiagnosticCenter"] = "DIAGNOSTIC_CENTER";
    BranchType["Hospital"] = "HOSPITAL";
    BranchType["Pharmacy"] = "PHARMACY";
})(BranchType || (exports.BranchType = BranchType = {}));
var BulkErrorCodes;
(function (BulkErrorCodes) {
    BulkErrorCodes["BulkCancelBulkFile"] = "BULK_CANCEL_BULK_FILE";
    BulkErrorCodes["BulkDuplicateRowData"] = "BULK_DUPLICATE_ROW_DATA";
    BulkErrorCodes["BulkFieldRequired"] = "BULK_FIELD_REQUIRED";
    BulkErrorCodes["BulkFileNotFound"] = "BULK_FILE_NOT_FOUND";
    BulkErrorCodes["BulkFileServiceNotReachable"] = "BULK_FILE_SERVICE_NOT_REACHABLE";
    BulkErrorCodes["BulkInvalidDateFormat"] = "BULK_INVALID_DATE_FORMAT";
    BulkErrorCodes["BulkInvalidFileExtension"] = "BULK_INVALID_FILE_EXTENSION";
    BulkErrorCodes["BulkNoneNegativePageSize"] = "BULK_NONE_NEGATIVE_PAGE_SIZE";
    BulkErrorCodes["BulkPermissionDenied"] = "BULK_PERMISSION_DENIED";
    BulkErrorCodes["BulkTemplateNotEnabled"] = "BULK_TEMPLATE_NOT_ENABLED";
    BulkErrorCodes["BulkUnknown"] = "BULK_UNKNOWN";
    BulkErrorCodes["BulkUnknownType"] = "BULK_UNKNOWN_TYPE";
    BulkErrorCodes["FileAlreadyExist"] = "FILE_ALREADY_EXIST";
})(BulkErrorCodes || (exports.BulkErrorCodes = BulkErrorCodes = {}));
var BulkFileSortField;
(function (BulkFileSortField) {
    BulkFileSortField["Code"] = "CODE";
    BulkFileSortField["CreatedDate"] = "CREATED_DATE";
    BulkFileSortField["FailedRecordCount"] = "FAILED_RECORD_COUNT";
    BulkFileSortField["FileName"] = "FILE_NAME";
    BulkFileSortField["ProcessedRecordCount"] = "PROCESSED_RECORD_COUNT";
    BulkFileSortField["RecordsCount"] = "RECORDS_COUNT";
    BulkFileSortField["UpdatedDate"] = "UPDATED_DATE";
})(BulkFileSortField || (exports.BulkFileSortField = BulkFileSortField = {}));
var BulkFilesOrderDirection;
(function (BulkFilesOrderDirection) {
    BulkFilesOrderDirection["Asc"] = "ASC";
    BulkFilesOrderDirection["Desc"] = "DESC";
})(BulkFilesOrderDirection || (exports.BulkFilesOrderDirection = BulkFilesOrderDirection = {}));
var BulkProcessingStatus;
(function (BulkProcessingStatus) {
    BulkProcessingStatus["Cancelled"] = "CANCELLED";
    BulkProcessingStatus["Pending"] = "PENDING";
    BulkProcessingStatus["Processed"] = "PROCESSED";
    BulkProcessingStatus["UnderProcess"] = "UNDER_PROCESS";
})(BulkProcessingStatus || (exports.BulkProcessingStatus = BulkProcessingStatus = {}));
var BulkTemplateCode;
(function (BulkTemplateCode) {
    BulkTemplateCode["Doctor"] = "DOCTOR";
    BulkTemplateCode["HealthParameter"] = "HEALTH_PARAMETER";
    BulkTemplateCode["HealthProgramMember"] = "HEALTH_PROGRAM_MEMBER";
    BulkTemplateCode["HealthProgramNetwork"] = "HEALTH_PROGRAM_NETWORK";
    BulkTemplateCode["HealthProgramNetworkProvider"] = "HEALTH_PROGRAM_NETWORK_PROVIDER";
    BulkTemplateCode["MedicalForm"] = "MEDICAL_FORM";
})(BulkTemplateCode || (exports.BulkTemplateCode = BulkTemplateCode = {}));
var CalculatedPaymentStatusForSubscription;
(function (CalculatedPaymentStatusForSubscription) {
    CalculatedPaymentStatusForSubscription["PaymentChargedBack"] = "PAYMENT_CHARGED_BACK";
    CalculatedPaymentStatusForSubscription["PaymentPending"] = "PAYMENT_PENDING";
    CalculatedPaymentStatusForSubscription["PaymentRejected"] = "PAYMENT_REJECTED";
    CalculatedPaymentStatusForSubscription["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    CalculatedPaymentStatusForSubscription["PaymentUnderProcessing"] = "PAYMENT_UNDER_PROCESSING";
    CalculatedPaymentStatusForSubscription["RefundedToWallet"] = "REFUNDED_TO_WALLET";
    CalculatedPaymentStatusForSubscription["RefundChargedBack"] = "REFUND_CHARGED_BACK";
    CalculatedPaymentStatusForSubscription["RefundPending"] = "REFUND_PENDING";
    CalculatedPaymentStatusForSubscription["RefundRejected"] = "REFUND_REJECTED";
    CalculatedPaymentStatusForSubscription["RefundSucceeded"] = "REFUND_SUCCEEDED";
})(CalculatedPaymentStatusForSubscription || (exports.CalculatedPaymentStatusForSubscription = CalculatedPaymentStatusForSubscription = {}));
var CallRecordStatus;
(function (CallRecordStatus) {
    CallRecordStatus["Available"] = "AVAILABLE";
    CallRecordStatus["Ready"] = "READY";
    CallRecordStatus["TranscriptedFailed"] = "TRANSCRIPTED_FAILED";
    CallRecordStatus["TranscriptedSucceeded"] = "TRANSCRIPTED_SUCCEEDED";
    CallRecordStatus["Unavailable"] = "UNAVAILABLE";
})(CallRecordStatus || (exports.CallRecordStatus = CallRecordStatus = {}));
var CallSummaryAuditRecordType;
(function (CallSummaryAuditRecordType) {
    CallSummaryAuditRecordType["Snapshot"] = "SNAPSHOT";
})(CallSummaryAuditRecordType || (exports.CallSummaryAuditRecordType = CallSummaryAuditRecordType = {}));
var CallbackRequestStatus;
(function (CallbackRequestStatus) {
    CallbackRequestStatus["InProgress"] = "IN_PROGRESS";
    CallbackRequestStatus["New"] = "NEW";
    CallbackRequestStatus["Resolved"] = "RESOLVED";
})(CallbackRequestStatus || (exports.CallbackRequestStatus = CallbackRequestStatus = {}));
var CareType;
(function (CareType) {
    CareType["ErxCoverage"] = "ERX_COVERAGE";
    CareType["ImmediateCare"] = "IMMEDIATE_CARE";
    CareType["Proactive"] = "PROACTIVE";
    CareType["Reactive"] = "REACTIVE";
})(CareType || (exports.CareType = CareType = {}));
var ChartType;
(function (ChartType) {
    ChartType["Bar"] = "BAR";
    ChartType["HorizontalBar"] = "HORIZONTAL_BAR";
    ChartType["Line"] = "LINE";
    ChartType["Table"] = "TABLE";
})(ChartType || (exports.ChartType = ChartType = {}));
var ChatErrorCode;
(function (ChatErrorCode) {
    ChatErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    ChatErrorCode["Invalid"] = "INVALID";
    ChatErrorCode["NotFound"] = "NOT_FOUND";
    ChatErrorCode["Required"] = "REQUIRED";
})(ChatErrorCode || (exports.ChatErrorCode = ChatErrorCode = {}));
var ChatSortingField;
(function (ChatSortingField) {
    ChatSortingField["Code"] = "CODE";
    ChatSortingField["Created"] = "CREATED";
    ChatSortingField["Description"] = "DESCRIPTION";
    ChatSortingField["Name"] = "NAME";
    ChatSortingField["Updated"] = "UPDATED";
})(ChatSortingField || (exports.ChatSortingField = ChatSortingField = {}));
var CityOrderField;
(function (CityOrderField) {
    CityOrderField["Created"] = "CREATED";
    CityOrderField["Name"] = "NAME";
})(CityOrderField || (exports.CityOrderField = CityOrderField = {}));
var CodeSystemCode;
(function (CodeSystemCode) {
    CodeSystemCode["Allergy"] = "ALLERGY";
    CodeSystemCode["CancellationReason"] = "CANCELLATION_REASON";
    CodeSystemCode["ChronicDisease"] = "CHRONIC_DISEASE";
    CodeSystemCode["ChronicMedication"] = "CHRONIC_MEDICATION";
    CodeSystemCode["City"] = "CITY";
    CodeSystemCode["Clinician"] = "CLINICIAN";
    CodeSystemCode["Cpt"] = "CPT";
    CodeSystemCode["DenialCode"] = "DENIAL_CODE";
    CodeSystemCode["Diagnosis"] = "DIAGNOSIS";
    CodeSystemCode["DiagnosisRelatedGroup"] = "DIAGNOSIS_RELATED_GROUP";
    CodeSystemCode["Disability"] = "DISABILITY";
    CodeSystemCode["Division"] = "DIVISION";
    CodeSystemCode["Family"] = "FAMILY";
    CodeSystemCode["GenericDrug"] = "GENERIC_DRUG";
    CodeSystemCode["GenericDrugExtraData"] = "GENERIC_DRUG_EXTRA_DATA";
    CodeSystemCode["HealthPackageCategory"] = "HEALTH_PACKAGE_CATEGORY";
    CodeSystemCode["HealthProfessionalSpeciality"] = "HEALTH_PROFESSIONAL_SPECIALITY";
    CodeSystemCode["HealthProvider"] = "HEALTH_PROVIDER";
    CodeSystemCode["LabTest"] = "LAB_TEST";
    CodeSystemCode["Loinc"] = "LOINC";
    CodeSystemCode["MedicalService"] = "MEDICAL_SERVICE";
    CodeSystemCode["MobileTranslation"] = "MOBILE_TRANSLATION";
    CodeSystemCode["Nationality"] = "NATIONALITY";
    CodeSystemCode["NonChronicDisease"] = "NON_CHRONIC_DISEASE";
    CodeSystemCode["RejectionReason"] = "REJECTION_REASON";
    CodeSystemCode["RouteOfAdmin"] = "ROUTE_OF_ADMIN";
    CodeSystemCode["Service"] = "SERVICE";
    CodeSystemCode["Speciality"] = "SPECIALITY";
    CodeSystemCode["Supplement"] = "SUPPLEMENT";
    CodeSystemCode["Surgery"] = "SURGERY";
    CodeSystemCode["Temperature"] = "TEMPERATURE";
    CodeSystemCode["TradeDrug"] = "TRADE_DRUG";
    CodeSystemCode["TradeDrugExtraData"] = "TRADE_DRUG_EXTRA_DATA";
    CodeSystemCode["UnitId"] = "UNIT_ID";
})(CodeSystemCode || (exports.CodeSystemCode = CodeSystemCode = {}));
var Community;
(function (Community) {
    Community["Rural"] = "RURAL";
    Community["Urban"] = "URBAN";
})(Community || (exports.Community = Community = {}));
var ConditionOperation;
(function (ConditionOperation) {
    ConditionOperation["And"] = "AND";
    ConditionOperation["Or"] = "OR";
})(ConditionOperation || (exports.ConditionOperation = ConditionOperation = {}));
var ConsentLevel;
(function (ConsentLevel) {
    ConsentLevel["All"] = "ALL";
    ConsentLevel["Basic"] = "BASIC";
    ConsentLevel["Medical"] = "MEDICAL";
})(ConsentLevel || (exports.ConsentLevel = ConsentLevel = {}));
var ConsentStatus;
(function (ConsentStatus) {
    ConsentStatus["Approved"] = "APPROVED";
    ConsentStatus["Closed"] = "CLOSED";
    ConsentStatus["Pending"] = "PENDING";
    ConsentStatus["Rejected"] = "REJECTED";
})(ConsentStatus || (exports.ConsentStatus = ConsentStatus = {}));
var ConsentType;
(function (ConsentType) {
    ConsentType["All"] = "ALL";
    ConsentType["View"] = "VIEW";
})(ConsentType || (exports.ConsentType = ConsentType = {}));
var ConsumerViewPreferenceErrorCode;
(function (ConsumerViewPreferenceErrorCode) {
    ConsumerViewPreferenceErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    ConsumerViewPreferenceErrorCode["Invalid"] = "INVALID";
    ConsumerViewPreferenceErrorCode["NotFound"] = "NOT_FOUND";
    ConsumerViewPreferenceErrorCode["Required"] = "REQUIRED";
    ConsumerViewPreferenceErrorCode["Unique"] = "UNIQUE";
})(ConsumerViewPreferenceErrorCode || (exports.ConsumerViewPreferenceErrorCode = ConsumerViewPreferenceErrorCode = {}));
var Coverage;
(function (Coverage) {
    Coverage["FullyCovered"] = "FULLY_COVERED";
    Coverage["NotCovered"] = "NOT_COVERED";
    Coverage["PartiallyCovered"] = "PARTIALLY_COVERED";
})(Coverage || (exports.Coverage = Coverage = {}));
var CoverageByType;
(function (CoverageByType) {
    CoverageByType["Cash"] = "CASH";
    CoverageByType["Insurance"] = "INSURANCE";
    CoverageByType["Provider"] = "PROVIDER";
    CoverageByType["Tpa"] = "TPA";
})(CoverageByType || (exports.CoverageByType = CoverageByType = {}));
var Currency;
(function (Currency) {
    Currency["Aed"] = "AED";
    Currency["Eur"] = "EUR";
    Currency["Sar"] = "SAR";
    Currency["Usd"] = "USD";
})(Currency || (exports.Currency = Currency = {}));
var CustomerEventsEnum;
(function (CustomerEventsEnum) {
    CustomerEventsEnum["AccountCreated"] = "ACCOUNT_CREATED";
    CustomerEventsEnum["AccountCreatedFromSso"] = "ACCOUNT_CREATED_FROM_SSO";
    CustomerEventsEnum["CustomerDeleted"] = "CUSTOMER_DELETED";
    CustomerEventsEnum["DigitalLinkDownloaded"] = "DIGITAL_LINK_DOWNLOADED";
    CustomerEventsEnum["EmailAssigned"] = "EMAIL_ASSIGNED";
    CustomerEventsEnum["EmailChanged"] = "EMAIL_CHANGED";
    CustomerEventsEnum["EmailChangedRequest"] = "EMAIL_CHANGED_REQUEST";
    CustomerEventsEnum["NameAssigned"] = "NAME_ASSIGNED";
    CustomerEventsEnum["NoteAdded"] = "NOTE_ADDED";
    CustomerEventsEnum["NoteAddedToOrder"] = "NOTE_ADDED_TO_ORDER";
    CustomerEventsEnum["PasswordChanged"] = "PASSWORD_CHANGED";
    CustomerEventsEnum["PasswordReset"] = "PASSWORD_RESET";
    CustomerEventsEnum["PasswordResetLinkSent"] = "PASSWORD_RESET_LINK_SENT";
    CustomerEventsEnum["PlacedOrder"] = "PLACED_ORDER";
})(CustomerEventsEnum || (exports.CustomerEventsEnum = CustomerEventsEnum = {}));
var CustomerSupportOrderStatusEnum;
(function (CustomerSupportOrderStatusEnum) {
    CustomerSupportOrderStatusEnum["Delivered"] = "DELIVERED";
    CustomerSupportOrderStatusEnum["Failed"] = "FAILED";
    CustomerSupportOrderStatusEnum["Returned"] = "RETURNED";
})(CustomerSupportOrderStatusEnum || (exports.CustomerSupportOrderStatusEnum = CustomerSupportOrderStatusEnum = {}));
var DayOfWeekEnum;
(function (DayOfWeekEnum) {
    DayOfWeekEnum["Fri"] = "FRI";
    DayOfWeekEnum["Mon"] = "MON";
    DayOfWeekEnum["Sat"] = "SAT";
    DayOfWeekEnum["Sun"] = "SUN";
    DayOfWeekEnum["Thu"] = "THU";
    DayOfWeekEnum["Tue"] = "TUE";
    DayOfWeekEnum["Wed"] = "WED";
})(DayOfWeekEnum || (exports.DayOfWeekEnum = DayOfWeekEnum = {}));
var DecisionMakerErrorCodes;
(function (DecisionMakerErrorCodes) {
    DecisionMakerErrorCodes["DecisionFileNotFound"] = "DECISION_FILE_NOT_FOUND";
    DecisionMakerErrorCodes["DecisionFileServiceNotReachable"] = "DECISION_FILE_SERVICE_NOT_REACHABLE";
    DecisionMakerErrorCodes["DecisionInvalidFileExtension"] = "DECISION_INVALID_FILE_EXTENSION";
    DecisionMakerErrorCodes["DecisionInvalidId"] = "DECISION_INVALID_ID";
    DecisionMakerErrorCodes["DecisionMakerDuplicateListItem"] = "DECISION_MAKER_DUPLICATE_LIST_ITEM";
    DecisionMakerErrorCodes["DecisionMakerDuplicateListName"] = "DECISION_MAKER_DUPLICATE_LIST_NAME";
    DecisionMakerErrorCodes["DecisionMakerDuplicateRuleName"] = "DECISION_MAKER_DUPLICATE_RULE_NAME";
    DecisionMakerErrorCodes["DecisionMakerEntityNotFound"] = "DECISION_MAKER_ENTITY_NOT_FOUND";
    DecisionMakerErrorCodes["DecisionMakerFieldMandatory"] = "DECISION_MAKER_FIELD_MANDATORY";
    DecisionMakerErrorCodes["DecisionMakerFieldSize"] = "DECISION_MAKER_FIELD_SIZE";
    DecisionMakerErrorCodes["DecisionMakerFieldSizeException"] = "DECISION_MAKER_FIELD_SIZE_EXCEPTION";
    DecisionMakerErrorCodes["DecisionMakerInvalidAction"] = "DECISION_MAKER_INVALID_ACTION";
    DecisionMakerErrorCodes["DecisionMakerInvalidOperator"] = "DECISION_MAKER_INVALID_OPERATOR";
    DecisionMakerErrorCodes["DecisionMakerInvalidRule"] = "DECISION_MAKER_INVALID_RULE";
    DecisionMakerErrorCodes["DecisionMakerInvalidValue"] = "DECISION_MAKER_INVALID_VALUE";
    DecisionMakerErrorCodes["DecisionMakerNoneNegativePageSize"] = "DECISION_MAKER_NONE_NEGATIVE_PAGE_SIZE";
    DecisionMakerErrorCodes["DecisionMakerSubListNotFound"] = "DECISION_MAKER_SUB_LIST_NOT_FOUND";
    DecisionMakerErrorCodes["DecisionMakerUnknown"] = "DECISION_MAKER_UNKNOWN";
    DecisionMakerErrorCodes["DecisionMakerUnknownType"] = "DECISION_MAKER_UNKNOWN_TYPE";
    DecisionMakerErrorCodes["DecisionMakerValidationError"] = "DECISION_MAKER_VALIDATION_ERROR";
    DecisionMakerErrorCodes["DecisionPermissionDenied"] = "DECISION_PERMISSION_DENIED";
    DecisionMakerErrorCodes["InvalidOrExpiredToken"] = "INVALID_OR_EXPIRED_TOKEN";
})(DecisionMakerErrorCodes || (exports.DecisionMakerErrorCodes = DecisionMakerErrorCodes = {}));
var DecisionMakerOrderDirection;
(function (DecisionMakerOrderDirection) {
    DecisionMakerOrderDirection["Asc"] = "ASC";
    DecisionMakerOrderDirection["Desc"] = "DESC";
})(DecisionMakerOrderDirection || (exports.DecisionMakerOrderDirection = DecisionMakerOrderDirection = {}));
var DecisionPlanCategory;
(function (DecisionPlanCategory) {
    DecisionPlanCategory["HealthCondition"] = "HEALTH_CONDITION";
    DecisionPlanCategory["Intervention"] = "INTERVENTION";
    DecisionPlanCategory["Optima"] = "OPTIMA";
    DecisionPlanCategory["PatientEligibility"] = "PATIENT_ELIGIBILITY";
    DecisionPlanCategory["RiskStratification"] = "RISK_STRATIFICATION";
    DecisionPlanCategory["TaskFlow"] = "TASK_FLOW";
    DecisionPlanCategory["UserRequiredAction"] = "USER_REQUIRED_ACTION";
})(DecisionPlanCategory || (exports.DecisionPlanCategory = DecisionPlanCategory = {}));
var DecisionSortField;
(function (DecisionSortField) {
    DecisionSortField["DecisionPlan"] = "DECISION_PLAN";
    DecisionSortField["IsActive"] = "IS_ACTIVE";
    DecisionSortField["Name"] = "NAME";
    DecisionSortField["Type"] = "TYPE";
})(DecisionSortField || (exports.DecisionSortField = DecisionSortField = {}));
var DefaultSortingField;
(function (DefaultSortingField) {
    DefaultSortingField["Created"] = "CREATED";
})(DefaultSortingField || (exports.DefaultSortingField = DefaultSortingField = {}));
var DeliveryType;
(function (DeliveryType) {
    DeliveryType["Delivery"] = "DELIVERY";
    DeliveryType["Pickup"] = "PICKUP";
})(DeliveryType || (exports.DeliveryType = DeliveryType = {}));
var DepressionFrequency;
(function (DepressionFrequency) {
    DepressionFrequency["MoreThanHalfTheDays"] = "MORE_THAN_HALF_THE_DAYS";
    DepressionFrequency["NearlyEveryDay"] = "NEARLY_EVERY_DAY";
    DepressionFrequency["NotAtAll"] = "NOT_AT_ALL";
    DepressionFrequency["SeveralDays"] = "SEVERAL_DAYS";
})(DepressionFrequency || (exports.DepressionFrequency = DepressionFrequency = {}));
var DestinationType;
(function (DestinationType) {
    DestinationType["Eclaim"] = "ECLAIM";
    DestinationType["Io"] = "IO";
})(DestinationType || (exports.DestinationType = DestinationType = {}));
var DiabetesRisk;
(function (DiabetesRisk) {
    DiabetesRisk["High"] = "HIGH";
    DiabetesRisk["Low"] = "LOW";
})(DiabetesRisk || (exports.DiabetesRisk = DiabetesRisk = {}));
var DiagnoseType;
(function (DiagnoseType) {
    DiagnoseType["Principal"] = "PRINCIPAL";
    DiagnoseType["Secondary"] = "SECONDARY";
})(DiagnoseType || (exports.DiagnoseType = DiagnoseType = {}));
var DiagnosisPrescriptionErrorCode;
(function (DiagnosisPrescriptionErrorCode) {
    DiagnosisPrescriptionErrorCode["ControlledDrug"] = "CONTROLLED_DRUG";
    DiagnosisPrescriptionErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    DiagnosisPrescriptionErrorCode["Ingested"] = "INGESTED";
    DiagnosisPrescriptionErrorCode["Invalid"] = "INVALID";
    DiagnosisPrescriptionErrorCode["NotFound"] = "NOT_FOUND";
    DiagnosisPrescriptionErrorCode["Required"] = "REQUIRED";
    DiagnosisPrescriptionErrorCode["Unique"] = "UNIQUE";
})(DiagnosisPrescriptionErrorCode || (exports.DiagnosisPrescriptionErrorCode = DiagnosisPrescriptionErrorCode = {}));
var DiagnosisType;
(function (DiagnosisType) {
    DiagnosisType["Principal"] = "Principal";
    DiagnosisType["Secondary"] = "Secondary";
})(DiagnosisType || (exports.DiagnosisType = DiagnosisType = {}));
var DietQuality;
(function (DietQuality) {
    DietQuality["Adequate"] = "ADEQUATE";
    DietQuality["Fair"] = "FAIR";
    DietQuality["Poor"] = "POOR";
    DietQuality["VeryPoor"] = "VERY_POOR";
})(DietQuality || (exports.DietQuality = DietQuality = {}));
var DietType;
(function (DietType) {
    DietType["Balanced"] = "BALANCED";
    DietType["HighProtein"] = "HIGH_PROTEIN";
    DietType["Keto"] = "KETO";
    DietType["LowCarb"] = "LOW_CARB";
    DietType["LowFat"] = "LOW_FAT";
    DietType["MealReplacement"] = "MEAL_REPLACEMENT";
    DietType["VeryLowCalorie"] = "VERY_LOW_CALORIE";
})(DietType || (exports.DietType = DietType = {}));
var DoctorAvailabilityStatus;
(function (DoctorAvailabilityStatus) {
    DoctorAvailabilityStatus["AvailableLevel_1"] = "AVAILABLE_LEVEL_1";
    DoctorAvailabilityStatus["AvailableLevel_2"] = "AVAILABLE_LEVEL_2";
    DoctorAvailabilityStatus["Unavailable"] = "UNAVAILABLE";
})(DoctorAvailabilityStatus || (exports.DoctorAvailabilityStatus = DoctorAvailabilityStatus = {}));
var DoctorAvailabilityStatusEnum;
(function (DoctorAvailabilityStatusEnum) {
    DoctorAvailabilityStatusEnum["AvailableLevel_1"] = "AVAILABLE_LEVEL_1";
    DoctorAvailabilityStatusEnum["AvailableLevel_2"] = "AVAILABLE_LEVEL_2";
    DoctorAvailabilityStatusEnum["Unavailable"] = "UNAVAILABLE";
})(DoctorAvailabilityStatusEnum || (exports.DoctorAvailabilityStatusEnum = DoctorAvailabilityStatusEnum = {}));
var DoctorErrorCode;
(function (DoctorErrorCode) {
    DoctorErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    DoctorErrorCode["Invalid"] = "INVALID";
    DoctorErrorCode["NotFound"] = "NOT_FOUND";
    DoctorErrorCode["Required"] = "REQUIRED";
    DoctorErrorCode["Unique"] = "UNIQUE";
})(DoctorErrorCode || (exports.DoctorErrorCode = DoctorErrorCode = {}));
var DoctorOrderField;
(function (DoctorOrderField) {
    DoctorOrderField["Created"] = "CREATED";
    DoctorOrderField["Distance"] = "DISTANCE";
    DoctorOrderField["Rating"] = "RATING";
})(DoctorOrderField || (exports.DoctorOrderField = DoctorOrderField = {}));
var DoctorSeniorityEnum;
(function (DoctorSeniorityEnum) {
    DoctorSeniorityEnum["Cp"] = "CP";
    DoctorSeniorityEnum["Gp"] = "GP";
    DoctorSeniorityEnum["Sp"] = "SP";
})(DoctorSeniorityEnum || (exports.DoctorSeniorityEnum = DoctorSeniorityEnum = {}));
var EducationLevel;
(function (EducationLevel) {
    EducationLevel["HighSchoolGraduate"] = "HIGH_SCHOOL_GRADUATE";
    EducationLevel["LessThanHighSchool"] = "LESS_THAN_HIGH_SCHOOL";
    EducationLevel["UniversityGraduate"] = "UNIVERSITY_GRADUATE";
})(EducationLevel || (exports.EducationLevel = EducationLevel = {}));
var EntityType;
(function (EntityType) {
    EntityType["Boolean"] = "BOOLEAN";
    EntityType["Date"] = "DATE";
    EntityType["Id"] = "ID";
    EntityType["List"] = "LIST";
    EntityType["Number"] = "NUMBER";
    EntityType["String"] = "STRING";
})(EntityType || (exports.EntityType = EntityType = {}));
var ErrorDetail;
(function (ErrorDetail) {
    /**
     * The deadline expired before the operation could complete.
     *
     * For operations that change the state of the system, this error
     * may be returned even if the operation has completed successfully.
     * For example, a successful response from a server could have been
     * delayed long enough for the deadline to expire.
     *
     * HTTP Mapping: 504 Gateway Timeout
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["DeadlineExceeded"] = "DEADLINE_EXCEEDED";
    /**
     * The server detected that the client is exhibiting a behavior that
     * might be generating excessive load.
     *
     * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["EnhanceYourCalm"] = "ENHANCE_YOUR_CALM";
    /**
     * The requested field is not found in the schema.
     *
     * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
     * query is valid, but is unable to return a result (if, for example, a
     * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
     * returned by the server to signify that the requested field is not known to exist.
     * This may be returned in lieu of failing the entire query.
     * See also `PERMISSION_DENIED` for cases where the
     * requested field is invalid only for the given user or class of users.
     *
     * HTTP Mapping: 404 Not Found
     * Error Type: BAD_REQUEST
     */
    ErrorDetail["FieldNotFound"] = "FIELD_NOT_FOUND";
    /**
     * The client specified an invalid argument.
     *
     * Note that this differs from `FAILED_PRECONDITION`.
     * `INVALID_ARGUMENT` indicates arguments that are problematic
     * regardless of the state of the system (e.g., a malformed file name).
     *
     * HTTP Mapping: 400 Bad Request
     * Error Type: BAD_REQUEST
     */
    ErrorDetail["InvalidArgument"] = "INVALID_ARGUMENT";
    /**
     * The provided cursor is not valid.
     *
     * The most common usage for this error is when a client is paginating
     * through a list that uses stateful cursors. In that case, the provided
     * cursor may be expired.
     *
     * HTTP Mapping: 404 Not Found
     * Error Type: NOT_FOUND
     */
    ErrorDetail["InvalidCursor"] = "INVALID_CURSOR";
    /**
     * Unable to perform operation because a required resource is missing.
     *
     * Example: Client is attempting to refresh a list, but the specified
     * list is expired. This requires an action by the client to get a new list.
     *
     * If the user is simply trying GET a resource that is not found,
     * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
     * is to be used particularly when the user is performing an operation
     * that requires a particular resource to exist.
     *
     * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
     * Error Type: FAILED_PRECONDITION
     */
    ErrorDetail["MissingResource"] = "MISSING_RESOURCE";
    /**
     * Service Error.
     *
     * There is a problem with an upstream service.
     *
     * This may be returned if a gateway receives an unknown error from a service
     * or if a service is unreachable.
     * If a request times out which waiting on a response from a service,
     * `DEADLINE_EXCEEDED` may be returned instead.
     * If a service returns a more specific error Type, the specific error Type may
     * be returned instead.
     *
     * HTTP Mapping: 502 Bad Gateway
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["ServiceError"] = "SERVICE_ERROR";
    /**
     * Request failed due to network errors.
     *
     * HTTP Mapping: 503 Unavailable
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["TcpFailure"] = "TCP_FAILURE";
    /**
     * Request throttled based on server concurrency limits.
     *
     * HTTP Mapping: 503 Unavailable
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["ThrottledConcurrency"] = "THROTTLED_CONCURRENCY";
    /**
     * Request throttled based on server CPU limits
     *
     * HTTP Mapping: 503 Unavailable.
     * Error Type: UNAVAILABLE
     */
    ErrorDetail["ThrottledCpu"] = "THROTTLED_CPU";
    /**
     * The operation is not implemented or is not currently supported/enabled.
     *
     * HTTP Mapping: 501 Not Implemented
     * Error Type: BAD_REQUEST
     */
    ErrorDetail["Unimplemented"] = "UNIMPLEMENTED";
    /**
     * Unknown error.
     *
     * This error should only be returned when no other error detail applies.
     * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
     *
     * HTTP Mapping: 500 Internal Server Error
     */
    ErrorDetail["Unknown"] = "UNKNOWN";
})(ErrorDetail || (exports.ErrorDetail = ErrorDetail = {}));
var ErrorType;
(function (ErrorType) {
    /**
     * Bad Request.
     *
     * There is a problem with the request.
     * Retrying the same request is not likely to succeed.
     * An example would be a query or argument that cannot be deserialized.
     *
     * HTTP Mapping: 400 Bad Request
     */
    ErrorType["BadRequest"] = "BAD_REQUEST";
    /**
     * The operation was rejected because the system is not in a state
     * required for the operation's execution.  For example, the directory
     * to be deleted is non-empty, an rmdir operation is applied to
     * a non-directory, etc.
     *
     * Service implementers can use the following guidelines to decide
     * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
     *
     * - Use `UNAVAILABLE` if the client can retry just the failing call.
     * - Use `FAILED_PRECONDITION` if the client should not retry until
     * the system state has been explicitly fixed.  E.g., if an "rmdir"
     *      fails because the directory is non-empty, `FAILED_PRECONDITION`
     * should be returned since the client should not retry unless
     * the files are deleted from the directory.
     *
     * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
     */
    ErrorType["FailedPrecondition"] = "FAILED_PRECONDITION";
    /**
     * Internal error.
     *
     * An unexpected internal error was encountered. This means that some
     * invariants expected by the underlying system have been broken.
     * This error code is reserved for serious errors.
     *
     * HTTP Mapping: 500 Internal Server Error
     */
    ErrorType["Internal"] = "INTERNAL";
    /**
     * The requested entity was not found.
     *
     * This could apply to a resource that has never existed (e.g. bad resource id),
     * or a resource that no longer exists (e.g. cache expired.)
     *
     * Note to server developers: if a request is denied for an entire class
     * of users, such as gradual feature rollout or undocumented allowlist,
     * `NOT_FOUND` may be used. If a request is denied for some users within
     * a class of users, such as user-based access control, `PERMISSION_DENIED`
     * must be used.
     *
     * HTTP Mapping: 404 Not Found
     */
    ErrorType["NotFound"] = "NOT_FOUND";
    /**
     * The caller does not have permission to execute the specified
     * operation.
     *
     * `PERMISSION_DENIED` must not be used for rejections
     * caused by exhausting some resource or quota.
     * `PERMISSION_DENIED` must not be used if the caller
     * cannot be identified (use `UNAUTHENTICATED`
     * instead for those errors).
     *
     * This error Type does not imply the
     * request is valid or the requested entity exists or satisfies
     * other pre-conditions.
     *
     * HTTP Mapping: 403 Forbidden
     */
    ErrorType["PermissionDenied"] = "PERMISSION_DENIED";
    /**
     * The request does not have valid authentication credentials.
     *
     * This is intended to be returned only for routes that require
     * authentication.
     *
     * HTTP Mapping: 401 Unauthorized
     */
    ErrorType["Unauthenticated"] = "UNAUTHENTICATED";
    /**
     * Currently Unavailable.
     *
     * The service is currently unavailable.  This is most likely a
     * transient condition, which can be corrected by retrying with
     * a backoff.
     *
     * HTTP Mapping: 503 Unavailable
     */
    ErrorType["Unavailable"] = "UNAVAILABLE";
    /**
     * Unknown error.
     *
     * For example, this error may be returned when
     * an error code received from another address space belongs to
     * an error space that is not known in this address space.  Also
     * errors raised by APIs that do not return enough error information
     * may be converted to this error.
     *
     * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
     * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
     * by an implementation as being equivalent to INTERNAL.
     *
     * When possible, a more specific error should be provided.
     *
     * HTTP Mapping: 520 Unknown Error
     */
    ErrorType["Unknown"] = "UNKNOWN";
})(ErrorType || (exports.ErrorType = ErrorType = {}));
var ExerciseDurationUnit;
(function (ExerciseDurationUnit) {
    ExerciseDurationUnit["Hours"] = "HOURS";
    ExerciseDurationUnit["Minutes"] = "MINUTES";
})(ExerciseDurationUnit || (exports.ExerciseDurationUnit = ExerciseDurationUnit = {}));
var ExerciseType;
(function (ExerciseType) {
    ExerciseType["BodyweightExercises"] = "BODYWEIGHT_EXERCISES";
    ExerciseType["Cycling"] = "CYCLING";
    ExerciseType["Dancing"] = "DANCING";
    ExerciseType["Pilates"] = "PILATES";
    ExerciseType["ResistanceBandExercises"] = "RESISTANCE_BAND_EXERCISES";
    ExerciseType["StretchingRoutines"] = "STRETCHING_ROUTINES";
    ExerciseType["Swimming"] = "SWIMMING";
    ExerciseType["Walking"] = "WALKING";
    ExerciseType["WaterAerobics"] = "WATER_AEROBICS";
    ExerciseType["Weightlifting"] = "WEIGHTLIFTING";
    ExerciseType["Yoga"] = "YOGA";
})(ExerciseType || (exports.ExerciseType = ExerciseType = {}));
var FieldStandard;
(function (FieldStandard) {
    FieldStandard["Cpt"] = "CPT";
    FieldStandard["Loinc"] = "LOINC";
})(FieldStandard || (exports.FieldStandard = FieldStandard = {}));
var FileType;
(function (FileType) {
    FileType["Csv"] = "CSV";
    FileType["Pdf"] = "PDF";
    FileType["Txt"] = "TXT";
})(FileType || (exports.FileType = FileType = {}));
var FlowrseIntegrationSortDirection;
(function (FlowrseIntegrationSortDirection) {
    FlowrseIntegrationSortDirection["Asc"] = "ASC";
    FlowrseIntegrationSortDirection["Desc"] = "DESC";
})(FlowrseIntegrationSortDirection || (exports.FlowrseIntegrationSortDirection = FlowrseIntegrationSortDirection = {}));
var Gender;
(function (Gender) {
    Gender["Female"] = "FEMALE";
    Gender["Male"] = "MALE";
})(Gender || (exports.Gender = Gender = {}));
var GuidedCareActivityType;
(function (GuidedCareActivityType) {
    GuidedCareActivityType["FacilityVisit"] = "FACILITY_VISIT";
    GuidedCareActivityType["HomeVisit"] = "HOME_VISIT";
    GuidedCareActivityType["Lab"] = "LAB";
    GuidedCareActivityType["MedicalForm"] = "MEDICAL_FORM";
    GuidedCareActivityType["MedicalMessageGeneral"] = "MEDICAL_MESSAGE_GENERAL";
    GuidedCareActivityType["MedicalMessageInstruction"] = "MEDICAL_MESSAGE_INSTRUCTION";
    GuidedCareActivityType["MedicalMessageMedical"] = "MEDICAL_MESSAGE_MEDICAL";
    GuidedCareActivityType["MedicalMessageMonitoring"] = "MEDICAL_MESSAGE_MONITORING";
    GuidedCareActivityType["MedicalMessageReminder"] = "MEDICAL_MESSAGE_REMINDER";
    GuidedCareActivityType["OnlineVisit"] = "ONLINE_VISIT";
    GuidedCareActivityType["Rad"] = "RAD";
})(GuidedCareActivityType || (exports.GuidedCareActivityType = GuidedCareActivityType = {}));
var GuidedCareCareJourneyItemStatus;
(function (GuidedCareCareJourneyItemStatus) {
    GuidedCareCareJourneyItemStatus["Accepted"] = "ACCEPTED";
    GuidedCareCareJourneyItemStatus["Cancelled"] = "CANCELLED";
    GuidedCareCareJourneyItemStatus["Completed"] = "COMPLETED";
    GuidedCareCareJourneyItemStatus["Failed"] = "FAILED";
    GuidedCareCareJourneyItemStatus["InProgress"] = "IN_PROGRESS";
    GuidedCareCareJourneyItemStatus["Missed"] = "MISSED";
    GuidedCareCareJourneyItemStatus["OnHold"] = "ON_HOLD";
    GuidedCareCareJourneyItemStatus["Pending"] = "PENDING";
    GuidedCareCareJourneyItemStatus["Received"] = "RECEIVED";
    GuidedCareCareJourneyItemStatus["Rejected"] = "REJECTED";
    GuidedCareCareJourneyItemStatus["Rescheduled"] = "RESCHEDULED";
})(GuidedCareCareJourneyItemStatus || (exports.GuidedCareCareJourneyItemStatus = GuidedCareCareJourneyItemStatus = {}));
var GuidedCareHealthProgramSortingField;
(function (GuidedCareHealthProgramSortingField) {
    GuidedCareHealthProgramSortingField["Created"] = "CREATED";
    GuidedCareHealthProgramSortingField["Id"] = "ID";
    GuidedCareHealthProgramSortingField["IsActive"] = "IS_ACTIVE";
    GuidedCareHealthProgramSortingField["Modified"] = "MODIFIED";
    GuidedCareHealthProgramSortingField["Price"] = "PRICE";
    GuidedCareHealthProgramSortingField["ProgramName"] = "PROGRAM_NAME";
    GuidedCareHealthProgramSortingField["TemplateName"] = "TEMPLATE_NAME";
})(GuidedCareHealthProgramSortingField || (exports.GuidedCareHealthProgramSortingField = GuidedCareHealthProgramSortingField = {}));
var GuidedCareJourneyItemPriority;
(function (GuidedCareJourneyItemPriority) {
    /** The request should be actioned as soon as possible - higher priority than urgent */
    GuidedCareJourneyItemPriority["Asap"] = "ASAP";
    /** The request has normal priority */
    GuidedCareJourneyItemPriority["Routine"] = "ROUTINE";
    /** The request should be actioned immediately - highest possible priority. E.g. an emergency */
    GuidedCareJourneyItemPriority["Stat"] = "STAT";
    /** The request should be actioned promptly - higher priority than routine */
    GuidedCareJourneyItemPriority["Urgent"] = "URGENT";
})(GuidedCareJourneyItemPriority || (exports.GuidedCareJourneyItemPriority = GuidedCareJourneyItemPriority = {}));
var GuidedCareJourneyItemSource;
(function (GuidedCareJourneyItemSource) {
    GuidedCareJourneyItemSource["Personalized"] = "PERSONALIZED";
    GuidedCareJourneyItemSource["Program"] = "PROGRAM";
})(GuidedCareJourneyItemSource || (exports.GuidedCareJourneyItemSource = GuidedCareJourneyItemSource = {}));
var GuidedCareJourneyItemStatus;
(function (GuidedCareJourneyItemStatus) {
    GuidedCareJourneyItemStatus["Accepted"] = "ACCEPTED";
    GuidedCareJourneyItemStatus["Cancelled"] = "CANCELLED";
    GuidedCareJourneyItemStatus["Completed"] = "COMPLETED";
    GuidedCareJourneyItemStatus["Failed"] = "FAILED";
    GuidedCareJourneyItemStatus["InProgress"] = "IN_PROGRESS";
    GuidedCareJourneyItemStatus["Missed"] = "MISSED";
    GuidedCareJourneyItemStatus["OnHold"] = "ON_HOLD";
    GuidedCareJourneyItemStatus["Pending"] = "PENDING";
    GuidedCareJourneyItemStatus["Received"] = "RECEIVED";
    GuidedCareJourneyItemStatus["Rejected"] = "REJECTED";
    GuidedCareJourneyItemStatus["Rescheduled"] = "RESCHEDULED";
})(GuidedCareJourneyItemStatus || (exports.GuidedCareJourneyItemStatus = GuidedCareJourneyItemStatus = {}));
var GuidedCareJourneySortField;
(function (GuidedCareJourneySortField) {
    /** Sort by adherence. */
    GuidedCareJourneySortField["Adherence"] = "ADHERENCE";
    /** Sort by creation date. */
    GuidedCareJourneySortField["CreationDate"] = "CREATION_DATE";
    /** Sort by gaps. */
    GuidedCareJourneySortField["Gaps"] = "GAPS";
})(GuidedCareJourneySortField || (exports.GuidedCareJourneySortField = GuidedCareJourneySortField = {}));
var GuidedCareJourneyStatus;
(function (GuidedCareJourneyStatus) {
    GuidedCareJourneyStatus["Cancelled"] = "CANCELLED";
    GuidedCareJourneyStatus["Completed"] = "COMPLETED";
    GuidedCareJourneyStatus["InProgress"] = "IN_PROGRESS";
})(GuidedCareJourneyStatus || (exports.GuidedCareJourneyStatus = GuidedCareJourneyStatus = {}));
var GuidedCareTaskSortingField;
(function (GuidedCareTaskSortingField) {
    GuidedCareTaskSortingField["DueDate"] = "DUE_DATE";
    GuidedCareTaskSortingField["NextReminderDate"] = "NEXT_REMINDER_DATE";
})(GuidedCareTaskSortingField || (exports.GuidedCareTaskSortingField = GuidedCareTaskSortingField = {}));
var GuidedCareType;
(function (GuidedCareType) {
    GuidedCareType["Acute"] = "ACUTE";
    GuidedCareType["Chronic"] = "CHRONIC";
    GuidedCareType["PostOperative"] = "POST_OPERATIVE";
    GuidedCareType["PreOperative"] = "PRE_OPERATIVE";
    GuidedCareType["Primary"] = "PRIMARY";
    GuidedCareType["SubAcute"] = "SUB_ACUTE";
    GuidedCareType["Supportive"] = "SUPPORTIVE";
})(GuidedCareType || (exports.GuidedCareType = GuidedCareType = {}));
var H_DayOfWeek;
(function (H_DayOfWeek) {
    H_DayOfWeek["Friday"] = "Friday";
    H_DayOfWeek["Monday"] = "Monday";
    H_DayOfWeek["Saturday"] = "Saturday";
    H_DayOfWeek["Sunday"] = "Sunday";
    H_DayOfWeek["Thursday"] = "Thursday";
    H_DayOfWeek["Tuesday"] = "Tuesday";
    H_DayOfWeek["Wednesday"] = "Wednesday";
})(H_DayOfWeek || (exports.H_DayOfWeek = H_DayOfWeek = {}));
var H_EntityErrorCode;
(function (H_EntityErrorCode) {
    H_EntityErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    H_EntityErrorCode["AppointmentOverlap"] = "APPOINTMENT_OVERLAP";
    H_EntityErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    H_EntityErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    H_EntityErrorCode["Invalid"] = "INVALID";
    H_EntityErrorCode["Mismatch"] = "MISMATCH";
    H_EntityErrorCode["NotFound"] = "NOT_FOUND";
    H_EntityErrorCode["Required"] = "REQUIRED";
    H_EntityErrorCode["Unique"] = "UNIQUE";
})(H_EntityErrorCode || (exports.H_EntityErrorCode = H_EntityErrorCode = {}));
var H_Gender;
(function (H_Gender) {
    H_Gender["Female"] = "FEMALE";
    H_Gender["Male"] = "MALE";
})(H_Gender || (exports.H_Gender = H_Gender = {}));
var H_IdTypes;
(function (H_IdTypes) {
    H_IdTypes["NationalCard"] = "NATIONAL_CARD";
    H_IdTypes["Passport"] = "PASSPORT";
    H_IdTypes["ResidentCard"] = "RESIDENT_CARD";
})(H_IdTypes || (exports.H_IdTypes = H_IdTypes = {}));
var H_OrderDirection;
(function (H_OrderDirection) {
    /** Specifies an ascending sort order. */
    H_OrderDirection["Asc"] = "ASC";
    /** Specifies a descending sort order. */
    H_OrderDirection["Desc"] = "DESC";
})(H_OrderDirection || (exports.H_OrderDirection = H_OrderDirection = {}));
var H_RelationTypes;
(function (H_RelationTypes) {
    H_RelationTypes["Aunt"] = "AUNT";
    H_RelationTypes["Brother"] = "BROTHER";
    H_RelationTypes["Cousin"] = "COUSIN";
    H_RelationTypes["Daughter"] = "DAUGHTER";
    H_RelationTypes["Father"] = "FATHER";
    H_RelationTypes["Friend"] = "FRIEND";
    H_RelationTypes["Granddaughter"] = "GRANDDAUGHTER";
    H_RelationTypes["Grandfather"] = "GRANDFATHER";
    H_RelationTypes["Grandmother"] = "GRANDMOTHER";
    H_RelationTypes["Grandson"] = "GRANDSON";
    H_RelationTypes["Husband"] = "HUSBAND";
    H_RelationTypes["Mother"] = "MOTHER";
    H_RelationTypes["Nephew"] = "NEPHEW";
    H_RelationTypes["Niece"] = "NIECE";
    H_RelationTypes["Other"] = "OTHER";
    H_RelationTypes["Sister"] = "SISTER";
    H_RelationTypes["Son"] = "SON";
    H_RelationTypes["Uncle"] = "UNCLE";
    H_RelationTypes["Wife"] = "WIFE";
})(H_RelationTypes || (exports.H_RelationTypes = H_RelationTypes = {}));
var H_ReportingPeriod;
(function (H_ReportingPeriod) {
    H_ReportingPeriod["Last_7Days"] = "LAST_7_DAYS";
    H_ReportingPeriod["ThisMonth"] = "THIS_MONTH";
    H_ReportingPeriod["Today"] = "TODAY";
})(H_ReportingPeriod || (exports.H_ReportingPeriod = H_ReportingPeriod = {}));
var HealthChannelSubscriptionType;
(function (HealthChannelSubscriptionType) {
    HealthChannelSubscriptionType["Free"] = "FREE";
    HealthChannelSubscriptionType["Paid"] = "PAID";
})(HealthChannelSubscriptionType || (exports.HealthChannelSubscriptionType = HealthChannelSubscriptionType = {}));
var HealthConditionDiagnosisSortingField;
(function (HealthConditionDiagnosisSortingField) {
    HealthConditionDiagnosisSortingField["Code"] = "CODE";
    HealthConditionDiagnosisSortingField["CreatedDate"] = "CREATED_DATE";
})(HealthConditionDiagnosisSortingField || (exports.HealthConditionDiagnosisSortingField = HealthConditionDiagnosisSortingField = {}));
var HealthConditionSortingField;
(function (HealthConditionSortingField) {
    HealthConditionSortingField["ArabicDisplay"] = "ARABIC_DISPLAY";
    HealthConditionSortingField["Code"] = "CODE";
    HealthConditionSortingField["CreatedDate"] = "CREATED_DATE";
    HealthConditionSortingField["Disease"] = "DISEASE";
    HealthConditionSortingField["Display"] = "DISPLAY";
    HealthConditionSortingField["IsActive"] = "IS_ACTIVE";
    HealthConditionSortingField["Type"] = "TYPE";
})(HealthConditionSortingField || (exports.HealthConditionSortingField = HealthConditionSortingField = {}));
var HealthConditionType;
(function (HealthConditionType) {
    HealthConditionType["Acute"] = "ACUTE";
    HealthConditionType["Chronic"] = "CHRONIC";
    HealthConditionType["SubAcute"] = "SUB_ACUTE";
})(HealthConditionType || (exports.HealthConditionType = HealthConditionType = {}));
var HealthHistoryType;
(function (HealthHistoryType) {
    HealthHistoryType["Allergy"] = "ALLERGY";
    HealthHistoryType["Diagnose"] = "DIAGNOSE";
    HealthHistoryType["Family"] = "FAMILY";
    HealthHistoryType["Medical"] = "MEDICAL";
    HealthHistoryType["Surgery"] = "SURGERY";
})(HealthHistoryType || (exports.HealthHistoryType = HealthHistoryType = {}));
var HealthParameterSortingField;
(function (HealthParameterSortingField) {
    HealthParameterSortingField["ArabicDisplay"] = "ARABIC_DISPLAY";
    HealthParameterSortingField["Code"] = "CODE";
    HealthParameterSortingField["CreatedDate"] = "CREATED_DATE";
    HealthParameterSortingField["Display"] = "DISPLAY";
    HealthParameterSortingField["StandardCode"] = "STANDARD_CODE";
})(HealthParameterSortingField || (exports.HealthParameterSortingField = HealthParameterSortingField = {}));
var HealthParameterType;
(function (HealthParameterType) {
    HealthParameterType["Vaccination"] = "VACCINATION";
})(HealthParameterType || (exports.HealthParameterType = HealthParameterType = {}));
var HealthProgramBenefitOptionCode;
(function (HealthProgramBenefitOptionCode) {
    HealthProgramBenefitOptionCode["MaxOfFreeAudioVideoCalls"] = "MAX_OF_FREE_AUDIO_VIDEO_CALLS";
    HealthProgramBenefitOptionCode["NumberOfProactiveAudioVideoCalls"] = "NUMBER_OF_PROACTIVE_AUDIO_VIDEO_CALLS";
    HealthProgramBenefitOptionCode["NumberOfReactiveAudioVideoCalls"] = "NUMBER_OF_REACTIVE_AUDIO_VIDEO_CALLS";
    HealthProgramBenefitOptionCode["PatientSharePercentage"] = "PATIENT_SHARE_PERCENTAGE";
    HealthProgramBenefitOptionCode["ProactiveAtHomePlannedHealthServices"] = "PROACTIVE_AT_HOME_PLANNED_HEALTH_SERVICES";
    HealthProgramBenefitOptionCode["ProactiveInPersonPlannedHealthServices"] = "PROACTIVE_IN_PERSON_PLANNED_HEALTH_SERVICES";
    HealthProgramBenefitOptionCode["ReactiveAtHomeHealthServices"] = "REACTIVE_AT_HOME_HEALTH_SERVICES";
    HealthProgramBenefitOptionCode["ReactiveInPersonHealthServices"] = "REACTIVE_IN_PERSON_HEALTH_SERVICES";
})(HealthProgramBenefitOptionCode || (exports.HealthProgramBenefitOptionCode = HealthProgramBenefitOptionCode = {}));
var HealthProgramMemberListSortingField;
(function (HealthProgramMemberListSortingField) {
    HealthProgramMemberListSortingField["Created"] = "CREATED";
    HealthProgramMemberListSortingField["IsActive"] = "IS_ACTIVE";
    HealthProgramMemberListSortingField["Name"] = "NAME";
    HealthProgramMemberListSortingField["Updated"] = "UPDATED";
})(HealthProgramMemberListSortingField || (exports.HealthProgramMemberListSortingField = HealthProgramMemberListSortingField = {}));
var HealthProgramMemberSortingField;
(function (HealthProgramMemberSortingField) {
    HealthProgramMemberSortingField["Created"] = "CREATED";
    HealthProgramMemberSortingField["InsuranceId"] = "INSURANCE_ID";
    HealthProgramMemberSortingField["Name"] = "NAME";
    HealthProgramMemberSortingField["NationalId"] = "NATIONAL_ID";
    HealthProgramMemberSortingField["Updated"] = "UPDATED";
})(HealthProgramMemberSortingField || (exports.HealthProgramMemberSortingField = HealthProgramMemberSortingField = {}));
var HealthProgramMembershipRequestSortingField;
(function (HealthProgramMembershipRequestSortingField) {
    HealthProgramMembershipRequestSortingField["Created"] = "CREATED";
    HealthProgramMembershipRequestSortingField["InsuranceId"] = "INSURANCE_ID";
    HealthProgramMembershipRequestSortingField["Name"] = "NAME";
    HealthProgramMembershipRequestSortingField["NationalId"] = "NATIONAL_ID";
    HealthProgramMembershipRequestSortingField["Updated"] = "UPDATED";
})(HealthProgramMembershipRequestSortingField || (exports.HealthProgramMembershipRequestSortingField = HealthProgramMembershipRequestSortingField = {}));
var HealthProgramNetworkSortingField;
(function (HealthProgramNetworkSortingField) {
    HealthProgramNetworkSortingField["Code"] = "CODE";
    HealthProgramNetworkSortingField["Created"] = "CREATED";
    HealthProgramNetworkSortingField["IsActive"] = "IS_ACTIVE";
    HealthProgramNetworkSortingField["Name"] = "NAME";
    HealthProgramNetworkSortingField["Updated"] = "UPDATED";
})(HealthProgramNetworkSortingField || (exports.HealthProgramNetworkSortingField = HealthProgramNetworkSortingField = {}));
var HealthProgramOptionType;
(function (HealthProgramOptionType) {
    HealthProgramOptionType["Include"] = "INCLUDE";
    HealthProgramOptionType["Limitation"] = "LIMITATION";
})(HealthProgramOptionType || (exports.HealthProgramOptionType = HealthProgramOptionType = {}));
var HealthProgramSortingField;
(function (HealthProgramSortingField) {
    HealthProgramSortingField["Code"] = "CODE";
    HealthProgramSortingField["Created"] = "CREATED";
    HealthProgramSortingField["IsActive"] = "IS_ACTIVE";
    HealthProgramSortingField["Name"] = "NAME";
    HealthProgramSortingField["Type"] = "TYPE";
    HealthProgramSortingField["Updated"] = "UPDATED";
})(HealthProgramSortingField || (exports.HealthProgramSortingField = HealthProgramSortingField = {}));
var HealthProgramSubscriptionPeriod;
(function (HealthProgramSubscriptionPeriod) {
    HealthProgramSubscriptionPeriod["Monthly"] = "MONTHLY";
    HealthProgramSubscriptionPeriod["Yearly"] = "YEARLY";
})(HealthProgramSubscriptionPeriod || (exports.HealthProgramSubscriptionPeriod = HealthProgramSubscriptionPeriod = {}));
var HealthProgramTemplateSortingField;
(function (HealthProgramTemplateSortingField) {
    HealthProgramTemplateSortingField["Created"] = "CREATED";
    HealthProgramTemplateSortingField["GuidedCareType"] = "GUIDED_CARE_TYPE";
    HealthProgramTemplateSortingField["Id"] = "ID";
    HealthProgramTemplateSortingField["IsDraft"] = "IS_DRAFT";
    HealthProgramTemplateSortingField["Modified"] = "MODIFIED";
    HealthProgramTemplateSortingField["Name"] = "NAME";
})(HealthProgramTemplateSortingField || (exports.HealthProgramTemplateSortingField = HealthProgramTemplateSortingField = {}));
var HealthProgramType;
(function (HealthProgramType) {
    HealthProgramType["ImmediateCare"] = "IMMEDIATE_CARE";
    HealthProgramType["ScheduledCare"] = "SCHEDULED_CARE";
})(HealthProgramType || (exports.HealthProgramType = HealthProgramType = {}));
var HealthSymptomErrorCode;
(function (HealthSymptomErrorCode) {
    HealthSymptomErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    HealthSymptomErrorCode["Invalid"] = "INVALID";
    HealthSymptomErrorCode["NotFound"] = "NOT_FOUND";
    HealthSymptomErrorCode["Required"] = "REQUIRED";
    HealthSymptomErrorCode["Unique"] = "UNIQUE";
})(HealthSymptomErrorCode || (exports.HealthSymptomErrorCode = HealthSymptomErrorCode = {}));
var HealthSymptomOrderField;
(function (HealthSymptomOrderField) {
    HealthSymptomOrderField["Created"] = "CREATED";
    HealthSymptomOrderField["Name"] = "NAME";
})(HealthSymptomOrderField || (exports.HealthSymptomOrderField = HealthSymptomOrderField = {}));
var InsuranceSortField;
(function (InsuranceSortField) {
    /** Sort by creation date. */
    InsuranceSortField["CreationDate"] = "CREATION_DATE";
    /** Sort by name. */
    InsuranceSortField["Name"] = "NAME";
})(InsuranceSortField || (exports.InsuranceSortField = InsuranceSortField = {}));
var InterventionActivationType;
(function (InterventionActivationType) {
    InterventionActivationType["Merge"] = "MERGE";
    InterventionActivationType["Replace"] = "REPLACE";
})(InterventionActivationType || (exports.InterventionActivationType = InterventionActivationType = {}));
var ItemType;
(function (ItemType) {
    ItemType["Consumable"] = "CONSUMABLE";
    ItemType["Dental"] = "DENTAL";
    ItemType["Drg"] = "DRG";
    ItemType["GenericDrug"] = "GENERIC_DRUG";
    ItemType["Procedure"] = "PROCEDURE";
    ItemType["Service"] = "SERVICE";
    ItemType["TradeDrug"] = "TRADE_DRUG";
})(ItemType || (exports.ItemType = ItemType = {}));
var JourneyItemClassification;
(function (JourneyItemClassification) {
    JourneyItemClassification["Essential"] = "ESSENTIAL";
    JourneyItemClassification["Supportive"] = "SUPPORTIVE";
})(JourneyItemClassification || (exports.JourneyItemClassification = JourneyItemClassification = {}));
var KeywordSearch;
(function (KeywordSearch) {
    KeywordSearch["All"] = "ALL";
    KeywordSearch["Code"] = "CODE";
    KeywordSearch["Display"] = "DISPLAY";
})(KeywordSearch || (exports.KeywordSearch = KeywordSearch = {}));
var LabTemplateSortingField;
(function (LabTemplateSortingField) {
    LabTemplateSortingField["ArabicDisplay"] = "ARABIC_DISPLAY";
    LabTemplateSortingField["Code"] = "CODE";
    LabTemplateSortingField["CreatedDate"] = "CREATED_DATE";
    LabTemplateSortingField["Display"] = "DISPLAY";
    LabTemplateSortingField["IsActive"] = "IS_ACTIVE";
})(LabTemplateSortingField || (exports.LabTemplateSortingField = LabTemplateSortingField = {}));
var LanguageCodeEnum;
(function (LanguageCodeEnum) {
    LanguageCodeEnum["Ar"] = "AR";
    LanguageCodeEnum["Az"] = "AZ";
    LanguageCodeEnum["Bg"] = "BG";
    LanguageCodeEnum["Bn"] = "BN";
    LanguageCodeEnum["Ca"] = "CA";
    LanguageCodeEnum["Cs"] = "CS";
    LanguageCodeEnum["Da"] = "DA";
    LanguageCodeEnum["De"] = "DE";
    LanguageCodeEnum["El"] = "EL";
    LanguageCodeEnum["En"] = "EN";
    LanguageCodeEnum["Es"] = "ES";
    LanguageCodeEnum["EsCo"] = "ES_CO";
    LanguageCodeEnum["Et"] = "ET";
    LanguageCodeEnum["Fa"] = "FA";
    LanguageCodeEnum["Fi"] = "FI";
    LanguageCodeEnum["Fr"] = "FR";
    LanguageCodeEnum["Hi"] = "HI";
    LanguageCodeEnum["Hu"] = "HU";
    LanguageCodeEnum["Hy"] = "HY";
    LanguageCodeEnum["Id"] = "ID";
    LanguageCodeEnum["Is"] = "IS";
    LanguageCodeEnum["It"] = "IT";
    LanguageCodeEnum["Ja"] = "JA";
    LanguageCodeEnum["Ko"] = "KO";
    LanguageCodeEnum["Lt"] = "LT";
    LanguageCodeEnum["Mn"] = "MN";
    LanguageCodeEnum["Nb"] = "NB";
    LanguageCodeEnum["Nl"] = "NL";
    LanguageCodeEnum["Pl"] = "PL";
    LanguageCodeEnum["Pt"] = "PT";
    LanguageCodeEnum["PtBr"] = "PT_BR";
    LanguageCodeEnum["Ro"] = "RO";
    LanguageCodeEnum["Ru"] = "RU";
    LanguageCodeEnum["Sk"] = "SK";
    LanguageCodeEnum["Sl"] = "SL";
    LanguageCodeEnum["Sq"] = "SQ";
    LanguageCodeEnum["Sr"] = "SR";
    LanguageCodeEnum["Sv"] = "SV";
    LanguageCodeEnum["Sw"] = "SW";
    LanguageCodeEnum["Ta"] = "TA";
    LanguageCodeEnum["Th"] = "TH";
    LanguageCodeEnum["Tr"] = "TR";
    LanguageCodeEnum["Uk"] = "UK";
    LanguageCodeEnum["Vi"] = "VI";
    LanguageCodeEnum["ZhHans"] = "ZH_HANS";
    LanguageCodeEnum["ZhHant"] = "ZH_HANT";
})(LanguageCodeEnum || (exports.LanguageCodeEnum = LanguageCodeEnum = {}));
var MaritalStatusesEnum;
(function (MaritalStatusesEnum) {
    MaritalStatusesEnum["Divorced"] = "DIVORCED";
    MaritalStatusesEnum["Married"] = "MARRIED";
    MaritalStatusesEnum["Single"] = "SINGLE";
    MaritalStatusesEnum["Widowed"] = "WIDOWED";
})(MaritalStatusesEnum || (exports.MaritalStatusesEnum = MaritalStatusesEnum = {}));
var MarketplaceApprovalStatus;
(function (MarketplaceApprovalStatus) {
    MarketplaceApprovalStatus["Approved"] = "APPROVED";
    MarketplaceApprovalStatus["Draft"] = "DRAFT";
    MarketplaceApprovalStatus["PendingApproval"] = "PENDING_APPROVAL";
    MarketplaceApprovalStatus["Rejected"] = "REJECTED";
})(MarketplaceApprovalStatus || (exports.MarketplaceApprovalStatus = MarketplaceApprovalStatus = {}));
var MarketplaceContentType;
(function (MarketplaceContentType) {
    MarketplaceContentType["Html"] = "HTML";
    MarketplaceContentType["Md"] = "MD";
    MarketplaceContentType["Text"] = "TEXT";
})(MarketplaceContentType || (exports.MarketplaceContentType = MarketplaceContentType = {}));
var MarketplaceDefaultSortingField;
(function (MarketplaceDefaultSortingField) {
    MarketplaceDefaultSortingField["Created"] = "CREATED";
    MarketplaceDefaultSortingField["Name"] = "NAME";
    MarketplaceDefaultSortingField["NameAr"] = "NAME_AR";
})(MarketplaceDefaultSortingField || (exports.MarketplaceDefaultSortingField = MarketplaceDefaultSortingField = {}));
var MarketplaceDiscountSortingField;
(function (MarketplaceDiscountSortingField) {
    MarketplaceDiscountSortingField["Amount"] = "AMOUNT";
    MarketplaceDiscountSortingField["Created"] = "CREATED";
    MarketplaceDiscountSortingField["EndDate"] = "END_DATE";
    MarketplaceDiscountSortingField["Name"] = "NAME";
    MarketplaceDiscountSortingField["Percentage"] = "PERCENTAGE";
    MarketplaceDiscountSortingField["Published"] = "PUBLISHED";
    MarketplaceDiscountSortingField["StartDate"] = "START_DATE";
    MarketplaceDiscountSortingField["Type"] = "TYPE";
})(MarketplaceDiscountSortingField || (exports.MarketplaceDiscountSortingField = MarketplaceDiscountSortingField = {}));
var MarketplaceDiscountType;
(function (MarketplaceDiscountType) {
    MarketplaceDiscountType["Fixed"] = "FIXED";
    MarketplaceDiscountType["Percentage"] = "PERCENTAGE";
})(MarketplaceDiscountType || (exports.MarketplaceDiscountType = MarketplaceDiscountType = {}));
var MarketplaceGender;
(function (MarketplaceGender) {
    MarketplaceGender["All"] = "ALL";
    MarketplaceGender["Female"] = "FEMALE";
    MarketplaceGender["Male"] = "MALE";
})(MarketplaceGender || (exports.MarketplaceGender = MarketplaceGender = {}));
var MarketplaceHealthPackageSortingField;
(function (MarketplaceHealthPackageSortingField) {
    MarketplaceHealthPackageSortingField["Created"] = "CREATED";
    MarketplaceHealthPackageSortingField["Name"] = "NAME";
    MarketplaceHealthPackageSortingField["NameAr"] = "NAME_AR";
    MarketplaceHealthPackageSortingField["Price"] = "PRICE";
})(MarketplaceHealthPackageSortingField || (exports.MarketplaceHealthPackageSortingField = MarketplaceHealthPackageSortingField = {}));
var MarketplaceOrderSortingField;
(function (MarketplaceOrderSortingField) {
    MarketplaceOrderSortingField["Created"] = "CREATED";
    MarketplaceOrderSortingField["PaymentMethod"] = "PAYMENT_METHOD";
    MarketplaceOrderSortingField["Status"] = "STATUS";
    MarketplaceOrderSortingField["Type"] = "TYPE";
})(MarketplaceOrderSortingField || (exports.MarketplaceOrderSortingField = MarketplaceOrderSortingField = {}));
var MarketplaceOrderStatus;
(function (MarketplaceOrderStatus) {
    MarketplaceOrderStatus["Accepted"] = "ACCEPTED";
    MarketplaceOrderStatus["Cancelled"] = "CANCELLED";
    MarketplaceOrderStatus["Consumed"] = "CONSUMED";
    MarketplaceOrderStatus["Delivered"] = "DELIVERED";
    MarketplaceOrderStatus["New"] = "NEW";
    MarketplaceOrderStatus["OutForDelivery"] = "OUT_FOR_DELIVERY";
    MarketplaceOrderStatus["Rejected"] = "REJECTED";
})(MarketplaceOrderStatus || (exports.MarketplaceOrderStatus = MarketplaceOrderStatus = {}));
var MarketplaceOrderType;
(function (MarketplaceOrderType) {
    MarketplaceOrderType["HealthPackage"] = "HEALTH_PACKAGE";
    MarketplaceOrderType["Product"] = "PRODUCT";
})(MarketplaceOrderType || (exports.MarketplaceOrderType = MarketplaceOrderType = {}));
var MarketplacePaymentMethod;
(function (MarketplacePaymentMethod) {
    MarketplacePaymentMethod["CreditWhenArrive"] = "CREDIT_WHEN_ARRIVE";
    MarketplacePaymentMethod["PayNow"] = "PAY_NOW";
})(MarketplacePaymentMethod || (exports.MarketplacePaymentMethod = MarketplacePaymentMethod = {}));
var MarketplaceProductSortingField;
(function (MarketplaceProductSortingField) {
    MarketplaceProductSortingField["Created"] = "CREATED";
    MarketplaceProductSortingField["Name"] = "NAME";
    MarketplaceProductSortingField["NameAr"] = "NAME_AR";
    MarketplaceProductSortingField["Price"] = "PRICE";
    MarketplaceProductSortingField["Published"] = "PUBLISHED";
})(MarketplaceProductSortingField || (exports.MarketplaceProductSortingField = MarketplaceProductSortingField = {}));
var MarketplacePromotionSortingField;
(function (MarketplacePromotionSortingField) {
    MarketplacePromotionSortingField["Created"] = "CREATED";
    MarketplacePromotionSortingField["EndDate"] = "END_DATE";
    MarketplacePromotionSortingField["Name"] = "NAME";
    MarketplacePromotionSortingField["Published"] = "PUBLISHED";
    MarketplacePromotionSortingField["StartDate"] = "START_DATE";
})(MarketplacePromotionSortingField || (exports.MarketplacePromotionSortingField = MarketplacePromotionSortingField = {}));
var MarketplaceSortDirection;
(function (MarketplaceSortDirection) {
    MarketplaceSortDirection["Asc"] = "ASC";
    MarketplaceSortDirection["Desc"] = "DESC";
})(MarketplaceSortDirection || (exports.MarketplaceSortDirection = MarketplaceSortDirection = {}));
var MedicalFormCategory;
(function (MedicalFormCategory) {
    MedicalFormCategory["Condition"] = "CONDITION";
    MedicalFormCategory["Disease"] = "DISEASE";
    MedicalFormCategory["General"] = "GENERAL";
    MedicalFormCategory["Procedure"] = "PROCEDURE";
})(MedicalFormCategory || (exports.MedicalFormCategory = MedicalFormCategory = {}));
var MedicalFormSortingField;
(function (MedicalFormSortingField) {
    MedicalFormSortingField["Active"] = "ACTIVE";
    MedicalFormSortingField["Category"] = "CATEGORY";
    MedicalFormSortingField["CreatedDate"] = "CREATED_DATE";
    MedicalFormSortingField["Name"] = "NAME";
    MedicalFormSortingField["NameAr"] = "NAME_AR";
    MedicalFormSortingField["Type"] = "TYPE";
    MedicalFormSortingField["UpdatedDate"] = "UPDATED_DATE";
})(MedicalFormSortingField || (exports.MedicalFormSortingField = MedicalFormSortingField = {}));
var MedicalFormType;
(function (MedicalFormType) {
    MedicalFormType["Administrative"] = "ADMINISTRATIVE";
    MedicalFormType["Assessment"] = "ASSESSMENT";
    MedicalFormType["History"] = "HISTORY";
    MedicalFormType["Prem"] = "PREM";
    MedicalFormType["Prom"] = "PROM";
    MedicalFormType["Quizzes"] = "QUIZZES";
})(MedicalFormType || (exports.MedicalFormType = MedicalFormType = {}));
var MedicalMessageCategory;
(function (MedicalMessageCategory) {
    MedicalMessageCategory["General"] = "GENERAL";
    MedicalMessageCategory["Instruction"] = "INSTRUCTION";
    MedicalMessageCategory["Medical"] = "MEDICAL";
    MedicalMessageCategory["Monitoring"] = "MONITORING";
    MedicalMessageCategory["Reminder"] = "REMINDER";
})(MedicalMessageCategory || (exports.MedicalMessageCategory = MedicalMessageCategory = {}));
var MedicalMessageSortField;
(function (MedicalMessageSortField) {
    /** Sort by creation date. */
    MedicalMessageSortField["CreatedDate"] = "CREATED_DATE";
    /** Sort by update date. */
    MedicalMessageSortField["LastModifiedDate"] = "LAST_MODIFIED_DATE";
    /** Sort by name. */
    MedicalMessageSortField["Name"] = "NAME";
})(MedicalMessageSortField || (exports.MedicalMessageSortField = MedicalMessageSortField = {}));
var MedicalMessageType;
(function (MedicalMessageType) {
    MedicalMessageType["Article"] = "ARTICLE";
    MedicalMessageType["Text"] = "TEXT";
    MedicalMessageType["Video"] = "VIDEO";
})(MedicalMessageType || (exports.MedicalMessageType = MedicalMessageType = {}));
var MedicalMessageVariantSortField;
(function (MedicalMessageVariantSortField) {
    /** Sort by creation date. */
    MedicalMessageVariantSortField["CreatedDate"] = "CREATED_DATE";
    MedicalMessageVariantSortField["Id"] = "ID";
    MedicalMessageVariantSortField["IsActive"] = "IS_ACTIVE";
    /** Sort by update date. */
    MedicalMessageVariantSortField["LastModifiedDate"] = "LAST_MODIFIED_DATE";
})(MedicalMessageVariantSortField || (exports.MedicalMessageVariantSortField = MedicalMessageVariantSortField = {}));
var NotificationChannel;
(function (NotificationChannel) {
    NotificationChannel["App"] = "APP";
    NotificationChannel["Email"] = "EMAIL";
    NotificationChannel["Sms"] = "SMS";
})(NotificationChannel || (exports.NotificationChannel = NotificationChannel = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["ActivityTrackerNotification"] = "ACTIVITY_TRACKER_NOTIFICATION";
    NotificationType["BulkFile"] = "BULK_FILE";
    NotificationType["Chat"] = "CHAT";
    NotificationType["ChronicProgramEnrollment"] = "CHRONIC_PROGRAM_ENROLLMENT";
    NotificationType["DoctorAvailabilityStatusChanged"] = "DOCTOR_AVAILABILITY_STATUS_CHANGED";
    NotificationType["ErxCreate"] = "ERX_CREATE";
    NotificationType["ErxDeliveryStatusUpdate"] = "ERX_DELIVERY_STATUS_UPDATE";
    NotificationType["ErxStatusUpdate"] = "ERX_STATUS_UPDATE";
    NotificationType["GuidedCareAppointmentBookingReminder"] = "GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER";
    NotificationType["GuidedCareInterventionNotification"] = "GUIDED_CARE_INTERVENTION_NOTIFICATION";
    NotificationType["GuidedCareTaskNotification"] = "GUIDED_CARE_TASK_NOTIFICATION";
    NotificationType["HealthParameters"] = "HEALTH_PARAMETERS";
    NotificationType["HealthProgramAppointmentReminder"] = "HEALTH_PROGRAM_APPOINTMENT_REMINDER";
    NotificationType["HealthProgramAppointmentStatusChanged"] = "HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED";
    NotificationType["HealthProgramNotification"] = "HEALTH_PROGRAM_NOTIFICATION";
    NotificationType["HealthProgramVisitStatusChanged"] = "HEALTH_PROGRAM_VISIT_STATUS_CHANGED";
    NotificationType["MarketplaceCheckoutCompleteFailure"] = "MARKETPLACE_CHECKOUT_COMPLETE_FAILURE";
    NotificationType["MarketplaceOrderStatusUpdate"] = "MARKETPLACE_ORDER_STATUS_UPDATE";
    NotificationType["MarkAllAsRead"] = "MARK_ALL_AS_READ";
    NotificationType["MarkAsRead"] = "MARK_AS_READ";
    NotificationType["MedicalForm"] = "MEDICAL_FORM";
    NotificationType["MedicalMessage"] = "MEDICAL_MESSAGE";
    NotificationType["NeedActionCodeSystem"] = "NEED_ACTION_CODE_SYSTEM";
    NotificationType["NewNotification"] = "NEW_NOTIFICATION";
    NotificationType["NewTransactionNotification"] = "NEW_TRANSACTION_NOTIFICATION";
    NotificationType["OrderDeleted"] = "ORDER_DELETED";
    NotificationType["OrderStatusUpdate"] = "ORDER_STATUS_UPDATE";
    NotificationType["OrderTimeoutAlert"] = "ORDER_TIMEOUT_ALERT";
    NotificationType["PatientProfileAccessClose"] = "PATIENT_PROFILE_ACCESS_CLOSE";
    NotificationType["PatientProfileAccessRequest"] = "PATIENT_PROFILE_ACCESS_REQUEST";
    NotificationType["PatientProfileAccessResponse"] = "PATIENT_PROFILE_ACCESS_RESPONSE";
    NotificationType["PaymentNotification"] = "PAYMENT_NOTIFICATION";
    NotificationType["PingPong"] = "PING_PONG";
    NotificationType["PrescriptionAuthStatusChanged"] = "PRESCRIPTION_AUTH_STATUS_CHANGED";
    NotificationType["SurveyNotification"] = "SURVEY_NOTIFICATION";
    NotificationType["ValidationRequest"] = "VALIDATION_REQUEST";
    NotificationType["VisitVitalSigns"] = "VISIT_VITAL_SIGNS";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
var OperationStatus;
(function (OperationStatus) {
    OperationStatus["Failed"] = "FAILED";
    OperationStatus["Pending"] = "PENDING";
    OperationStatus["Successful"] = "SUCCESSFUL";
})(OperationStatus || (exports.OperationStatus = OperationStatus = {}));
var OperationType;
(function (OperationType) {
    OperationType["AddToWallet"] = "ADD_TO_WALLET";
    OperationType["DeductFromWallet"] = "DEDUCT_FROM_WALLET";
    OperationType["Refund"] = "REFUND";
    OperationType["RefundFromWallet"] = "REFUND_FROM_WALLET";
})(OperationType || (exports.OperationType = OperationType = {}));
var Operator;
(function (Operator) {
    Operator["Contains"] = "CONTAINS";
    Operator["Count"] = "COUNT";
    Operator["EndWith"] = "END_WITH";
    Operator["Equal"] = "EQUAL";
    Operator["GreaterThan"] = "GREATER_THAN";
    Operator["GreaterThanOrEqual"] = "GREATER_THAN_OR_EQUAL";
    Operator["In"] = "IN";
    Operator["IsEmpty"] = "IS_EMPTY";
    Operator["IsNotEmpty"] = "IS_NOT_EMPTY";
    Operator["LessThan"] = "LESS_THAN";
    Operator["LessThanOrEqual"] = "LESS_THAN_OR_EQUAL";
    Operator["No"] = "NO";
    Operator["NotContains"] = "NOT_CONTAINS";
    Operator["NotEqual"] = "NOT_EQUAL";
    Operator["NotIn"] = "NOT_IN";
    Operator["StartWith"] = "START_WITH";
    Operator["Yes"] = "YES";
})(Operator || (exports.Operator = Operator = {}));
var OptimaPriority;
(function (OptimaPriority) {
    OptimaPriority["High"] = "HIGH";
    OptimaPriority["Low"] = "LOW";
    OptimaPriority["Medium"] = "MEDIUM";
})(OptimaPriority || (exports.OptimaPriority = OptimaPriority = {}));
var OptimaUse;
(function (OptimaUse) {
    OptimaUse["Claim"] = "CLAIM";
    OptimaUse["Preauthorization"] = "PREAUTHORIZATION";
    OptimaUse["Predetermination"] = "PREDETERMINATION";
})(OptimaUse || (exports.OptimaUse = OptimaUse = {}));
var OptimaValidationRequestsSortingField;
(function (OptimaValidationRequestsSortingField) {
    OptimaValidationRequestsSortingField["Created"] = "CREATED";
    OptimaValidationRequestsSortingField["PaymentMethod"] = "PAYMENT_METHOD";
    OptimaValidationRequestsSortingField["Status"] = "STATUS";
    OptimaValidationRequestsSortingField["Type"] = "TYPE";
})(OptimaValidationRequestsSortingField || (exports.OptimaValidationRequestsSortingField = OptimaValidationRequestsSortingField = {}));
var OrderDeliveryStatus;
(function (OrderDeliveryStatus) {
    OrderDeliveryStatus["CarrierAllocated"] = "CARRIER_ALLOCATED";
    OrderDeliveryStatus["DeliveryTimedOut"] = "DELIVERY_TIMED_OUT";
    OrderDeliveryStatus["DriverAcceptedOrder"] = "DRIVER_ACCEPTED_ORDER";
    OrderDeliveryStatus["DriverIsAboutToArrive"] = "DRIVER_IS_ABOUT_TO_ARRIVE";
    OrderDeliveryStatus["DriverPickedUpOrder"] = "DRIVER_PICKED_UP_ORDER";
    OrderDeliveryStatus["Failed"] = "FAILED";
    OrderDeliveryStatus["OrderIsCancelled"] = "ORDER_IS_CANCELLED";
    OrderDeliveryStatus["OrderIsDelivered"] = "ORDER_IS_DELIVERED";
    OrderDeliveryStatus["OrderIsResumed"] = "ORDER_IS_RESUMED";
    OrderDeliveryStatus["OrderIsSuspended"] = "ORDER_IS_SUSPENDED";
    OrderDeliveryStatus["Pending"] = "PENDING";
    OrderDeliveryStatus["PickedByConsumer"] = "PICKED_BY_CONSUMER";
    OrderDeliveryStatus["Returned"] = "RETURNED";
})(OrderDeliveryStatus || (exports.OrderDeliveryStatus = OrderDeliveryStatus = {}));
var OrderDirection;
(function (OrderDirection) {
    /** Specifies an ascending sort order. */
    OrderDirection["Asc"] = "ASC";
    /** Specifies a descending sort order. */
    OrderDirection["Desc"] = "DESC";
})(OrderDirection || (exports.OrderDirection = OrderDirection = {}));
var OrderEventType;
(function (OrderEventType) {
    OrderEventType["Allocated"] = "ALLOCATED";
    OrderEventType["Canceled"] = "CANCELED";
    OrderEventType["CardPaid"] = "CARD_PAID";
    OrderEventType["CarrierAllocated"] = "CARRIER_ALLOCATED";
    OrderEventType["Consumed"] = "CONSUMED";
    OrderEventType["ConsumerCanceled"] = "CONSUMER_CANCELED";
    OrderEventType["ConsumerPicked"] = "CONSUMER_PICKED";
    OrderEventType["Created"] = "CREATED";
    OrderEventType["Delivered"] = "DELIVERED";
    OrderEventType["DeliveredTimedOut"] = "DELIVERED_TIMED_OUT";
    OrderEventType["Dispensed"] = "DISPENSED";
    OrderEventType["DraftAddedProducts"] = "DRAFT_ADDED_PRODUCTS";
    OrderEventType["DraftCreated"] = "DRAFT_CREATED";
    OrderEventType["DraftCustomerAccepted"] = "DRAFT_CUSTOMER_ACCEPTED";
    OrderEventType["DraftRemovedProducts"] = "DRAFT_REMOVED_PRODUCTS";
    OrderEventType["DriverAcceptedOrder"] = "DRIVER_ACCEPTED_ORDER";
    OrderEventType["DriverDelivered"] = "DRIVER_DELIVERED";
    OrderEventType["DriverIsAboutToArrive"] = "DRIVER_IS_ABOUT_TO_ARRIVE";
    OrderEventType["DriverPicked"] = "DRIVER_PICKED";
    OrderEventType["DriverReturned"] = "DRIVER_RETURNED";
    OrderEventType["EmailSent"] = "EMAIL_SENT";
    OrderEventType["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    OrderEventType["ErxHubAuthSubmitTimedOut"] = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT";
    OrderEventType["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    OrderEventType["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    OrderEventType["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    OrderEventType["ErxHubClaimSubmitTimedOut"] = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT";
    OrderEventType["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    OrderEventType["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    OrderEventType["ErxHubRejected"] = "ERX_HUB_REJECTED";
    OrderEventType["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    OrderEventType["Failed"] = "FAILED";
    OrderEventType["FulfillmentCanceled"] = "FULFILLMENT_CANCELED";
    OrderEventType["FulfillmentFulfilledItems"] = "FULFILLMENT_FULFILLED_ITEMS";
    OrderEventType["FulfillmentRestockedItems"] = "FULFILLMENT_RESTOCKED_ITEMS";
    OrderEventType["NoteAdded"] = "NOTE_ADDED";
    OrderEventType["OrderDeliveryFailed"] = "ORDER_DELIVERY_FAILED";
    OrderEventType["OrderDeliveryIsCancelled"] = "ORDER_DELIVERY_IS_CANCELLED";
    OrderEventType["OrderDeliveryIsResumed"] = "ORDER_DELIVERY_IS_RESUMED";
    OrderEventType["OrderDeliveryIsSuspended"] = "ORDER_DELIVERY_IS_SUSPENDED";
    OrderEventType["OrderDeliveryTimedOut"] = "ORDER_DELIVERY_TIMED_OUT";
    OrderEventType["OrderFullyPaid"] = "ORDER_FULLY_PAID";
    OrderEventType["OrderMarkedAsPaid"] = "ORDER_MARKED_AS_PAID";
    OrderEventType["Other"] = "OTHER";
    OrderEventType["OutForDelivery"] = "OUT_FOR_DELIVERY";
    OrderEventType["OutForDeliveryTimedOut"] = "OUT_FOR_DELIVERY_TIMED_OUT";
    OrderEventType["OversoldItems"] = "OVERSOLD_ITEMS";
    OrderEventType["PaymentCaptured"] = "PAYMENT_CAPTURED";
    OrderEventType["PaymentFailed"] = "PAYMENT_FAILED";
    OrderEventType["PaymentOnArrive"] = "PAYMENT_ON_ARRIVE";
    OrderEventType["PaymentPending"] = "PAYMENT_PENDING";
    OrderEventType["PaymentRefunded"] = "PAYMENT_REFUNDED";
    OrderEventType["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    OrderEventType["PaymentTimedOut"] = "PAYMENT_TIMED_OUT";
    OrderEventType["PaymentVoided"] = "PAYMENT_VOIDED";
    OrderEventType["PendingDelivery"] = "PENDING_DELIVERY";
    OrderEventType["PickedByConsumer"] = "PICKED_BY_CONSUMER";
    OrderEventType["Placed"] = "PLACED";
    OrderEventType["PlacedFromDraft"] = "PLACED_FROM_DRAFT";
    OrderEventType["PrescriptionOrderCreated"] = "PRESCRIPTION_ORDER_CREATED";
    OrderEventType["PrescriptionOrderVendorAssigned"] = "PRESCRIPTION_ORDER_VENDOR_ASSIGNED";
    OrderEventType["PrescriptionRefillOrderCreated"] = "PRESCRIPTION_REFILL_ORDER_CREATED";
    OrderEventType["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    OrderEventType["Returned"] = "RETURNED";
    OrderEventType["ReturnedTimedOut"] = "RETURNED_TIMED_OUT";
    OrderEventType["ReturnInProgress"] = "RETURN_IN_PROGRESS";
    OrderEventType["TrackingUpdated"] = "TRACKING_UPDATED";
    OrderEventType["Updated"] = "UPDATED";
    OrderEventType["UpdatedAddress"] = "UPDATED_ADDRESS";
    OrderEventType["VendorAccepted"] = "VENDOR_ACCEPTED";
    OrderEventType["VendorCanceled"] = "VENDOR_CANCELED";
    OrderEventType["VendorDelivered"] = "VENDOR_DELIVERED";
    OrderEventType["VendorFinishedProcessing"] = "VENDOR_FINISHED_PROCESSING";
    OrderEventType["VendorRejected"] = "VENDOR_REJECTED";
    OrderEventType["VendorResponseTimedOut"] = "VENDOR_RESPONSE_TIMED_OUT";
    OrderEventType["VendorReturned"] = "VENDOR_RETURNED";
    OrderEventType["WaitingErxHubAuthApproval"] = "WAITING_ERX_HUB_AUTH_APPROVAL";
    OrderEventType["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
})(OrderEventType || (exports.OrderEventType = OrderEventType = {}));
var OrderPaymentMethod;
(function (OrderPaymentMethod) {
    OrderPaymentMethod["CreditWhenArrive"] = "CREDIT_WHEN_ARRIVE";
    OrderPaymentMethod["PayNow"] = "PAY_NOW";
})(OrderPaymentMethod || (exports.OrderPaymentMethod = OrderPaymentMethod = {}));
var OrderSortField;
(function (OrderSortField) {
    OrderSortField["CreationDate"] = "CREATION_DATE";
    OrderSortField["Customer"] = "CUSTOMER";
    OrderSortField["FulfillmentStatus"] = "FULFILLMENT_STATUS";
    OrderSortField["ModifiedDate"] = "MODIFIED_DATE";
    OrderSortField["Number"] = "NUMBER";
    OrderSortField["StatusAndCreated"] = "STATUS_AND_CREATED";
    OrderSortField["Total"] = "TOTAL";
})(OrderSortField || (exports.OrderSortField = OrderSortField = {}));
var OrderSourcesEnum;
(function (OrderSourcesEnum) {
    OrderSourcesEnum["Chat"] = "CHAT";
    OrderSourcesEnum["Checkout"] = "CHECKOUT";
    OrderSourcesEnum["PrescriptionDispense"] = "PRESCRIPTION_DISPENSE";
    OrderSourcesEnum["PrescriptionRefill"] = "PRESCRIPTION_REFILL";
})(OrderSourcesEnum || (exports.OrderSourcesEnum = OrderSourcesEnum = {}));
/**
 * enum OrderStatus{
 *     NEW,
 *     DELIVERY_ORDER_CREATED,
 *     PENDING,
 *     PICKED_BY_CONSUMER,
 *     RETURNED,
 *     CARRIER_ALLOCATED,
 *     DRIVER_ACCEPTED_ORDER,
 *     DRIVER_PICKED_UP_ORDER,
 *     DRIVER_IS_ABOUT_TO_ARRIVE,
 *     ORDER_IS_DELIVERED,
 *     ORDER_IS_CANCELLED,
 *     ORDER_IS_SUSPENDED,
 *     ORDER_IS_RESUMED,
 *     DELIVERY_TIMED_OUT,
 *     PATIENT_CONFIRMED,
 *     FAILED
 * }
 */
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["AcceptedByProvider"] = "ACCEPTED_BY_PROVIDER";
    OrderStatus["Allocated"] = "ALLOCATED";
    OrderStatus["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    OrderStatus["CanceledByProvider"] = "CANCELED_BY_PROVIDER";
    OrderStatus["Delivered"] = "DELIVERED";
    OrderStatus["Dispensed"] = "DISPENSED";
    OrderStatus["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    OrderStatus["ErxHubAuthSubmitTimedOut"] = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT";
    OrderStatus["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    OrderStatus["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    OrderStatus["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    OrderStatus["ErxHubClaimSubmitTimedOut"] = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT";
    OrderStatus["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    OrderStatus["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    OrderStatus["ErxHubRejected"] = "ERX_HUB_REJECTED";
    OrderStatus["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    OrderStatus["Failed"] = "FAILED";
    OrderStatus["NewRequest"] = "NEW_REQUEST";
    OrderStatus["OutForDelivery"] = "OUT_FOR_DELIVERY";
    OrderStatus["PaymentFailed"] = "PAYMENT_FAILED";
    OrderStatus["PaymentOnArrive"] = "PAYMENT_ON_ARRIVE";
    OrderStatus["PaymentPending"] = "PAYMENT_PENDING";
    OrderStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    OrderStatus["PaymentTimedOut"] = "PAYMENT_TIMED_OUT";
    OrderStatus["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    OrderStatus["RejectedByProvider"] = "REJECTED_BY_PROVIDER";
    OrderStatus["Returned"] = "RETURNED";
    OrderStatus["WaitingErxHubApproval"] = "WAITING_ERX_HUB_APPROVAL";
    OrderStatus["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
    OrderStatus["WaitingProviderSelection"] = "WAITING_PROVIDER_SELECTION";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
var OrderStatusEnum;
(function (OrderStatusEnum) {
    OrderStatusEnum["AcceptedByProvider"] = "ACCEPTED_BY_PROVIDER";
    OrderStatusEnum["Allocated"] = "ALLOCATED";
    OrderStatusEnum["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    OrderStatusEnum["CanceledByProvider"] = "CANCELED_BY_PROVIDER";
    OrderStatusEnum["Consumed"] = "CONSUMED";
    OrderStatusEnum["ConsumedTimedOut"] = "CONSUMED_TIMED_OUT";
    OrderStatusEnum["Delivered"] = "DELIVERED";
    OrderStatusEnum["DeliveredTimedOut"] = "DELIVERED_TIMED_OUT";
    OrderStatusEnum["Dispensed"] = "DISPENSED";
    OrderStatusEnum["Draft"] = "DRAFT";
    OrderStatusEnum["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    OrderStatusEnum["ErxHubAuthSubmitTimedOut"] = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT";
    OrderStatusEnum["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    OrderStatusEnum["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    OrderStatusEnum["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    OrderStatusEnum["ErxHubClaimSubmitTimedOut"] = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT";
    OrderStatusEnum["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    OrderStatusEnum["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    OrderStatusEnum["ErxHubRejected"] = "ERX_HUB_REJECTED";
    OrderStatusEnum["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    OrderStatusEnum["Failed"] = "FAILED";
    OrderStatusEnum["Fulfilled"] = "FULFILLED";
    OrderStatusEnum["InProgress"] = "IN_PROGRESS";
    OrderStatusEnum["NewRequest"] = "NEW_REQUEST";
    OrderStatusEnum["OutForDelivery"] = "OUT_FOR_DELIVERY";
    OrderStatusEnum["OutForDeliveryTimedOut"] = "OUT_FOR_DELIVERY_TIMED_OUT";
    OrderStatusEnum["PartiallyFulfilled"] = "PARTIALLY_FULFILLED";
    OrderStatusEnum["PaymentFailed"] = "PAYMENT_FAILED";
    OrderStatusEnum["PaymentOnArrive"] = "PAYMENT_ON_ARRIVE";
    OrderStatusEnum["PaymentPending"] = "PAYMENT_PENDING";
    OrderStatusEnum["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    OrderStatusEnum["PaymentTimedOut"] = "PAYMENT_TIMED_OUT";
    OrderStatusEnum["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    OrderStatusEnum["RejectedByProvider"] = "REJECTED_BY_PROVIDER";
    OrderStatusEnum["Returned"] = "RETURNED";
    OrderStatusEnum["ReturnedTimedOut"] = "RETURNED_TIMED_OUT";
    OrderStatusEnum["ReturnInProgress"] = "RETURN_IN_PROGRESS";
    OrderStatusEnum["Unfulfilled"] = "UNFULFILLED";
    OrderStatusEnum["VendorResponseTimedOut"] = "VENDOR_RESPONSE_TIMED_OUT";
    OrderStatusEnum["WaitingErxHubApproval"] = "WAITING_ERX_HUB_APPROVAL";
    OrderStatusEnum["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
    OrderStatusEnum["WaitingProviderSelection"] = "WAITING_PROVIDER_SELECTION";
})(OrderStatusEnum || (exports.OrderStatusEnum = OrderStatusEnum = {}));
var OrderType;
(function (OrderType) {
    OrderType["Delivery"] = "DELIVERY";
    OrderType["Pickup"] = "PICKUP";
})(OrderType || (exports.OrderType = OrderType = {}));
var OrderTypesEnum;
(function (OrderTypesEnum) {
    OrderTypesEnum["HomeDelivery"] = "HOME_DELIVERY";
    OrderTypesEnum["PickupFromPharmacy"] = "PICKUP_FROM_PHARMACY";
})(OrderTypesEnum || (exports.OrderTypesEnum = OrderTypesEnum = {}));
var OutPatientJourneyStatus;
(function (OutPatientJourneyStatus) {
    OutPatientJourneyStatus["AssessmentDone"] = "ASSESSMENT_DONE";
    OutPatientJourneyStatus["AssessmentPending"] = "ASSESSMENT_PENDING";
    OutPatientJourneyStatus["Canceled"] = "CANCELED";
    OutPatientJourneyStatus["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    OutPatientJourneyStatus["CheckedIn"] = "CHECKED_IN";
    OutPatientJourneyStatus["Completed"] = "COMPLETED";
    OutPatientJourneyStatus["ConsultationDone"] = "CONSULTATION_DONE";
    OutPatientJourneyStatus["ConsultationPending"] = "CONSULTATION_PENDING";
    OutPatientJourneyStatus["Incompleted"] = "INCOMPLETED";
    OutPatientJourneyStatus["PaymentFailed"] = "PAYMENT_FAILED";
    OutPatientJourneyStatus["PaymentPending"] = "PAYMENT_PENDING";
    OutPatientJourneyStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    OutPatientJourneyStatus["Pending"] = "PENDING";
    OutPatientJourneyStatus["TreatmentDone"] = "TREATMENT_DONE";
})(OutPatientJourneyStatus || (exports.OutPatientJourneyStatus = OutPatientJourneyStatus = {}));
var PasswordResetMethodEnum;
(function (PasswordResetMethodEnum) {
    PasswordResetMethodEnum["Email"] = "EMAIL";
    PasswordResetMethodEnum["Mobile"] = "MOBILE";
})(PasswordResetMethodEnum || (exports.PasswordResetMethodEnum = PasswordResetMethodEnum = {}));
var PatientErrorCode;
(function (PatientErrorCode) {
    PatientErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    PatientErrorCode["Invalid"] = "INVALID";
    PatientErrorCode["NotFound"] = "NOT_FOUND";
    PatientErrorCode["Required"] = "REQUIRED";
    PatientErrorCode["Unique"] = "UNIQUE";
})(PatientErrorCode || (exports.PatientErrorCode = PatientErrorCode = {}));
var PatientHealthParameterSortField;
(function (PatientHealthParameterSortField) {
    /** Sort by health parameter code. */
    PatientHealthParameterSortField["Code"] = "CODE";
    /** Sort by creation date. */
    PatientHealthParameterSortField["CreatedDate"] = "CREATED_DATE";
    /** Sort by source. */
    PatientHealthParameterSortField["Source"] = "SOURCE";
})(PatientHealthParameterSortField || (exports.PatientHealthParameterSortField = PatientHealthParameterSortField = {}));
var PatientIdType;
(function (PatientIdType) {
    PatientIdType["NationalCard"] = "NATIONAL_CARD";
    PatientIdType["Passport"] = "PASSPORT";
    PatientIdType["ResidentCard"] = "RESIDENT_CARD";
})(PatientIdType || (exports.PatientIdType = PatientIdType = {}));
var PatientIdTypeEnum;
(function (PatientIdTypeEnum) {
    PatientIdTypeEnum["NationalCard"] = "NATIONAL_CARD";
    PatientIdTypeEnum["Passport"] = "PASSPORT";
    PatientIdTypeEnum["ResidentCard"] = "RESIDENT_CARD";
})(PatientIdTypeEnum || (exports.PatientIdTypeEnum = PatientIdTypeEnum = {}));
var PatientIdentifierType;
(function (PatientIdentifierType) {
    PatientIdentifierType["MemberId"] = "MEMBER_ID";
    PatientIdentifierType["NationalId"] = "NATIONAL_ID";
})(PatientIdentifierType || (exports.PatientIdentifierType = PatientIdentifierType = {}));
var PatientMaritalStatus;
(function (PatientMaritalStatus) {
    PatientMaritalStatus["Divorced"] = "DIVORCED";
    PatientMaritalStatus["Married"] = "MARRIED";
    PatientMaritalStatus["Single"] = "SINGLE";
    PatientMaritalStatus["Widowed"] = "WIDOWED";
})(PatientMaritalStatus || (exports.PatientMaritalStatus = PatientMaritalStatus = {}));
var PayerErrorCode;
(function (PayerErrorCode) {
    PayerErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    PayerErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    PayerErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    PayerErrorCode["Invalid"] = "INVALID";
    PayerErrorCode["NotFound"] = "NOT_FOUND";
    PayerErrorCode["NotPayersImage"] = "NOT_PAYERS_IMAGE";
    PayerErrorCode["Required"] = "REQUIRED";
    PayerErrorCode["Unique"] = "UNIQUE";
})(PayerErrorCode || (exports.PayerErrorCode = PayerErrorCode = {}));
var PayerOrderField;
(function (PayerOrderField) {
    PayerOrderField["Created"] = "CREATED";
    PayerOrderField["Name"] = "NAME";
    PayerOrderField["NameAr"] = "NAME_AR";
    PayerOrderField["Tpo"] = "TPO";
})(PayerOrderField || (exports.PayerOrderField = PayerOrderField = {}));
var PayerTpo;
(function (PayerTpo) {
    PayerTpo["Eclaim"] = "ECLAIM";
    PayerTpo["Io"] = "IO";
})(PayerTpo || (exports.PayerTpo = PayerTpo = {}));
var PayerType;
(function (PayerType) {
    PayerType["Insurance"] = "INSURANCE";
    PayerType["Tpa"] = "TPA";
})(PayerType || (exports.PayerType = PayerType = {}));
var PayerTypeEnum;
(function (PayerTypeEnum) {
    PayerTypeEnum["Insurance"] = "INSURANCE";
    PayerTypeEnum["Tpa"] = "TPA";
})(PayerTypeEnum || (exports.PayerTypeEnum = PayerTypeEnum = {}));
var PaymentBrand;
(function (PaymentBrand) {
    PaymentBrand["Mada"] = "MADA";
    PaymentBrand["Master"] = "MASTER";
    PaymentBrand["Visa"] = "VISA";
})(PaymentBrand || (exports.PaymentBrand = PaymentBrand = {}));
var PaymentErrorCodes;
(function (PaymentErrorCodes) {
    PaymentErrorCodes["InvalidOrExpiredToken"] = "INVALID_OR_EXPIRED_TOKEN";
    PaymentErrorCodes["PaymentAddCardError"] = "PAYMENT_ADD_CARD_ERROR";
    PaymentErrorCodes["PaymentCardNotFound"] = "PAYMENT_CARD_NOT_FOUND";
    PaymentErrorCodes["PaymentDeleteCardError"] = "PAYMENT_DELETE_CARD_ERROR";
    PaymentErrorCodes["PaymentInvalidSecurityCodeError"] = "PAYMENT_INVALID_SECURITY_CODE_ERROR";
    PaymentErrorCodes["PaymentOwnershipError"] = "PAYMENT_OWNERSHIP_ERROR";
    PaymentErrorCodes["PaymentPermissionDenied"] = "PAYMENT_PERMISSION_DENIED";
    PaymentErrorCodes["PaymentProcessError"] = "PAYMENT_PROCESS_ERROR";
    PaymentErrorCodes["PaymentUnknown"] = "PAYMENT_UNKNOWN";
    PaymentErrorCodes["PaymentUnknownType"] = "PAYMENT_UNKNOWN_TYPE";
})(PaymentErrorCodes || (exports.PaymentErrorCodes = PaymentErrorCodes = {}));
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["Cash"] = "CASH";
    PaymentMethod["Cc"] = "CC";
    PaymentMethod["PaymentCard"] = "PAYMENT_CARD";
})(PaymentMethod || (exports.PaymentMethod = PaymentMethod = {}));
var PaymentOperation;
(function (PaymentOperation) {
    PaymentOperation["Confirm"] = "CONFIRM";
    PaymentOperation["Refund"] = "REFUND";
    PaymentOperation["Reverse"] = "REVERSE";
})(PaymentOperation || (exports.PaymentOperation = PaymentOperation = {}));
var PaymentOperationLogSortField;
(function (PaymentOperationLogSortField) {
    /** Sort visit by created. */
    PaymentOperationLogSortField["Created"] = "CREATED";
})(PaymentOperationLogSortField || (exports.PaymentOperationLogSortField = PaymentOperationLogSortField = {}));
var PaymentSource;
(function (PaymentSource) {
    PaymentSource["Checkout"] = "CHECKOUT";
    PaymentSource["Wallet"] = "WALLET";
})(PaymentSource || (exports.PaymentSource = PaymentSource = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["FullyCovered"] = "FULLY_COVERED";
    PaymentStatus["PaymentFailed"] = "PAYMENT_FAILED";
    PaymentStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    PaymentStatus["PaymentUnavailable"] = "PAYMENT_UNAVAILABLE";
    PaymentStatus["PaymentUnderProcessing"] = "PAYMENT_UNDER_PROCESSING";
    PaymentStatus["Refunded"] = "REFUNDED";
    PaymentStatus["RefundedFailed"] = "REFUNDED_FAILED";
    PaymentStatus["RefundedToWallet"] = "REFUNDED_TO_WALLET";
    PaymentStatus["RefundedUnderProcessing"] = "REFUNDED_UNDER_PROCESSING";
    PaymentStatus["UnPaid"] = "UN_PAID";
})(PaymentStatus || (exports.PaymentStatus = PaymentStatus = {}));
var PaymentType;
(function (PaymentType) {
    PaymentType["Cd"] = "CD";
    PaymentType["Cp"] = "CP";
    PaymentType["Db"] = "DB";
    PaymentType["Pa"] = "PA";
    PaymentType["Rf"] = "RF";
    PaymentType["Rv"] = "RV";
})(PaymentType || (exports.PaymentType = PaymentType = {}));
var PermissionEnum;
(function (PermissionEnum) {
    PermissionEnum["ApproveHealthPackages"] = "APPROVE_HEALTH_PACKAGES";
    PermissionEnum["ApproveProducts"] = "APPROVE_PRODUCTS";
    PermissionEnum["AssignPrescriptionOrderToVendors"] = "ASSIGN_PRESCRIPTION_ORDER_TO_VENDORS";
    PermissionEnum["ConvertVirtualOrderToRealOne"] = "CONVERT_VIRTUAL_ORDER_TO_REAL_ONE";
    PermissionEnum["CustomerSupportManageOrders"] = "CUSTOMER_SUPPORT_MANAGE_ORDERS";
    PermissionEnum["ManageAdminPromotions"] = "MANAGE_ADMIN_PROMOTIONS";
    PermissionEnum["ManageAppointments"] = "MANAGE_APPOINTMENTS";
    PermissionEnum["ManageArticles"] = "MANAGE_ARTICLES";
    PermissionEnum["ManageBlocks"] = "MANAGE_BLOCKS";
    PermissionEnum["ManageBranches"] = "MANAGE_BRANCHES";
    PermissionEnum["ManageBrands"] = "MANAGE_BRANDS";
    PermissionEnum["ManageCallbacksRequests"] = "MANAGE_CALLBACKS_REQUESTS";
    PermissionEnum["ManageChat"] = "MANAGE_CHAT";
    PermissionEnum["ManageChatFlow"] = "MANAGE_CHAT_FLOW";
    PermissionEnum["ManageChatFlowContributor"] = "MANAGE_CHAT_FLOW_CONTRIBUTOR";
    PermissionEnum["ManageCheckouts"] = "MANAGE_CHECKOUTS";
    PermissionEnum["ManageCities"] = "MANAGE_CITIES";
    PermissionEnum["ManageCodeSystemEditorReviewers"] = "MANAGE_CODE_SYSTEM_EDITOR_REVIEWERS";
    PermissionEnum["ManageCodeSystemLists"] = "MANAGE_CODE_SYSTEM_LISTS";
    PermissionEnum["ManageCodeSystemReviewers"] = "MANAGE_CODE_SYSTEM_REVIEWERS";
    PermissionEnum["ManageDeliveryTimeSlots"] = "MANAGE_DELIVERY_TIME_SLOTS";
    PermissionEnum["ManageDepartments"] = "MANAGE_DEPARTMENTS";
    PermissionEnum["ManageDiagnosis"] = "MANAGE_DIAGNOSIS";
    PermissionEnum["ManageDiscounts"] = "MANAGE_DISCOUNTS";
    PermissionEnum["ManageDoctors"] = "MANAGE_DOCTORS";
    PermissionEnum["ManageEarlyRefillReasons"] = "MANAGE_EARLY_REFILL_REASONS";
    PermissionEnum["ManageHealthChannels"] = "MANAGE_HEALTH_CHANNELS";
    PermissionEnum["ManageHealthChannelsCategories"] = "MANAGE_HEALTH_CHANNELS_CATEGORIES";
    PermissionEnum["ManageHealthConditions"] = "MANAGE_HEALTH_CONDITIONS";
    PermissionEnum["ManageHealthMessages"] = "MANAGE_HEALTH_MESSAGES";
    PermissionEnum["ManageHealthPackages"] = "MANAGE_HEALTH_PACKAGES";
    PermissionEnum["ManageHealthPackageCategories"] = "MANAGE_HEALTH_PACKAGE_CATEGORIES";
    PermissionEnum["ManageHealthPackageOrders"] = "MANAGE_HEALTH_PACKAGE_ORDERS";
    PermissionEnum["ManageHealthPrograms"] = "MANAGE_HEALTH_PROGRAMS";
    PermissionEnum["ManageHealthProgramsCareForFields"] = "MANAGE_HEALTH_PROGRAMS_CARE_FOR_FIELDS";
    PermissionEnum["ManageHealthSymptoms"] = "MANAGE_HEALTH_SYMPTOMS";
    PermissionEnum["ManageInsurance"] = "MANAGE_INSURANCE";
    PermissionEnum["ManageInsuranceNetworks"] = "MANAGE_INSURANCE_NETWORKS";
    PermissionEnum["ManageInvoices"] = "MANAGE_INVOICES";
    PermissionEnum["ManageLabs"] = "MANAGE_LABS";
    PermissionEnum["ManageLanguages"] = "MANAGE_LANGUAGES";
    PermissionEnum["ManageMarketplaceOrders"] = "MANAGE_MARKETPLACE_ORDERS";
    PermissionEnum["ManageMedicalDeliveryRequests"] = "MANAGE_MEDICAL_DELIVERY_REQUESTS";
    PermissionEnum["ManageMedicalEdits"] = "MANAGE_MEDICAL_EDITS";
    PermissionEnum["ManageMedicalForms"] = "MANAGE_MEDICAL_FORMS";
    PermissionEnum["ManageMedications"] = "MANAGE_MEDICATIONS";
    PermissionEnum["ManageMedicationScientificDetails"] = "MANAGE_MEDICATION_SCIENTIFIC_DETAILS";
    PermissionEnum["ManageOrders"] = "MANAGE_ORDERS";
    PermissionEnum["ManageParameters"] = "MANAGE_PARAMETERS";
    PermissionEnum["ManagePatients"] = "MANAGE_PATIENTS";
    PermissionEnum["ManagePatientMedicalHistory"] = "MANAGE_PATIENT_MEDICAL_HISTORY";
    PermissionEnum["ManagePayments"] = "MANAGE_PAYMENTS";
    PermissionEnum["ManagePermissionGroup"] = "MANAGE_PERMISSION_GROUP";
    PermissionEnum["ManagePharmacyCredentials"] = "MANAGE_PHARMACY_CREDENTIALS";
    PermissionEnum["ManagePlugins"] = "MANAGE_PLUGINS";
    PermissionEnum["ManagePrescriptions"] = "MANAGE_PRESCRIPTIONS";
    PermissionEnum["ManagePricingRules"] = "MANAGE_PRICING_RULES";
    PermissionEnum["ManageProducts"] = "MANAGE_PRODUCTS";
    PermissionEnum["ManageProductCategories"] = "MANAGE_PRODUCT_CATEGORIES";
    PermissionEnum["ManagePrograms"] = "MANAGE_PROGRAMS";
    PermissionEnum["ManageProgramTeams"] = "MANAGE_PROGRAM_TEAMS";
    PermissionEnum["ManageProgramTemplates"] = "MANAGE_PROGRAM_TEMPLATES";
    PermissionEnum["ManagePromotions"] = "MANAGE_PROMOTIONS";
    PermissionEnum["ManageQualifications"] = "MANAGE_QUALIFICATIONS";
    PermissionEnum["ManageRejectionReasons"] = "MANAGE_REJECTION_REASONS";
    PermissionEnum["ManageRiskStratification"] = "MANAGE_RISK_STRATIFICATION";
    PermissionEnum["ManageRuleEngineRules"] = "MANAGE_RULE_ENGINE_RULES";
    PermissionEnum["ManageSettings"] = "MANAGE_SETTINGS";
    PermissionEnum["ManageShipping"] = "MANAGE_SHIPPING";
    PermissionEnum["ManageSocialAndStreaming"] = "MANAGE_SOCIAL_AND_STREAMING";
    PermissionEnum["ManageSpecializations"] = "MANAGE_SPECIALIZATIONS";
    PermissionEnum["ManageStaff"] = "MANAGE_STAFF";
    PermissionEnum["ManageSubscriptions"] = "MANAGE_SUBSCRIPTIONS";
    PermissionEnum["ManageSurveys"] = "MANAGE_SURVEYS";
    PermissionEnum["ManageTranslations"] = "MANAGE_TRANSLATIONS";
    PermissionEnum["ManageUsers"] = "MANAGE_USERS";
    PermissionEnum["ManageVendors"] = "MANAGE_VENDORS";
    PermissionEnum["ManageVisits"] = "MANAGE_VISITS";
    PermissionEnum["ManageVisitCancelReasons"] = "MANAGE_VISIT_CANCEL_REASONS";
    PermissionEnum["ManageVisitRejectionReasons"] = "MANAGE_VISIT_REJECTION_REASONS";
    PermissionEnum["ManageVisitSummary"] = "MANAGE_VISIT_SUMMARY";
    PermissionEnum["ManageWallets"] = "MANAGE_WALLETS";
    PermissionEnum["MangeActivityTracker"] = "MANGE_ACTIVITY_TRACKER";
    PermissionEnum["MangeEditsAndActions"] = "MANGE_EDITS_AND_ACTIONS";
    PermissionEnum["MedicationScientificDetails"] = "MEDICATION_SCIENTIFIC_DETAILS";
    PermissionEnum["PublishCodeSystem"] = "PUBLISH_CODE_SYSTEM";
    PermissionEnum["UploadCodeSystemFile"] = "UPLOAD_CODE_SYSTEM_FILE";
    PermissionEnum["ValidateProcedures"] = "VALIDATE_PROCEDURES";
    PermissionEnum["VerifyMemberIds"] = "VERIFY_MEMBER_IDS";
    PermissionEnum["VerifyNationalIds"] = "VERIFY_NATIONAL_IDS";
    PermissionEnum["ViewAppointments"] = "VIEW_APPOINTMENTS";
    PermissionEnum["ViewCodeSystemLists"] = "VIEW_CODE_SYSTEM_LISTS";
    PermissionEnum["ViewCustomers"] = "VIEW_CUSTOMERS";
    PermissionEnum["ViewHealthPrograms"] = "VIEW_HEALTH_PROGRAMS";
    PermissionEnum["ViewOrders"] = "VIEW_ORDERS";
    PermissionEnum["ViewPatients"] = "VIEW_PATIENTS";
    PermissionEnum["ViewPrograms"] = "VIEW_PROGRAMS";
    PermissionEnum["ViewProgramTeams"] = "VIEW_PROGRAM_TEAMS";
    PermissionEnum["ViewProgramTemplates"] = "VIEW_PROGRAM_TEMPLATES";
    PermissionEnum["ViewStaff"] = "VIEW_STAFF";
    PermissionEnum["ViewSubscriptions"] = "VIEW_SUBSCRIPTIONS";
    PermissionEnum["ViewSurveys"] = "VIEW_SURVEYS";
    PermissionEnum["ViewTransaction"] = "VIEW_TRANSACTION";
    PermissionEnum["ViewUsers"] = "VIEW_USERS";
    PermissionEnum["ViewVisits"] = "VIEW_VISITS";
    PermissionEnum["ViewWallets"] = "VIEW_WALLETS";
    PermissionEnum["WorkflowManageOrders"] = "WORKFLOW_MANAGE_ORDERS";
    PermissionEnum["ManageOutPatientJourneys"] = "manage_out_patient_journeys";
})(PermissionEnum || (exports.PermissionEnum = PermissionEnum = {}));
var PermissionGroupErrorCode;
(function (PermissionGroupErrorCode) {
    PermissionGroupErrorCode["AssignNonStaffMember"] = "ASSIGN_NON_STAFF_MEMBER";
    PermissionGroupErrorCode["CannotRemoveFromLastGroup"] = "CANNOT_REMOVE_FROM_LAST_GROUP";
    PermissionGroupErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    PermissionGroupErrorCode["Invalid"] = "INVALID";
    PermissionGroupErrorCode["LeftNotManageablePermission"] = "LEFT_NOT_MANAGEABLE_PERMISSION";
    PermissionGroupErrorCode["NotEditable"] = "NOT_EDITABLE";
    PermissionGroupErrorCode["NotFound"] = "NOT_FOUND";
    PermissionGroupErrorCode["OutOfScopePermission"] = "OUT_OF_SCOPE_PERMISSION";
    PermissionGroupErrorCode["OutOfScopeUser"] = "OUT_OF_SCOPE_USER";
    PermissionGroupErrorCode["Required"] = "REQUIRED";
    PermissionGroupErrorCode["Unique"] = "UNIQUE";
})(PermissionGroupErrorCode || (exports.PermissionGroupErrorCode = PermissionGroupErrorCode = {}));
var PermissionGroupSortField;
(function (PermissionGroupSortField) {
    PermissionGroupSortField["CreatedDate"] = "CREATED_DATE";
    PermissionGroupSortField["Name"] = "NAME";
})(PermissionGroupSortField || (exports.PermissionGroupSortField = PermissionGroupSortField = {}));
var PersonGenderEnum;
(function (PersonGenderEnum) {
    PersonGenderEnum["Female"] = "FEMALE";
    PersonGenderEnum["Male"] = "MALE";
})(PersonGenderEnum || (exports.PersonGenderEnum = PersonGenderEnum = {}));
var PlanOrderField;
(function (PlanOrderField) {
    PlanOrderField["Name"] = "NAME";
})(PlanOrderField || (exports.PlanOrderField = PlanOrderField = {}));
var PlanPeriod;
(function (PlanPeriod) {
    PlanPeriod["HalfYear"] = "HALF_YEAR";
    PlanPeriod["Monthly"] = "MONTHLY";
    PlanPeriod["ThreeMonths"] = "THREE_MONTHS";
    PlanPeriod["Weekly"] = "WEEKLY";
    PlanPeriod["Yearly"] = "YEARLY";
})(PlanPeriod || (exports.PlanPeriod = PlanPeriod = {}));
var PrescriptionDispenseStatus;
(function (PrescriptionDispenseStatus) {
    PrescriptionDispenseStatus["Delivered"] = "DELIVERED";
    PrescriptionDispenseStatus["Dispensed"] = "DISPENSED";
    PrescriptionDispenseStatus["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    PrescriptionDispenseStatus["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    PrescriptionDispenseStatus["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    PrescriptionDispenseStatus["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    PrescriptionDispenseStatus["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    PrescriptionDispenseStatus["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    PrescriptionDispenseStatus["ErxHubRejected"] = "ERX_HUB_REJECTED";
    PrescriptionDispenseStatus["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    PrescriptionDispenseStatus["InProgress"] = "IN_PROGRESS";
    PrescriptionDispenseStatus["OutForDelivery"] = "OUT_FOR_DELIVERY";
    PrescriptionDispenseStatus["Pending"] = "PENDING";
    PrescriptionDispenseStatus["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    PrescriptionDispenseStatus["WaitingErxHubApproval"] = "WAITING_ERX_HUB_APPROVAL";
    PrescriptionDispenseStatus["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
    PrescriptionDispenseStatus["WaitingProviderSelection"] = "WAITING_PROVIDER_SELECTION";
})(PrescriptionDispenseStatus || (exports.PrescriptionDispenseStatus = PrescriptionDispenseStatus = {}));
var PrescriptionDispenseStatusEnum;
(function (PrescriptionDispenseStatusEnum) {
    PrescriptionDispenseStatusEnum["Delivered"] = "DELIVERED";
    PrescriptionDispenseStatusEnum["Dispensed"] = "DISPENSED";
    PrescriptionDispenseStatusEnum["ErxHubAuthRequestFailed"] = "ERX_HUB_AUTH_REQUEST_FAILED";
    PrescriptionDispenseStatusEnum["ErxHubClaimApproved"] = "ERX_HUB_CLAIM_APPROVED";
    PrescriptionDispenseStatusEnum["ErxHubClaimFailed"] = "ERX_HUB_CLAIM_FAILED";
    PrescriptionDispenseStatusEnum["ErxHubClaimRejected"] = "ERX_HUB_CLAIM_REJECTED";
    PrescriptionDispenseStatusEnum["ErxHubFullyApproved"] = "ERX_HUB_FULLY_APPROVED";
    PrescriptionDispenseStatusEnum["ErxHubPartiallyApproved"] = "ERX_HUB_PARTIALLY_APPROVED";
    PrescriptionDispenseStatusEnum["ErxHubRejected"] = "ERX_HUB_REJECTED";
    PrescriptionDispenseStatusEnum["ErxHubTimedOut"] = "ERX_HUB_TIMED_OUT";
    PrescriptionDispenseStatusEnum["InProgress"] = "IN_PROGRESS";
    PrescriptionDispenseStatusEnum["OutForDelivery"] = "OUT_FOR_DELIVERY";
    PrescriptionDispenseStatusEnum["Pending"] = "PENDING";
    PrescriptionDispenseStatusEnum["ReadyForCustomerPickup"] = "READY_FOR_CUSTOMER_PICKUP";
    PrescriptionDispenseStatusEnum["WaitingErxHubApproval"] = "WAITING_ERX_HUB_APPROVAL";
    PrescriptionDispenseStatusEnum["WaitingErxHubClaimApproval"] = "WAITING_ERX_HUB_CLAIM_APPROVAL";
    PrescriptionDispenseStatusEnum["WaitingProviderSelection"] = "WAITING_PROVIDER_SELECTION";
})(PrescriptionDispenseStatusEnum || (exports.PrescriptionDispenseStatusEnum = PrescriptionDispenseStatusEnum = {}));
var PrescriptionSortField;
(function (PrescriptionSortField) {
    PrescriptionSortField["Clinician"] = "CLINICIAN";
    PrescriptionSortField["Created"] = "CREATED";
    PrescriptionSortField["PatientIdentifier"] = "PATIENT_IDENTIFIER";
    PrescriptionSortField["ProviderLicense"] = "PROVIDER_LICENSE";
    PrescriptionSortField["ReferenceNumber"] = "REFERENCE_NUMBER";
})(PrescriptionSortField || (exports.PrescriptionSortField = PrescriptionSortField = {}));
var PriceRangeEnum;
(function (PriceRangeEnum) {
    PriceRangeEnum["Average"] = "AVERAGE";
    PriceRangeEnum["Cheap"] = "CHEAP";
    PriceRangeEnum["Expensive"] = "EXPENSIVE";
    PriceRangeEnum["VeryCheap"] = "VERY_CHEAP";
    PriceRangeEnum["VeryExpensive"] = "VERY_EXPENSIVE";
})(PriceRangeEnum || (exports.PriceRangeEnum = PriceRangeEnum = {}));
var Priority;
(function (Priority) {
    Priority["High"] = "HIGH";
    Priority["Low"] = "LOW";
    Priority["Medium"] = "MEDIUM";
})(Priority || (exports.Priority = Priority = {}));
var ProcessingStatus;
(function (ProcessingStatus) {
    ProcessingStatus["Approved"] = "APPROVED";
    ProcessingStatus["Draft"] = "DRAFT";
    ProcessingStatus["Expired"] = "EXPIRED";
    ProcessingStatus["Published"] = "PUBLISHED";
    ProcessingStatus["Rejected"] = "REJECTED";
    ProcessingStatus["UnderReview"] = "UNDER_REVIEW";
})(ProcessingStatus || (exports.ProcessingStatus = ProcessingStatus = {}));
var ProfileType;
(function (ProfileType) {
    ProfileType["Doctor"] = "DOCTOR";
    ProfileType["Patient"] = "PATIENT";
    ProfileType["Vendor"] = "VENDOR";
})(ProfileType || (exports.ProfileType = ProfileType = {}));
var ProviderGuidedCareHealthProgramTeamSortingField;
(function (ProviderGuidedCareHealthProgramTeamSortingField) {
    ProviderGuidedCareHealthProgramTeamSortingField["Created"] = "CREATED";
    ProviderGuidedCareHealthProgramTeamSortingField["Modified"] = "MODIFIED";
    ProviderGuidedCareHealthProgramTeamSortingField["Name"] = "NAME";
    ProviderGuidedCareHealthProgramTeamSortingField["Status"] = "STATUS";
})(ProviderGuidedCareHealthProgramTeamSortingField || (exports.ProviderGuidedCareHealthProgramTeamSortingField = ProviderGuidedCareHealthProgramTeamSortingField = {}));
var ProviderGuidedCareTeamStatus;
(function (ProviderGuidedCareTeamStatus) {
    ProviderGuidedCareTeamStatus["Approved"] = "APPROVED";
    ProviderGuidedCareTeamStatus["Pending"] = "PENDING";
    ProviderGuidedCareTeamStatus["Rejected"] = "REJECTED";
})(ProviderGuidedCareTeamStatus || (exports.ProviderGuidedCareTeamStatus = ProviderGuidedCareTeamStatus = {}));
var QuestionType;
(function (QuestionType) {
    QuestionType["Boolean"] = "BOOLEAN";
    QuestionType["MultiChoice"] = "MULTI_CHOICE";
    QuestionType["SingleChoice"] = "SINGLE_CHOICE";
    QuestionType["String"] = "STRING";
})(QuestionType || (exports.QuestionType = QuestionType = {}));
var RatingActionEnum;
(function (RatingActionEnum) {
    RatingActionEnum["Create"] = "CREATE";
    RatingActionEnum["Update"] = "UPDATE";
})(RatingActionEnum || (exports.RatingActionEnum = RatingActionEnum = {}));
var ReferralErrorCodes;
(function (ReferralErrorCodes) {
    ReferralErrorCodes["ReferralDoctorNotFound"] = "REFERRAL_DOCTOR_NOT_FOUND";
    ReferralErrorCodes["ReferralEmptyDoctorLicense"] = "REFERRAL_EMPTY_DOCTOR_LICENSE";
    ReferralErrorCodes["ReferralErrorCallingIntegrator"] = "REFERRAL_ERROR_CALLING_INTEGRATOR";
    ReferralErrorCodes["ReferralInvalidId"] = "REFERRAL_INVALID_ID";
    ReferralErrorCodes["ReferralMakerNoneNegativePageSize"] = "REFERRAL_MAKER_NONE_NEGATIVE_PAGE_SIZE";
    ReferralErrorCodes["ReferralMakerUnknown"] = "REFERRAL_MAKER_UNKNOWN";
    ReferralErrorCodes["ReferralMakerUnknownType"] = "REFERRAL_MAKER_UNKNOWN_TYPE";
    ReferralErrorCodes["ReferralMemberIdIsNull"] = "REFERRAL_MEMBER_ID_IS_NULL";
    ReferralErrorCodes["ReferralMemberNotActive"] = "REFERRAL_MEMBER_NOT_ACTIVE";
    ReferralErrorCodes["ReferralNotFound"] = "REFERRAL_NOT_FOUND";
    ReferralErrorCodes["ReferralNoPrincipalDiagnosis"] = "REFERRAL_NO_PRINCIPAL_DIAGNOSIS";
    ReferralErrorCodes["ReferralOnlyOnePrincipalDiagnosisAllowed"] = "REFERRAL_ONLY_ONE_PRINCIPAL_DIAGNOSIS_ALLOWED";
    ReferralErrorCodes["ReferralOperationNotAllowed"] = "REFERRAL_OPERATION_NOT_ALLOWED";
    ReferralErrorCodes["ReferralPatientNotEligible"] = "REFERRAL_PATIENT_NOT_ELIGIBLE";
    ReferralErrorCodes["ReferralPatientNotFound"] = "REFERRAL_PATIENT_NOT_FOUND";
    ReferralErrorCodes["ReferralPayerLicenseNotDefined"] = "REFERRAL_PAYER_LICENSE_NOT_DEFINED";
    ReferralErrorCodes["ReferralPermissionDenied"] = "REFERRAL_PERMISSION_DENIED";
    ReferralErrorCodes["ReferralUserIdNotDefined"] = "REFERRAL_USER_ID_NOT_DEFINED";
})(ReferralErrorCodes || (exports.ReferralErrorCodes = ReferralErrorCodes = {}));
var ReferralOrderDirection;
(function (ReferralOrderDirection) {
    ReferralOrderDirection["Asc"] = "ASC";
    ReferralOrderDirection["Desc"] = "DESC";
})(ReferralOrderDirection || (exports.ReferralOrderDirection = ReferralOrderDirection = {}));
var RefundStatus;
(function (RefundStatus) {
    RefundStatus["Handled"] = "HANDLED";
    RefundStatus["Requested"] = "REQUESTED";
})(RefundStatus || (exports.RefundStatus = RefundStatus = {}));
var RefundTarget;
(function (RefundTarget) {
    RefundTarget["PaymentGateway"] = "PAYMENT_GATEWAY";
    RefundTarget["Wallet"] = "WALLET";
})(RefundTarget || (exports.RefundTarget = RefundTarget = {}));
var RelationTypes;
(function (RelationTypes) {
    RelationTypes["Brother"] = "BROTHER";
    RelationTypes["Daughter"] = "DAUGHTER";
    RelationTypes["Father"] = "FATHER";
    RelationTypes["Grandfather"] = "GRANDFATHER";
    RelationTypes["Grandmother"] = "GRANDMOTHER";
    RelationTypes["Husband"] = "HUSBAND";
    RelationTypes["Mother"] = "MOTHER";
    RelationTypes["Other"] = "OTHER";
    RelationTypes["Sister"] = "SISTER";
    RelationTypes["Son"] = "SON";
    RelationTypes["Wife"] = "WIFE";
})(RelationTypes || (exports.RelationTypes = RelationTypes = {}));
var ReportingPeriod;
(function (ReportingPeriod) {
    ReportingPeriod["Last_7Days"] = "LAST_7_DAYS";
    ReportingPeriod["ThisMonth"] = "THIS_MONTH";
    ReportingPeriod["Today"] = "TODAY";
})(ReportingPeriod || (exports.ReportingPeriod = ReportingPeriod = {}));
var RequestStatus;
(function (RequestStatus) {
    RequestStatus["Approved"] = "APPROVED";
    RequestStatus["Pending"] = "PENDING";
    RequestStatus["Rejected"] = "REJECTED";
})(RequestStatus || (exports.RequestStatus = RequestStatus = {}));
var RequestedBy;
(function (RequestedBy) {
    RequestedBy["Patient"] = "PATIENT";
    RequestedBy["ProviderGuidedCareHealthProgramTeam"] = "PROVIDER_GUIDED_CARE_HEALTH_PROGRAM_TEAM";
})(RequestedBy || (exports.RequestedBy = RequestedBy = {}));
var ResidencyTypesEnum;
(function (ResidencyTypesEnum) {
    ResidencyTypesEnum["Residence"] = "RESIDENCE";
    ResidencyTypesEnum["Visitor"] = "VISITOR";
})(ResidencyTypesEnum || (exports.ResidencyTypesEnum = ResidencyTypesEnum = {}));
var ReviewQuestionValueType;
(function (ReviewQuestionValueType) {
    ReviewQuestionValueType["Boolean"] = "BOOLEAN";
    ReviewQuestionValueType["StarRating"] = "STAR_RATING";
})(ReviewQuestionValueType || (exports.ReviewQuestionValueType = ReviewQuestionValueType = {}));
var RiskFactorTemplateSortingField;
(function (RiskFactorTemplateSortingField) {
    RiskFactorTemplateSortingField["ArabicDisplay"] = "ARABIC_DISPLAY";
    RiskFactorTemplateSortingField["Code"] = "CODE";
    RiskFactorTemplateSortingField["CreatedDate"] = "CREATED_DATE";
    RiskFactorTemplateSortingField["Display"] = "DISPLAY";
    RiskFactorTemplateSortingField["IsActive"] = "IS_ACTIVE";
})(RiskFactorTemplateSortingField || (exports.RiskFactorTemplateSortingField = RiskFactorTemplateSortingField = {}));
var RiskLevel;
(function (RiskLevel) {
    RiskLevel["High"] = "HIGH";
    RiskLevel["Low"] = "LOW";
})(RiskLevel || (exports.RiskLevel = RiskLevel = {}));
var ServiceDescription;
(function (ServiceDescription) {
    ServiceDescription["LabTests"] = "LAB_TESTS";
    ServiceDescription["RadTests"] = "RAD_TESTS";
})(ServiceDescription || (exports.ServiceDescription = ServiceDescription = {}));
var SiteErrorCode;
(function (SiteErrorCode) {
    SiteErrorCode["ForbiddenCharacter"] = "FORBIDDEN_CHARACTER";
    SiteErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
})(SiteErrorCode || (exports.SiteErrorCode = SiteErrorCode = {}));
var SmokingHabit;
(function (SmokingHabit) {
    SmokingHabit["FormerSmoker"] = "FORMER_SMOKER";
    SmokingHabit["HeavySmoker"] = "HEAVY_SMOKER";
    SmokingHabit["LightSmoker"] = "LIGHT_SMOKER";
    SmokingHabit["Never"] = "NEVER";
})(SmokingHabit || (exports.SmokingHabit = SmokingHabit = {}));
var SortDirection;
(function (SortDirection) {
    SortDirection["Asc"] = "ASC";
    SortDirection["Desc"] = "DESC";
})(SortDirection || (exports.SortDirection = SortDirection = {}));
var Source;
(function (Source) {
    Source["Doctor"] = "DOCTOR";
    Source["Iot"] = "IOT";
    Source["Nurse"] = "NURSE";
    Source["Patient"] = "PATIENT";
})(Source || (exports.Source = Source = {}));
var SpecializationErrorCode;
(function (SpecializationErrorCode) {
    SpecializationErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    SpecializationErrorCode["Invalid"] = "INVALID";
    SpecializationErrorCode["NotFound"] = "NOT_FOUND";
    SpecializationErrorCode["Required"] = "REQUIRED";
    SpecializationErrorCode["Unique"] = "UNIQUE";
})(SpecializationErrorCode || (exports.SpecializationErrorCode = SpecializationErrorCode = {}));
var SubListItemSortField;
(function (SubListItemSortField) {
    SubListItemSortField["Code"] = "CODE";
    SubListItemSortField["Display"] = "DISPLAY";
})(SubListItemSortField || (exports.SubListItemSortField = SubListItemSortField = {}));
var SubListSortField;
(function (SubListSortField) {
    SubListSortField["Display"] = "DISPLAY";
    SubListSortField["Field"] = "FIELD";
})(SubListSortField || (exports.SubListSortField = SubListSortField = {}));
var SubscriptionErrorCode;
(function (SubscriptionErrorCode) {
    SubscriptionErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    SubscriptionErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    SubscriptionErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    SubscriptionErrorCode["Invalid"] = "INVALID";
    SubscriptionErrorCode["NotFound"] = "NOT_FOUND";
    SubscriptionErrorCode["Required"] = "REQUIRED";
    SubscriptionErrorCode["Unique"] = "UNIQUE";
})(SubscriptionErrorCode || (exports.SubscriptionErrorCode = SubscriptionErrorCode = {}));
var SubscriptionErrorCodes;
(function (SubscriptionErrorCodes) {
    SubscriptionErrorCodes["SubscriptionInvalidId"] = "SUBSCRIPTION_INVALID_ID";
    SubscriptionErrorCodes["SubscriptionMakerUnknownType"] = "SUBSCRIPTION_MAKER_UNKNOWN_TYPE";
    SubscriptionErrorCodes["SubscriptionNoneNegativePageSize"] = "SUBSCRIPTION_NONE_NEGATIVE_PAGE_SIZE";
    SubscriptionErrorCodes["SubscriptionNotFound"] = "SUBSCRIPTION_NOT_FOUND";
    SubscriptionErrorCodes["SubscriptionPermissionDenied"] = "SUBSCRIPTION_PERMISSION_DENIED";
    SubscriptionErrorCodes["SubscriptionUnknown"] = "SUBSCRIPTION_UNKNOWN";
    SubscriptionErrorCodes["SubscriptionValidation"] = "SUBSCRIPTION_VALIDATION";
})(SubscriptionErrorCodes || (exports.SubscriptionErrorCodes = SubscriptionErrorCodes = {}));
var SurveyErrorCodes;
(function (SurveyErrorCodes) {
    SurveyErrorCodes["SurveyDuplicate"] = "SURVEY_DUPLICATE";
    SurveyErrorCodes["SurveyInvalidDateFormat"] = "SURVEY_INVALID_DATE_FORMAT";
    SurveyErrorCodes["SurveyMakerUnknownType"] = "SURVEY_MAKER_UNKNOWN_TYPE";
    SurveyErrorCodes["SurveyNoneNegativePageSize"] = "SURVEY_NONE_NEGATIVE_PAGE_SIZE";
    SurveyErrorCodes["SurveyNotFound"] = "SURVEY_NOT_FOUND";
    SurveyErrorCodes["SurveyPermissionDenied"] = "SURVEY_PERMISSION_DENIED";
    SurveyErrorCodes["SurveyUnknown"] = "SURVEY_UNKNOWN";
    SurveyErrorCodes["SurveyValidation"] = "SURVEY_VALIDATION";
})(SurveyErrorCodes || (exports.SurveyErrorCodes = SurveyErrorCodes = {}));
var SurveyGender;
(function (SurveyGender) {
    SurveyGender["Female"] = "Female";
    SurveyGender["Male"] = "Male";
})(SurveyGender || (exports.SurveyGender = SurveyGender = {}));
var SurveySortingField;
(function (SurveySortingField) {
    SurveySortingField["CreationDate"] = "CREATION_DATE";
    SurveySortingField["Status"] = "STATUS";
    SurveySortingField["UpdateDate"] = "UPDATE_DATE";
})(SurveySortingField || (exports.SurveySortingField = SurveySortingField = {}));
var SurveyStatus;
(function (SurveyStatus) {
    SurveyStatus["Cancelled"] = "CANCELLED";
    SurveyStatus["Draft"] = "DRAFT";
    SurveyStatus["Expired"] = "EXPIRED";
    SurveyStatus["Published"] = "PUBLISHED";
    SurveyStatus["Unpublished"] = "UNPUBLISHED";
})(SurveyStatus || (exports.SurveyStatus = SurveyStatus = {}));
var Tpo;
(function (Tpo) {
    Tpo["Eclaim"] = "ECLAIM";
    Tpo["Io"] = "IO";
})(Tpo || (exports.Tpo = Tpo = {}));
var TaskDefinitionAction;
(function (TaskDefinitionAction) {
    TaskDefinitionAction["SendMedicalMessage"] = "SEND_MEDICAL_MESSAGE";
    TaskDefinitionAction["SendNotification"] = "SEND_NOTIFICATION";
})(TaskDefinitionAction || (exports.TaskDefinitionAction = TaskDefinitionAction = {}));
var TaskDefinitionPriority;
(function (TaskDefinitionPriority) {
    TaskDefinitionPriority["Low"] = "LOW";
    TaskDefinitionPriority["Moderate"] = "MODERATE";
    TaskDefinitionPriority["Urgent"] = "URGENT";
})(TaskDefinitionPriority || (exports.TaskDefinitionPriority = TaskDefinitionPriority = {}));
var TaskDefinitionSortingField;
(function (TaskDefinitionSortingField) {
    TaskDefinitionSortingField["Created"] = "CREATED";
    TaskDefinitionSortingField["Priority"] = "PRIORITY";
})(TaskDefinitionSortingField || (exports.TaskDefinitionSortingField = TaskDefinitionSortingField = {}));
var TaskDefinitionStatus;
(function (TaskDefinitionStatus) {
    TaskDefinitionStatus["Cancelled"] = "CANCELLED";
    TaskDefinitionStatus["Done"] = "DONE";
    TaskDefinitionStatus["Idle"] = "IDLE";
    TaskDefinitionStatus["InProgress"] = "IN_PROGRESS";
    TaskDefinitionStatus["Missed"] = "MISSED";
    TaskDefinitionStatus["Pending"] = "PENDING";
})(TaskDefinitionStatus || (exports.TaskDefinitionStatus = TaskDefinitionStatus = {}));
var TaskWorkflowType;
(function (TaskWorkflowType) {
    TaskWorkflowType["ConfirmAppointmentDate"] = "CONFIRM_APPOINTMENT_DATE";
    TaskWorkflowType["ReviewMedicalFormAnswer"] = "REVIEW_MEDICAL_FORM_ANSWER";
    TaskWorkflowType["SubmitMedicalFormAnswers"] = "SUBMIT_MEDICAL_FORM_ANSWERS";
})(TaskWorkflowType || (exports.TaskWorkflowType = TaskWorkflowType = {}));
var TeamMemberPosition;
(function (TeamMemberPosition) {
    TeamMemberPosition["DentalHygienist"] = "DENTAL_HYGIENIST";
    TeamMemberPosition["DiabetesEducator"] = "DIABETES_EDUCATOR";
    TeamMemberPosition["FitnessCoach"] = "FITNESS_COACH";
    TeamMemberPosition["Nurse"] = "NURSE";
    TeamMemberPosition["Nutritionist"] = "NUTRITIONIST";
    TeamMemberPosition["Optometrist"] = "OPTOMETRIST";
    TeamMemberPosition["Physician"] = "PHYSICIAN";
    TeamMemberPosition["PodiatricMedicalAssistant"] = "PODIATRIC_MEDICAL_ASSISTANT";
    TeamMemberPosition["Psychologist"] = "PSYCHOLOGIST";
    TeamMemberPosition["SocialWorker"] = "SOCIAL_WORKER";
    TeamMemberPosition["TeamAdmin"] = "TEAM_ADMIN";
    TeamMemberPosition["TeamLeader"] = "TEAM_LEADER";
})(TeamMemberPosition || (exports.TeamMemberPosition = TeamMemberPosition = {}));
var TemplateActivityEventItemSortingField;
(function (TemplateActivityEventItemSortingField) {
    TemplateActivityEventItemSortingField["Offset"] = "OFFSET";
})(TemplateActivityEventItemSortingField || (exports.TemplateActivityEventItemSortingField = TemplateActivityEventItemSortingField = {}));
var TemplateFieldType;
(function (TemplateFieldType) {
    TemplateFieldType["AudioFile"] = "AUDIO_FILE";
    TemplateFieldType["Boolean"] = "BOOLEAN";
    TemplateFieldType["Date"] = "DATE";
    TemplateFieldType["List"] = "LIST";
    TemplateFieldType["Number"] = "NUMBER";
    TemplateFieldType["NumericList"] = "NUMERIC_LIST";
    TemplateFieldType["String"] = "STRING";
    TemplateFieldType["VideoFile"] = "VIDEO_FILE";
})(TemplateFieldType || (exports.TemplateFieldType = TemplateFieldType = {}));
var TerminologyErrorCodes;
(function (TerminologyErrorCodes) {
    TerminologyErrorCodes["InvalidOrExpiredToken"] = "INVALID_OR_EXPIRED_TOKEN";
    TerminologyErrorCodes["TerminologyClinicianNotFound"] = "TERMINOLOGY_CLINICIAN_NOT_FOUND";
    TerminologyErrorCodes["TerminologyConceptDefinitionListNotFound"] = "TERMINOLOGY_CONCEPT_DEFINITION_LIST_NOT_FOUND";
    TerminologyErrorCodes["TerminologyDuplicateColumn"] = "TERMINOLOGY_DUPLICATE_COLUMN";
    TerminologyErrorCodes["TerminologyDuplicateProperty"] = "TERMINOLOGY_DUPLICATE_PROPERTY";
    TerminologyErrorCodes["TerminologyDuplicateRowData"] = "TERMINOLOGY_DUPLICATE_ROW_DATA";
    TerminologyErrorCodes["TerminologyDuplicateVersion"] = "TERMINOLOGY_DUPLICATE_VERSION";
    TerminologyErrorCodes["TerminologyEmptyFile"] = "TERMINOLOGY_EMPTY_FILE";
    TerminologyErrorCodes["TerminologyFileServiceNotReachable"] = "TERMINOLOGY_FILE_SERVICE_NOT_REACHABLE";
    TerminologyErrorCodes["TerminologyInvalidDateFormat"] = "TERMINOLOGY_INVALID_DATE_FORMAT";
    TerminologyErrorCodes["TerminologyInvalidFileExtension"] = "TERMINOLOGY_INVALID_FILE_EXTENSION";
    TerminologyErrorCodes["TerminologyInvalidProcessingStatus"] = "TERMINOLOGY_INVALID_PROCESSING_STATUS";
    TerminologyErrorCodes["TerminologyInvalidToken"] = "TERMINOLOGY_INVALID_TOKEN";
    TerminologyErrorCodes["TerminologyListDefinitionNotFound"] = "TERMINOLOGY_LIST_Definition_NOT_FOUND";
    TerminologyErrorCodes["TerminologyListItemNotFound"] = "TERMINOLOGY_LIST_Item_NOT_FOUND";
    TerminologyErrorCodes["TerminologyListNotFound"] = "TERMINOLOGY_LIST_NOT_FOUND";
    TerminologyErrorCodes["TerminologyMandatoryConceptPropertyException"] = "TERMINOLOGY_MANDATORY_CONCEPT_PROPERTY_EXCEPTION";
    TerminologyErrorCodes["TerminologyNoneDraftCodeSystem"] = "TERMINOLOGY_NONE_DRAFT_CODE_SYSTEM";
    TerminologyErrorCodes["TerminologyNoneEditableConceptDefinition"] = "TERMINOLOGY_NONE_EDITABLE_CONCEPT_DEFINITION";
    TerminologyErrorCodes["TerminologyNoneEditableList"] = "TERMINOLOGY_NONE_EDITABLE_LIST";
    TerminologyErrorCodes["TerminologyNoneNegativePageSize"] = "TERMINOLOGY_NONE_NEGATIVE_PAGE_SIZE";
    TerminologyErrorCodes["TerminologyNoneUnderReviewCodeSystem"] = "TERMINOLOGY_NONE_UNDER_REVIEW_CODE_SYSTEM";
    TerminologyErrorCodes["TerminologyNotApprovedCodeSystem"] = "TERMINOLOGY_NOT_APPROVED_CODE_SYSTEM";
    TerminologyErrorCodes["TerminologyNotFound"] = "TERMINOLOGY_NOT_FOUND";
    TerminologyErrorCodes["TerminologyNoActiveCodeSystem"] = "TERMINOLOGY_NO_ACTIVE_CODE_SYSTEM";
    TerminologyErrorCodes["TerminologyOldVersion"] = "TERMINOLOGY_OLD_VERSION";
    TerminologyErrorCodes["TerminologyPermissionDenied"] = "TERMINOLOGY_PERMISSION_DENIED";
    TerminologyErrorCodes["TerminologyUnknown"] = "TERMINOLOGY_UNKNOWN";
    TerminologyErrorCodes["TerminologyUnknownType"] = "TERMINOLOGY_UNKNOWN_TYPE";
    TerminologyErrorCodes["TerminologyValidation"] = "TERMINOLOGY_VALIDATION";
})(TerminologyErrorCodes || (exports.TerminologyErrorCodes = TerminologyErrorCodes = {}));
var TextSummaryErrorCode;
(function (TextSummaryErrorCode) {
    TextSummaryErrorCode["ControlledDrug"] = "CONTROLLED_DRUG";
    TextSummaryErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    TextSummaryErrorCode["Ingested"] = "INGESTED";
    TextSummaryErrorCode["Invalid"] = "INVALID";
    TextSummaryErrorCode["NotFound"] = "NOT_FOUND";
    TextSummaryErrorCode["Required"] = "REQUIRED";
    TextSummaryErrorCode["Unique"] = "UNIQUE";
})(TextSummaryErrorCode || (exports.TextSummaryErrorCode = TextSummaryErrorCode = {}));
var TrackerActivityType;
(function (TrackerActivityType) {
    TrackerActivityType["Supplement"] = "SUPPLEMENT";
    TrackerActivityType["TradeDrug"] = "TRADE_DRUG";
})(TrackerActivityType || (exports.TrackerActivityType = TrackerActivityType = {}));
var TrackerColor;
(function (TrackerColor) {
    TrackerColor["Black"] = "BLACK";
    TrackerColor["Blue"] = "BLUE";
    TrackerColor["Brown"] = "BROWN";
    TrackerColor["Green"] = "GREEN";
    TrackerColor["Orange"] = "ORANGE";
    TrackerColor["Pink"] = "PINK";
    TrackerColor["Purple"] = "PURPLE";
    TrackerColor["Red"] = "RED";
    TrackerColor["White"] = "WHITE";
    TrackerColor["Yellow"] = "YELLOW";
})(TrackerColor || (exports.TrackerColor = TrackerColor = {}));
var TrackerDayOfWeek;
(function (TrackerDayOfWeek) {
    TrackerDayOfWeek["Friday"] = "FRIDAY";
    TrackerDayOfWeek["Monday"] = "MONDAY";
    TrackerDayOfWeek["Saturday"] = "SATURDAY";
    TrackerDayOfWeek["Sunday"] = "SUNDAY";
    TrackerDayOfWeek["Thursday"] = "THURSDAY";
    TrackerDayOfWeek["Tuesday"] = "TUESDAY";
    TrackerDayOfWeek["Wednesday"] = "WEDNESDAY";
})(TrackerDayOfWeek || (exports.TrackerDayOfWeek = TrackerDayOfWeek = {}));
var TrackerDurationType;
(function (TrackerDurationType) {
    TrackerDurationType["Day"] = "DAY";
    TrackerDurationType["Month"] = "MONTH";
    TrackerDurationType["Week"] = "WEEK";
    TrackerDurationType["Year"] = "YEAR";
})(TrackerDurationType || (exports.TrackerDurationType = TrackerDurationType = {}));
var TrackerFrequencyType;
(function (TrackerFrequencyType) {
    TrackerFrequencyType["EveryDay"] = "EVERY_DAY";
    TrackerFrequencyType["EveryOtherDay"] = "EVERY_OTHER_DAY";
    TrackerFrequencyType["EveryXDays"] = "EVERY_X_DAYS";
    TrackerFrequencyType["EveryXMonths"] = "EVERY_X_MONTHS";
    TrackerFrequencyType["EveryXWeeks"] = "EVERY_X_WEEKS";
    TrackerFrequencyType["SpecificDaysOfWeek"] = "SPECIFIC_DAYS_OF_WEEK";
})(TrackerFrequencyType || (exports.TrackerFrequencyType = TrackerFrequencyType = {}));
var TrackerIntakeInstructions;
(function (TrackerIntakeInstructions) {
    TrackerIntakeInstructions["AfterEating"] = "AFTER_EATING";
    TrackerIntakeInstructions["BeforeEating"] = "BEFORE_EATING";
    TrackerIntakeInstructions["DoesntMatter"] = "DOESNT_MATTER";
    TrackerIntakeInstructions["WhileEating"] = "WHILE_EATING";
})(TrackerIntakeInstructions || (exports.TrackerIntakeInstructions = TrackerIntakeInstructions = {}));
var TrackerReminderSettingsSortingField;
(function (TrackerReminderSettingsSortingField) {
    TrackerReminderSettingsSortingField["StartDate"] = "START_DATE";
})(TrackerReminderSettingsSortingField || (exports.TrackerReminderSettingsSortingField = TrackerReminderSettingsSortingField = {}));
var TrackerReminderSortingField;
(function (TrackerReminderSortingField) {
    TrackerReminderSortingField["ReminderTime"] = "REMINDER_TIME";
})(TrackerReminderSortingField || (exports.TrackerReminderSortingField = TrackerReminderSortingField = {}));
var TrackerReminderStatus;
(function (TrackerReminderStatus) {
    TrackerReminderStatus["Cancelled"] = "CANCELLED";
    TrackerReminderStatus["Done"] = "DONE";
    TrackerReminderStatus["Missed"] = "MISSED";
    TrackerReminderStatus["Pending"] = "PENDING";
    TrackerReminderStatus["Skipped"] = "SKIPPED";
    TrackerReminderStatus["TimeComes"] = "TIME_COMES";
})(TrackerReminderStatus || (exports.TrackerReminderStatus = TrackerReminderStatus = {}));
var TrackerReminderStatusUpdate;
(function (TrackerReminderStatusUpdate) {
    TrackerReminderStatusUpdate["Done"] = "DONE";
    TrackerReminderStatusUpdate["Skipped"] = "SKIPPED";
})(TrackerReminderStatusUpdate || (exports.TrackerReminderStatusUpdate = TrackerReminderStatusUpdate = {}));
var TrackerSortDirection;
(function (TrackerSortDirection) {
    TrackerSortDirection["Asc"] = "ASC";
    TrackerSortDirection["Desc"] = "DESC";
})(TrackerSortDirection || (exports.TrackerSortDirection = TrackerSortDirection = {}));
var TrackerStrengthUnit;
(function (TrackerStrengthUnit) {
    TrackerStrengthUnit["G"] = "G";
    TrackerStrengthUnit["Mcg"] = "MCG";
    TrackerStrengthUnit["Mg"] = "MG";
    TrackerStrengthUnit["Ml"] = "ML";
})(TrackerStrengthUnit || (exports.TrackerStrengthUnit = TrackerStrengthUnit = {}));
var TrackerTimeEnum;
(function (TrackerTimeEnum) {
    TrackerTimeEnum["ExactTime"] = "EXACT_TIME";
    TrackerTimeEnum["MidDay"] = "MID_DAY";
    TrackerTimeEnum["Morning"] = "MORNING";
    TrackerTimeEnum["Night"] = "NIGHT";
})(TrackerTimeEnum || (exports.TrackerTimeEnum = TrackerTimeEnum = {}));
var TransactionAuthStatus;
(function (TransactionAuthStatus) {
    TransactionAuthStatus["Approved"] = "APPROVED";
    TransactionAuthStatus["Pending"] = "PENDING";
    TransactionAuthStatus["Rejected"] = "REJECTED";
})(TransactionAuthStatus || (exports.TransactionAuthStatus = TransactionAuthStatus = {}));
var TransactionAuthorizationResult;
(function (TransactionAuthorizationResult) {
    TransactionAuthorizationResult["No"] = "No";
    TransactionAuthorizationResult["Pending"] = "Pending";
    TransactionAuthorizationResult["Yes"] = "Yes";
})(TransactionAuthorizationResult || (exports.TransactionAuthorizationResult = TransactionAuthorizationResult = {}));
var TransactionClaimDateType;
(function (TransactionClaimDateType) {
    TransactionClaimDateType["EncounterEndDate"] = "ENCOUNTER_END_DATE";
    TransactionClaimDateType["EncounterStartDate"] = "ENCOUNTER_START_DATE";
    TransactionClaimDateType["TransactionDate"] = "TRANSACTION_DATE";
})(TransactionClaimDateType || (exports.TransactionClaimDateType = TransactionClaimDateType = {}));
var TransactionClaimSortField;
(function (TransactionClaimSortField) {
    TransactionClaimSortField["EncounterEndDate"] = "ENCOUNTER_END_DATE";
    TransactionClaimSortField["EncounterStartDate"] = "ENCOUNTER_START_DATE";
    TransactionClaimSortField["TransactionDate"] = "TRANSACTION_DATE";
})(TransactionClaimSortField || (exports.TransactionClaimSortField = TransactionClaimSortField = {}));
var TransactionErrorCodes;
(function (TransactionErrorCodes) {
    TransactionErrorCodes["IntegrationActivityCodeNotFound"] = "INTEGRATION_ACTIVITY_CODE_NOT_FOUND";
    TransactionErrorCodes["IntegrationClinicianCredentialsNotFound"] = "INTEGRATION_CLINICIAN_CREDENTIALS_NOT_FOUND";
    TransactionErrorCodes["IntegrationDateOfBirthNotDefined"] = "INTEGRATION_DATE_OF_BIRTH_NOT_DEFINED";
    TransactionErrorCodes["IntegrationDuplicateBranchCredentials"] = "INTEGRATION_DUPLICATE_BRANCH_CREDENTIALS";
    TransactionErrorCodes["IntegrationErxValidation"] = "INTEGRATION_ERX_VALIDATION";
    TransactionErrorCodes["IntegrationInvalidOrExpiredToken"] = "INTEGRATION_INVALID_OR_EXPIRED_TOKEN";
    TransactionErrorCodes["IntegrationMemberNotActive"] = "INTEGRATION_MEMBER_NOT_ACTIVE";
    TransactionErrorCodes["IntegrationNoneNegativePageSize"] = "INTEGRATION_NONE_NEGATIVE_PAGE_SIZE";
    TransactionErrorCodes["IntegrationNullPointer"] = "INTEGRATION_NULL_POINTER";
    TransactionErrorCodes["IntegrationOperationNotAllowed"] = "INTEGRATION_OPERATION_NOT_ALLOWED";
    TransactionErrorCodes["IntegrationPatientNotFound"] = "INTEGRATION_PATIENT_NOT_FOUND";
    TransactionErrorCodes["IntegrationPayerLicenseNotDefined"] = "INTEGRATION_PAYER_LICENSE_NOT_DEFINED";
    TransactionErrorCodes["IntegrationPermissionDenied"] = "INTEGRATION_PERMISSION_DENIED";
    TransactionErrorCodes["IntegrationUnknown"] = "INTEGRATION_UNKNOWN";
    TransactionErrorCodes["IntegrationUnknownType"] = "INTEGRATION_UNKNOWN_TYPE";
    TransactionErrorCodes["InvalidClinicianCredentials"] = "INVALID_CLINICIAN_CREDENTIALS";
})(TransactionErrorCodes || (exports.TransactionErrorCodes = TransactionErrorCodes = {}));
var TransactionFrequencyType;
(function (TransactionFrequencyType) {
    TransactionFrequencyType["Day"] = "Day";
    TransactionFrequencyType["Hour"] = "Hour";
    TransactionFrequencyType["Month"] = "Month";
    TransactionFrequencyType["Week"] = "Week";
})(TransactionFrequencyType || (exports.TransactionFrequencyType = TransactionFrequencyType = {}));
var TransactionOrderDirection;
(function (TransactionOrderDirection) {
    TransactionOrderDirection["Asc"] = "ASC";
    TransactionOrderDirection["Desc"] = "DESC";
})(TransactionOrderDirection || (exports.TransactionOrderDirection = TransactionOrderDirection = {}));
var TransactionSortDirection;
(function (TransactionSortDirection) {
    TransactionSortDirection["Asc"] = "ASC";
    TransactionSortDirection["Desc"] = "DESC";
})(TransactionSortDirection || (exports.TransactionSortDirection = TransactionSortDirection = {}));
var TransactionSortField;
(function (TransactionSortField) {
    TransactionSortField["TransactionDate"] = "TRANSACTION_DATE";
})(TransactionSortField || (exports.TransactionSortField = TransactionSortField = {}));
var TransactionSource;
(function (TransactionSource) {
    TransactionSource["Eclaim"] = "ECLAIM";
    TransactionSource["Riayati"] = "RIAYATI";
})(TransactionSource || (exports.TransactionSource = TransactionSource = {}));
var TransactionTransactionStatus;
(function (TransactionTransactionStatus) {
    TransactionTransactionStatus["FullyApproved"] = "FULLY_APPROVED";
    TransactionTransactionStatus["PartiallyApproved"] = "PARTIALLY_APPROVED";
    TransactionTransactionStatus["Rejected"] = "REJECTED";
})(TransactionTransactionStatus || (exports.TransactionTransactionStatus = TransactionTransactionStatus = {}));
var TransactionVisitPrescriptionSortField;
(function (TransactionVisitPrescriptionSortField) {
    TransactionVisitPrescriptionSortField["CreatedDate"] = "CREATED_DATE";
})(TransactionVisitPrescriptionSortField || (exports.TransactionVisitPrescriptionSortField = TransactionVisitPrescriptionSortField = {}));
var TranscribeErrorCode;
(function (TranscribeErrorCode) {
    TranscribeErrorCode["ControlledDrug"] = "CONTROLLED_DRUG";
    TranscribeErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    TranscribeErrorCode["Ingested"] = "INGESTED";
    TranscribeErrorCode["Invalid"] = "INVALID";
    TranscribeErrorCode["NotFound"] = "NOT_FOUND";
    TranscribeErrorCode["Required"] = "REQUIRED";
    TranscribeErrorCode["Unique"] = "UNIQUE";
})(TranscribeErrorCode || (exports.TranscribeErrorCode = TranscribeErrorCode = {}));
var Type;
(function (Type) {
    Type["Boolean"] = "BOOLEAN";
    Type["Date"] = "DATE";
    Type["Float"] = "FLOAT";
    Type["Integer"] = "INTEGER";
    Type["String"] = "STRING";
})(Type || (exports.Type = Type = {}));
var UnverifiedNationalsIdType;
(function (UnverifiedNationalsIdType) {
    UnverifiedNationalsIdType["NationalCard"] = "NATIONAL_CARD";
    UnverifiedNationalsIdType["Passport"] = "PASSPORT";
    UnverifiedNationalsIdType["ResidentCard"] = "RESIDENT_CARD";
})(UnverifiedNationalsIdType || (exports.UnverifiedNationalsIdType = UnverifiedNationalsIdType = {}));
var UnverifiedNationalsStatus;
(function (UnverifiedNationalsStatus) {
    UnverifiedNationalsStatus["Approved"] = "APPROVED";
    UnverifiedNationalsStatus["Pending"] = "PENDING";
    UnverifiedNationalsStatus["Rejected"] = "REJECTED";
})(UnverifiedNationalsStatus || (exports.UnverifiedNationalsStatus = UnverifiedNationalsStatus = {}));
var UnverifiedNationalsStatusesEnum;
(function (UnverifiedNationalsStatusesEnum) {
    UnverifiedNationalsStatusesEnum["Approved"] = "APPROVED";
    UnverifiedNationalsStatusesEnum["Pending"] = "PENDING";
    UnverifiedNationalsStatusesEnum["Rejected"] = "REJECTED";
})(UnverifiedNationalsStatusesEnum || (exports.UnverifiedNationalsStatusesEnum = UnverifiedNationalsStatusesEnum = {}));
var UserGender;
(function (UserGender) {
    UserGender["Female"] = "FEMALE";
    UserGender["Male"] = "MALE";
})(UserGender || (exports.UserGender = UserGender = {}));
var UserNotificationField;
(function (UserNotificationField) {
    UserNotificationField["CreationDate"] = "CREATION_DATE";
    UserNotificationField["Seen"] = "SEEN";
    UserNotificationField["Type"] = "TYPE";
    UserNotificationField["UpdateDate"] = "UPDATE_DATE";
})(UserNotificationField || (exports.UserNotificationField = UserNotificationField = {}));
var UserSortField;
(function (UserSortField) {
    UserSortField["DateJoined"] = "DATE_JOINED";
    UserSortField["Email"] = "EMAIL";
    UserSortField["FirstName"] = "FIRST_NAME";
    UserSortField["LastMessageSent"] = "LAST_MESSAGE_SENT";
    UserSortField["LastName"] = "LAST_NAME";
    UserSortField["OrderCount"] = "ORDER_COUNT";
})(UserSortField || (exports.UserSortField = UserSortField = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "ACTIVE";
    UserStatus["Deactivated"] = "DEACTIVATED";
})(UserStatus || (exports.UserStatus = UserStatus = {}));
var UserVendorUserType;
(function (UserVendorUserType) {
    UserVendorUserType["DentalHygienist"] = "DENTAL_HYGIENIST";
    UserVendorUserType["DiabetesEducator"] = "DIABETES_EDUCATOR";
    UserVendorUserType["Doctor"] = "DOCTOR";
    UserVendorUserType["FitnessCoach"] = "FITNESS_COACH";
    UserVendorUserType["Manager"] = "MANAGER";
    UserVendorUserType["Nurse"] = "NURSE";
    UserVendorUserType["Nutritionist"] = "NUTRITIONIST";
    UserVendorUserType["Optometrist"] = "OPTOMETRIST";
    UserVendorUserType["Pharmacist"] = "PHARMACIST";
    UserVendorUserType["PodiatricMedicalAssistant"] = "PODIATRIC_MEDICAL_ASSISTANT";
    UserVendorUserType["Psychologist"] = "PSYCHOLOGIST";
    UserVendorUserType["Receptionist"] = "RECEPTIONIST";
    UserVendorUserType["SocialWorker"] = "SOCIAL_WORKER";
})(UserVendorUserType || (exports.UserVendorUserType = UserVendorUserType = {}));
var VendorBranchTypeEnum;
(function (VendorBranchTypeEnum) {
    VendorBranchTypeEnum["Clinic"] = "CLINIC";
    VendorBranchTypeEnum["DiagnosticCenter"] = "DIAGNOSTIC_CENTER";
    VendorBranchTypeEnum["Hospital"] = "HOSPITAL";
    VendorBranchTypeEnum["Pharmacy"] = "PHARMACY";
})(VendorBranchTypeEnum || (exports.VendorBranchTypeEnum = VendorBranchTypeEnum = {}));
var VendorDepartmentOrderField;
(function (VendorDepartmentOrderField) {
    VendorDepartmentOrderField["Branch"] = "BRANCH";
    VendorDepartmentOrderField["Code"] = "CODE";
    VendorDepartmentOrderField["Name"] = "NAME";
})(VendorDepartmentOrderField || (exports.VendorDepartmentOrderField = VendorDepartmentOrderField = {}));
var VendorErrorCode;
(function (VendorErrorCode) {
    VendorErrorCode["AlreadyExists"] = "ALREADY_EXISTS";
    VendorErrorCode["DuplicatedInputItem"] = "DUPLICATED_INPUT_ITEM";
    VendorErrorCode["GraphqlError"] = "GRAPHQL_ERROR";
    VendorErrorCode["Invalid"] = "INVALID";
    VendorErrorCode["NotFound"] = "NOT_FOUND";
    VendorErrorCode["NotVendorsImage"] = "NOT_VENDORS_IMAGE";
    VendorErrorCode["Required"] = "REQUIRED";
    VendorErrorCode["Unique"] = "UNIQUE";
})(VendorErrorCode || (exports.VendorErrorCode = VendorErrorCode = {}));
var VendorOrderField;
(function (VendorOrderField) {
    VendorOrderField["Created"] = "CREATED";
    VendorOrderField["Name"] = "NAME";
    VendorOrderField["Nearest"] = "NEAREST";
    VendorOrderField["OrderCount"] = "ORDER_COUNT";
    VendorOrderField["Rating"] = "RATING";
})(VendorOrderField || (exports.VendorOrderField = VendorOrderField = {}));
var VendorType;
(function (VendorType) {
    VendorType["Clinic"] = "CLINIC";
    VendorType["DiagnosticCenter"] = "DIAGNOSTIC_CENTER";
    VendorType["Hospital"] = "HOSPITAL";
    VendorType["Pharmacy"] = "PHARMACY";
})(VendorType || (exports.VendorType = VendorType = {}));
var VendorTypeEnum;
(function (VendorTypeEnum) {
    VendorTypeEnum["Clinic"] = "CLINIC";
    VendorTypeEnum["DiagnosticCenter"] = "DIAGNOSTIC_CENTER";
    VendorTypeEnum["Hospital"] = "HOSPITAL";
    VendorTypeEnum["Pharmacy"] = "PHARMACY";
})(VendorTypeEnum || (exports.VendorTypeEnum = VendorTypeEnum = {}));
var VendorUserTypes;
(function (VendorUserTypes) {
    VendorUserTypes["DentalHygienist"] = "DENTAL_HYGIENIST";
    VendorUserTypes["DiabetesEducator"] = "DIABETES_EDUCATOR";
    VendorUserTypes["Doctor"] = "DOCTOR";
    VendorUserTypes["FitnessCoach"] = "FITNESS_COACH";
    VendorUserTypes["Manager"] = "MANAGER";
    VendorUserTypes["Nurse"] = "NURSE";
    VendorUserTypes["Nutritionist"] = "NUTRITIONIST";
    VendorUserTypes["Optometrist"] = "OPTOMETRIST";
    VendorUserTypes["Pharmacist"] = "PHARMACIST";
    VendorUserTypes["PodiatricMedicalAssistant"] = "PODIATRIC_MEDICAL_ASSISTANT";
    VendorUserTypes["Psychologist"] = "PSYCHOLOGIST";
    VendorUserTypes["Receptionist"] = "RECEPTIONIST";
    VendorUserTypes["SocialWorker"] = "SOCIAL_WORKER";
})(VendorUserTypes || (exports.VendorUserTypes = VendorUserTypes = {}));
var VisitAttachmentType;
(function (VisitAttachmentType) {
    VisitAttachmentType["File"] = "FILE";
    VisitAttachmentType["MedicalReport"] = "MEDICAL_REPORT";
    VisitAttachmentType["TestResult"] = "TEST_RESULT";
})(VisitAttachmentType || (exports.VisitAttachmentType = VisitAttachmentType = {}));
var VisitCareType;
(function (VisitCareType) {
    /** Home Visit */
    VisitCareType["HomeVisit"] = "HOME_VISIT";
    /** In Person */
    VisitCareType["InPerson"] = "IN_PERSON";
    /** Virtual */
    VisitCareType["Virtual"] = "VIRTUAL";
})(VisitCareType || (exports.VisitCareType = VisitCareType = {}));
var VisitDiagnosisType;
(function (VisitDiagnosisType) {
    VisitDiagnosisType["Principal"] = "PRINCIPAL";
    VisitDiagnosisType["Secondary"] = "SECONDARY";
})(VisitDiagnosisType || (exports.VisitDiagnosisType = VisitDiagnosisType = {}));
var VisitPrescriptionActivityFrequencyType;
(function (VisitPrescriptionActivityFrequencyType) {
    VisitPrescriptionActivityFrequencyType["Day"] = "Day";
    VisitPrescriptionActivityFrequencyType["Hour"] = "Hour";
    VisitPrescriptionActivityFrequencyType["Month"] = "Month";
    VisitPrescriptionActivityFrequencyType["Week"] = "Week";
})(VisitPrescriptionActivityFrequencyType || (exports.VisitPrescriptionActivityFrequencyType = VisitPrescriptionActivityFrequencyType = {}));
var VisitPrescriptionActivityType;
(function (VisitPrescriptionActivityType) {
    VisitPrescriptionActivityType["GenericDrug"] = "GenericDrug";
    VisitPrescriptionActivityType["TradeDrug"] = "TradeDrug";
})(VisitPrescriptionActivityType || (exports.VisitPrescriptionActivityType = VisitPrescriptionActivityType = {}));
var VisitReferralSortField;
(function (VisitReferralSortField) {
    VisitReferralSortField["CreatedDate"] = "CREATED_DATE";
})(VisitReferralSortField || (exports.VisitReferralSortField = VisitReferralSortField = {}));
var VisitSortField;
(function (VisitSortField) {
    /** Sort visit by created. */
    VisitSortField["Created"] = "CREATED";
    /** Sort visit by date. */
    VisitSortField["Date"] = "DATE";
    /** Sort visit by number. */
    VisitSortField["Number"] = "NUMBER";
})(VisitSortField || (exports.VisitSortField = VisitSortField = {}));
var VisitStatus;
(function (VisitStatus) {
    VisitStatus["Accepted"] = "ACCEPTED";
    VisitStatus["Assigned"] = "ASSIGNED";
    VisitStatus["CallInProgress"] = "CALL_IN_PROGRESS";
    VisitStatus["CallRejoinable"] = "CALL_REJOINABLE";
    VisitStatus["CallTimedOut"] = "CALL_TIMED_OUT";
    VisitStatus["Canceled"] = "CANCELED";
    VisitStatus["CanceledByConsumer"] = "CANCELED_BY_CONSUMER";
    VisitStatus["ChatInProgress"] = "CHAT_IN_PROGRESS";
    VisitStatus["Completed"] = "COMPLETED";
    VisitStatus["DoctorAnswerTimedOut"] = "DOCTOR_ANSWER_TIMED_OUT";
    VisitStatus["EndedByOneOfTheParticipants"] = "ENDED_BY_ONE_OF_THE_PARTICIPANTS";
    VisitStatus["Incompleted"] = "INCOMPLETED";
    VisitStatus["OutPatientJourneyAssessmentDone"] = "OUT_PATIENT_JOURNEY_ASSESSMENT_DONE";
    VisitStatus["OutPatientJourneyAssessmentPending"] = "OUT_PATIENT_JOURNEY_ASSESSMENT_PENDING";
    VisitStatus["OutPatientJourneyCheckedIn"] = "OUT_PATIENT_JOURNEY_CHECKED_IN";
    VisitStatus["OutPatientJourneyConsultationDone"] = "OUT_PATIENT_JOURNEY_CONSULTATION_DONE";
    VisitStatus["OutPatientJourneyConsultationPending"] = "OUT_PATIENT_JOURNEY_CONSULTATION_PENDING";
    VisitStatus["OutPatientJourneyPending"] = "OUT_PATIENT_JOURNEY_PENDING";
    VisitStatus["OutPatientJourneyTreatmentDone"] = "OUT_PATIENT_JOURNEY_TREATMENT_DONE";
    VisitStatus["PaymentFailed"] = "PAYMENT_FAILED";
    VisitStatus["PaymentPending"] = "PAYMENT_PENDING";
    VisitStatus["PaymentSucceeded"] = "PAYMENT_SUCCEEDED";
    VisitStatus["PaymentTimedOut"] = "PAYMENT_TIMED_OUT";
    VisitStatus["Pending"] = "PENDING";
    VisitStatus["Rejected"] = "REJECTED";
})(VisitStatus || (exports.VisitStatus = VisitStatus = {}));
var VisitTranscriptionProcessingStatus;
(function (VisitTranscriptionProcessingStatus) {
    VisitTranscriptionProcessingStatus["Failed"] = "FAILED";
    VisitTranscriptionProcessingStatus["Pending"] = "PENDING";
    VisitTranscriptionProcessingStatus["Processed"] = "PROCESSED";
    VisitTranscriptionProcessingStatus["UnderProcess"] = "UNDER_PROCESS";
})(VisitTranscriptionProcessingStatus || (exports.VisitTranscriptionProcessingStatus = VisitTranscriptionProcessingStatus = {}));
var VisitType;
(function (VisitType) {
    VisitType["DirectAppointment"] = "DIRECT_APPOINTMENT";
    VisitType["DirectAtHome"] = "DIRECT_AT_HOME";
    VisitType["DirectCall"] = "DIRECT_CALL";
    VisitType["DirectInPerson"] = "DIRECT_IN_PERSON";
    VisitType["HealthProgram"] = "HEALTH_PROGRAM";
})(VisitType || (exports.VisitType = VisitType = {}));
var WeightUnit;
(function (WeightUnit) {
    WeightUnit["Gram"] = "GRAM";
    WeightUnit["Kilogram"] = "KILOGRAM";
    WeightUnit["Ounce"] = "OUNCE";
    WeightUnit["Pound"] = "POUND";
})(WeightUnit || (exports.WeightUnit = WeightUnit = {}));
var Period;
(function (Period) {
    Period["HalfYear"] = "HALF_YEAR";
    Period["Monthly"] = "MONTHLY";
    Period["ThreeMonths"] = "THREE_MONTHS";
    Period["Weekly"] = "WEEKLY";
    Period["Yearly"] = "YEARLY";
})(Period || (exports.Period = Period = {}));
