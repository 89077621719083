import { createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramBenefitGroupsAutocompleteQuery } from "./gql";
export const createHealthProgramBenefitGroupAutocompleteOption = (group) => {
    return createAutocompleteOption({ id: group === null || group === void 0 ? void 0 : group.id, name: group === null || group === void 0 ? void 0 : group.name }, "id", "name");
};
export const getHealthProgramBenefitGroupsAutocompleteFilter = ({ name, multiple }) => {
    return {
        name,
        multiple,
        type: "autocomplete",
        accessor: "healthProgramBenefitGroups",
        query: useHealthProgramBenefitGroupsAutocompleteQuery,
        getOptionLabel: option => option === null || option === void 0 ? void 0 : option.name,
        getValueForBackend: option => (option === null || option === void 0 ? void 0 : option.map(item => item === null || item === void 0 ? void 0 : item.id)) || [],
    };
};
