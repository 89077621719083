import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useBranchesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const createBranchAutocompleteOption = (branch) => {
    return createAutocompleteOption({
        id: branch === null || branch === void 0 ? void 0 : branch.id,
        name: branch === null || branch === void 0 ? void 0 : branch.name,
        nameAr: branch === null || branch === void 0 ? void 0 : branch.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getBranchesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "branches",
        query: useBranchesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
        filterSearchKey: "name_Icontains",
    });
};
