import { z } from "zod";
import { QuestionType } from "@health/queries/types";
import { MedicalFormQuestionOptionUpsertFormSchema } from "../MedicalFormQuestionOptionUpsert/MedicalFormQuestionOptionUpsertFormSchema";
import { zodEnumSchema } from "@health/enum-options";
export const medicalFormQuestionUpsertFormSchema = z
    .object({
    question: z.string().min(1).max(255),
    questionType: zodEnumSchema.questionType,
    required: z.boolean(),
    image: z.string().nullish(),
    options: z.array(MedicalFormQuestionOptionUpsertFormSchema),
})
    .superRefine((data, ctx) => {
    var _a;
    if (((_a = data.questionType) === null || _a === void 0 ? void 0 : _a.value) !== QuestionType.String && data.options.length === 0) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Options are required for non-string question types",
            path: ["options"],
        });
    }
});
export const medicalFormQuestionUpsertFormDefaultValues = {
    question: undefined,
    questionType: undefined,
    required: true,
    image: undefined,
    options: [],
};
