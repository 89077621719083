import { BulkProcessingStatus } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const bulkProcessingStatusOptionsMap = {
    [BulkProcessingStatus.Cancelled]: {
        key: BulkProcessingStatus.Cancelled,
        get label() {
            return i18n.t("Cancelled", { ns: "domains" });
        },
        value: BulkProcessingStatus.Cancelled,
    },
    [BulkProcessingStatus.Pending]: {
        key: BulkProcessingStatus.Pending,
        get label() {
            return i18n.t("Pending", { ns: "domains" });
        },
        value: BulkProcessingStatus.Pending,
    },
    [BulkProcessingStatus.Processed]: {
        key: BulkProcessingStatus.Processed,
        get label() {
            return i18n.t("Processed", { ns: "domains" });
        },
        value: BulkProcessingStatus.Processed,
    },
    [BulkProcessingStatus.UnderProcess]: {
        key: BulkProcessingStatus.UnderProcess,
        get label() {
            return i18n.t("Under Process", { ns: "domains" });
        },
        value: BulkProcessingStatus.UnderProcess,
    },
};
export const bulkProcessingStatusOptions = Object.values(bulkProcessingStatusOptionsMap);
