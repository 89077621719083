import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { GlobeIcon } from "@toolkit/ui";
import { citiesPaths, citiesRoute } from "./CitiesPaths";
import { CityListContainer, CityCreateContainer, CityUpdateContainer } from "../containers";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";

export const citiesRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }): RouteItem => {
  return {
    id: "cities-routes",
    text: i18n.t("Cities Management", { ns: "admin" }),
    icon: <GlobeIcon />,
    route: citiesRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageCities),
    subItems: [
      {
        id: "cities-list-route",
        text: "",
        route: citiesPaths.list.route,
        fullPath: citiesPaths.list.fullPath,
        element: <CityListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "cities-create-route",
        text: "",
        route: citiesPaths.create.route,
        fullPath: citiesPaths.create.fullPath,
        hidden: true,
        element: <CityCreateContainer />,
      },
      {
        id: "cities-update-route",
        text: "",
        route: citiesPaths.update.route,
        fullPath: citiesPaths.update.fullPath,
        hidden: true,
        element: <CityUpdateContainer />,
      },
    ],
  };
};
