import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { CityOrderField, OrderDirection } from "@health/queries/types";
import { useCityListQuery } from "../../gql";
import { citiesPaths } from "../../constants";
import { ICityListNode } from "../../types";
import { useSetCitiesBreadcrumbs } from "../../hooks";
import { useCityListContainerColumns } from "./useCityListContainerColumns";

export const CityListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(citiesPaths.create.fullPath);
  };

  const onEditRowClick = (item: ICityListNode) => {
    navigate(citiesPaths.update.fullPathWithParams({ cityId: item?.id }));
  };

  useSetCitiesBreadcrumbs("LIST");

  return (
    <GridProvider
      hasTableSetting
      gridName={"cityList"}
      query={useCityListQuery}
      columns={useCityListContainerColumns()}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          field: CityOrderField.Created,
          direction: OrderDirection.Desc,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
