import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { OptimaActionActivation } from "../../../OptimaActions/components/OptimaActionActivation/OptimaActionActivation";
export const useOptimaActionListContainerColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "id",
                header: t("ID"),
                accessor: "id",
            },
            {
                key: "action",
                header: t("Action"),
                accessor: "action",
            },
            {
                key: "link",
                header: t("Link"),
                accessor: "link",
            },
            {
                key: "active",
                header: t("Active"),
                accessor: row => _jsx(OptimaActionActivation, { id: row.id, isActive: !!(row === null || row === void 0 ? void 0 : row.isActive) }),
                filter: getAutocompleteEnumFilter("YesNo", "isActive"),
            },
        ];
    }, [t]);
};
