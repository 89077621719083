import { DoctorHealthProgramTotalPointsTrackingResponse } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, CustomTableColumnProps, Typography } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";
import { getDoctorsAutocompleteFilter, getHealthProgramsAutocompleteFilter } from "@health/autocompletes";

export type HealthProgramNetworkColumnTypes = CustomTableColumnProps<DoctorHealthProgramTotalPointsTrackingResponse>;
export type HealthProgramsColumnsTypes = HealthProgramNetworkColumnTypes[];

export const useProvidersActivityTrackingColumns = (): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");
  const fromDate = moment().startOf("month").format("YYYY-MM-DD");
  const toDate = moment().endOf("month").format("YYYY-MM-DD");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Enrolled Doctors"),
        accessor: ({ doctor }) =>
          doctor ? (
            <Box display={"flex"} alignItems='center'>
              <Avatar src={doctor?.user?.photo!} />
              <Typography sx={{ paddingInline: 1 }}>{doctor?.user?.firstName + " " + doctor?.user?.lastName}</Typography>
            </Box>
          ) : (
            "-"
          ),
        type: "string",
      },
      {
        key: "normal-Demand",
        header: t("Calls - Normal Demand"),
        accessor: ({ pointsDuringNormalDemand }) => pointsDuringNormalDemand || "-",
        type: "string",
      },
      {
        key: "urgent-demand",
        header: t("Calls - Urgent Demand"),
        accessor: ({ pointsDuringUrgentDemand }) => pointsDuringUrgentDemand || "-",
        type: "string",
      },
      {
        key: "Call Points",
        header: t("Call Points"),
        accessor: ({ callQualityRatingFactor }) => callQualityRatingFactor || "-",
        type: "string",
      },
      {
        key: "Total Points",
        header: t("Total Points"),
        accessor: ({ totalPoints }) => totalPoints || "-",
        type: "string",
      },
      {
        key: "Availability Hours",
        header: t("Availability Hours"),
        accessor: ({ availabilityRatingFactor }) => availabilityRatingFactor || "-",
        type: "string",
      },
      {
        key: "Specialization Factor",
        header: t("Specialization Factor"),
        accessor: ({ mainSpecializationsRateFactor }) => mainSpecializationsRateFactor || "-",
        type: "string",
      },
      {
        key: "healthPrograms",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: getHealthProgramsAutocompleteFilter({ name: "healthProgramId" }),
      },
      {
        key: "Doctors",
        header: t("Doctors"),
        showOnlyForFilterField: true,
        filter: getDoctorsAutocompleteFilter({ name: "doctorId" }),
      },
      {
        key: "fromDate",
        header: t("From Date"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "fromDate",
          initialValue: fromDate,
        },
      },
      {
        key: "toDate",
        header: t("To Date"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "toDate",
          initialValue: toDate,
        },
      },
    ];
  }, []);
};
