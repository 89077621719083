import { MarketplaceGender } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const marketplaceGenderOptionsMap = {
    [MarketplaceGender.Male]: {
        key: MarketplaceGender.Male,
        get label() {
            return i18n.t("Male", { ns: "domains" });
        },
        value: MarketplaceGender.Male,
    },
    [MarketplaceGender.Female]: {
        key: MarketplaceGender.Female,
        get label() {
            return i18n.t("Female", { ns: "domains" });
        },
        value: MarketplaceGender.Female,
    },
    [MarketplaceGender.All]: {
        key: MarketplaceGender.All,
        get label() {
            return i18n.t("All", { ns: "domains" });
        },
        value: MarketplaceGender.All,
    },
};
export const marketplaceGenderOptions = Object.values(marketplaceGenderOptionsMap);
