import { articleCategorySchema, articleSchema, branchSchema, citySchema, decisionPlanSchema, departmentSchema, doctorPatientSchema, doctorSchema, doctorTeamLeaderSchema, guidedCareProgramSchema, guidedCareTeamSchema, guidedCareTeamUserSchema, guidedCareTemplateSchema, healthConditionSchema, healthParameterCategorySchema, healthParameterSchema, healthParameterUnitSchema, healthProgramBenefitGroupSchema, healthProgramMemberListSchema, healthProgramMemberSchema, healthProgramNetworkSchema, healthProgramSchema, languageSchema, marketplaceBrandSchema, marketplaceHealthPackageCategorySchema, marketplaceHealthPackageSchema, marketplaceProductCategorySchema, marketplaceProductSchema, medicalFormClassificationSchema, medicalFormSchema, medicalMessageSchema, optimaActionSchema, patientSchema, payerSchema, riskFactorTemplateSchema, sadaVideoSchema, subListSchema, systemCodeSchema, userSchema, vendorDepartmentSchema, vendorSchema, } from "../components";
export const zodSchema = {
    article: articleSchema,
    articleCategory: articleCategorySchema,
    branch: branchSchema,
    city: citySchema,
    decisionPlan: decisionPlanSchema,
    department: departmentSchema,
    doctor: doctorSchema,
    doctorPatient: doctorPatientSchema,
    doctorTeamLeader: doctorTeamLeaderSchema,
    guidedCareProgram: guidedCareProgramSchema,
    guidedCareTeam: guidedCareTeamSchema,
    guidedCareTeamUser: guidedCareTeamUserSchema,
    guidedCareTemplate: guidedCareTemplateSchema,
    healthCondition: healthConditionSchema,
    healthParameter: healthParameterSchema,
    healthParameterCategory: healthParameterCategorySchema,
    healthParameterUnit: healthParameterUnitSchema,
    healthProgram: healthProgramSchema,
    healthProgramBenefitGroup: healthProgramBenefitGroupSchema,
    healthProgramMember: healthProgramMemberSchema,
    healthProgramMemberList: healthProgramMemberListSchema,
    healthProgramNetwork: healthProgramNetworkSchema,
    language: languageSchema,
    marketplaceBrand: marketplaceBrandSchema,
    marketplaceHealthPackage: marketplaceHealthPackageSchema,
    marketplaceHealthPackageCategory: marketplaceHealthPackageCategorySchema,
    marketplaceProduct: marketplaceProductSchema,
    marketplaceProductCategory: marketplaceProductCategorySchema,
    medicalForm: medicalFormSchema,
    medicalFormClassification: medicalFormClassificationSchema,
    medicalMessage: medicalMessageSchema,
    optimaAction: optimaActionSchema,
    patient: patientSchema,
    payer: payerSchema,
    riskFactorTemplate: riskFactorTemplateSchema,
    sadaVideo: sadaVideoSchema,
    subList: subListSchema,
    systemCode: systemCodeSchema,
    user: userSchema,
    vendor: vendorSchema,
    vendorDepartment: vendorDepartmentSchema,
};
