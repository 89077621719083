import React, { FC } from "react";
import { SvgIcon, useTheme } from "../../base/mui";
import { IconProps } from "./types";

export const AlphaFlag: FC<IconProps> = ({ width = "24.001", height = "26", viewBox = "0 0 24.001 26", ...props }) => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-alpha-flag_1_'
        data-name='icons8-alpha-flag (1)'
        d='M5,2A2,2,0,0,0,4,5.73V27a1,1,0,1,0,2,0V5.729A2,2,0,0,0,5,2ZM9,6A1,1,0,0,0,8,7V21a1,1,0,0,0,1,1H26a1,1,0,0,0,.869-1.5L23.152,14l3.717-6.5A1,1,0,0,0,26,6Zm1,2h6V20H10Z'
        transform='translate(-3 -2)'
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
