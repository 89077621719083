import { OptimaPriority } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const optimaPriorityOptionsMap = {
    [OptimaPriority.Low]: {
        key: OptimaPriority.Low,
        get label() {
            return i18n.t("Low", { ns: "domains" });
        },
        value: OptimaPriority.Low,
    },
    [OptimaPriority.Medium]: {
        key: OptimaPriority.Medium,
        get label() {
            return i18n.t("Medium", { ns: "domains" });
        },
        value: OptimaPriority.Medium,
    },
    [OptimaPriority.High]: {
        key: OptimaPriority.High,
        get label() {
            return i18n.t("High", { ns: "domains" });
        },
        value: OptimaPriority.High,
    },
};
export const optimaPriorityOptions = Object.values(optimaPriorityOptionsMap);
