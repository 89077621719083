import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const HealthProgramsAutocompleteDocument = gql `
    query HealthProgramsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramFilterInput) {
  healthPrograms(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;
/**
 * __useHealthProgramsAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthProgramsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramsAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(HealthProgramsAutocompleteDocument, options);
}
export function useHealthProgramsAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(HealthProgramsAutocompleteDocument, options);
}
