/* eslint-disable sonarjs/no-nested-template-literals */
import { useTranslation } from "@toolkit/i18n";
import moment, { Moment } from "moment";

export const getHelperText = (min: Moment, value: string, time: string, typeTime: string, max?: Moment): string => {
  const { t } = useTranslation();
  const momentValue = moment(value, "HH:mm:ss");
  if (value && !momentValue.isValid()) {
    return t("HH:MM (AM|PM)");
  }
  if (min && momentValue.isSameOrBefore(min)) {
    const minTime = min.format("hh:mm A");
    const translatedMessage = t("timeAfter", { time: minTime });
    return `${t(translatedMessage)}`;
  }
  if (max && momentValue.isSameOrAfter(max)) {
    const maxTime = max.format("hh:mm A");
    return t("timeBefore", { time: maxTime });
  }
  if (!value && time) {
    return `${t(`Please Enter ${typeTime}`)}`;
  }
  return "";
};
