import { z } from "zod";
import { medicalFormClassificationUpsertFormSchema } from "../MedicalFormClassificationUpsert/MedicalFormClassificationUpsertFormSchema";
import { medicalFormQuestionUpsertFormSchema } from "../MedicalFormQuestionUpsert/MedicalFormQuestionUpsertFormSchema";
import { zodEnumSchema } from "@health/enum-options";
import { zodSchema } from "@health/autocompletes";
export const medicalFormUpsertFormSchema = z.object({
    name: z.string().min(1).max(50),
    nameAr: z.string().min(1).max(50),
    code: z.string().min(1).max(50),
    type: zodEnumSchema.medicalFormType,
    category: zodEnumSchema.medicalFormCategory,
    welcomeCard: z.string().nullish(),
    welcomeCardAr: z.string().nullish(),
    description: z.string().min(1).max(255),
    department: zodSchema.department.nullish(),
    active: z.boolean(),
    questions: z.array(medicalFormQuestionUpsertFormSchema).optional(),
    medicalFormClassifications: z.array(medicalFormClassificationUpsertFormSchema).optional(),
});
export const medicalFormUpsertFormDefaultValues = {
    name: undefined,
    nameAr: undefined,
    code: undefined,
    type: undefined,
    category: undefined,
    welcomeCard: undefined,
    welcomeCardAr: undefined,
    description: undefined,
    department: undefined,
    active: false,
    questions: [],
    medicalFormClassifications: [],
};
