import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { citiesPaths } from "../constants";

export const useSetCitiesBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Cities Management");

  const cityListBreadcrumb = useMemo(() => {
    return {
      name: t("Cities"),
      route: citiesPaths.list.fullPath,
    };
  }, [t]);

  const setCityListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setCityCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [cityListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, cityListBreadcrumb, t]);

  const setCityUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [cityListBreadcrumb, { name: name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, cityListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setCityListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setCityCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setCityUpdateContainerBreadcrumb();
    }
  }, [containerType, setCityListContainerBreadcrumb, setCityCreateContainerBreadcrumb, setCityUpdateContainerBreadcrumb]);
};
