import { HealthProgramNetworkProvider, PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps } from "@toolkit/ui";
import { networksPaths } from "pages/HealthProgramNetworks/constants/networks-paths";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActiveToggleSwitch } from "./ActiveToggle";
import { getAutocompleteEnumFilter } from "@health/enum-options";

export type HealthProgramNetworkProvidersColumnTypes = CustomTableColumnProps<HealthProgramNetworkProvider>;
export type HealthProgramsColumnsTypes = HealthProgramNetworkProvidersColumnTypes[];

export const useHealthProgramNetworkProvidersColumns = ({ healthProgramNetworkID }): HealthProgramsColumnsTypes => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const params = useParams();
  const canManageActions = hasPermission(PermissionEnum.ManageHealthPrograms);

  const handleGoToNetworkProviderProfessionalsPath = id => () => {
    navigate(networksPaths.providersProfessionalsPath.fullPathWithParams(params?.id!, id));
  };

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ provider, id }) => (
          <Box onClick={handleGoToNetworkProviderProfessionalsPath(id)} sx={{ textDecoration: "underline", cursor: "pointer" }}>
            {provider?.name}
          </Box>
        ),
        type: "string",
      },
      {
        key: "licenseNumber",
        header: t("License ID"),
        accessor: ({ provider }) => provider?.taxLicenseNumber,
      },
      {
        key: "location",
        header: t("Location"),
        accessor: ({ provider }) => provider?.address?.streetAddress1 + " " + provider?.address?.streetAddress2,
        type: "string",
      },
      {
        key: "contactPhoneNumber",
        header: t("Contact Provider"),
        accessor: ({ provider }) => provider?.contactPhoneNumber,
      },
      {
        key: "city",
        header: t("City"),
        accessor: ({ provider }) => provider?.address?.city?.name,
        type: "string",
      },
      {
        key: "professional",
        header: t("Professionals"),
        accessor: ({ provider }) => (
          <Box sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleGoToNetworkProviderProfessionalsPath(provider?.id)}>
            {t("professional")}
          </Box>
        ),
        type: "string",
      },
      {
        key: "virtualCallEnabled",
        header: t("Virtual Call"),
        type: "string",
        accessor: row => {
          return (
            <Box sx={{ display: "flex" }}>
              <ActiveToggleSwitch
                isDisabled={!canManageActions || !row?.provider?.isActive}
                id={row?.id!}
                isActive={{ value: row?.virtualCallEnabled!, name: "virtualCallEnabled" }}
                updateRequiredInputs={{
                  healthProgramNetwork: healthProgramNetworkID,
                  providerId: row?.provider?.id,
                }}
              />
            </Box>
          );
        },
        filter: getAutocompleteEnumFilter("YesNo", "virtualCallEnabled"),
      },
      {
        key: "isActive",
        header: t("Active"),
        type: "string",
        accessor: row => {
          return (
            <Box sx={{ display: "flex" }}>
              <ActiveToggleSwitch
                updateRequiredInputs={{
                  healthProgramNetwork: healthProgramNetworkID,
                  providerId: row?.provider?.id,
                }}
                isDisabled={!canManageActions || !row?.provider?.isActive}
                id={row?.id!}
                isActive={{ value: row?.isActive!, name: "isActive" }}
              />
            </Box>
          );
        },
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, []);
};
