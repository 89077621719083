import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useOptimaActionsAutocompleteQuery } from "./gql";
export const createOptimaActionAutocompleteOption = (optimaAction) => {
    return createAutocompleteOption({ id: optimaAction === null || optimaAction === void 0 ? void 0 : optimaAction.id, action: optimaAction === null || optimaAction === void 0 ? void 0 : optimaAction.action }, "id", "action");
};
export const getOptimaActionsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "optimaActions",
        query: useOptimaActionsAutocompleteQuery,
        labelBy: "action",
        backendAccessor: "id",
    });
};
