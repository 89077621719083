import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { useCityCreateMutation } from "../../gql";
import { citiesPaths } from "../../constants";
import { convertCityFormValuesToBackEndValues } from "../../others";
import { useSetCitiesBreadcrumbs } from "../../hooks";
import { CityUpsertForm, ICityUpsertFormEvent } from "../../forms/CityUpsert/CityUpsertForm";

export const CityCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createCity, { loading: isSubmitting }] = useCityCreateMutation({
    onCompleted: () => {
      succeeded(t("City created successfully"));
      navigate(citiesPaths.list.fullPath);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onCityUpsertFormChange = (event: ICityUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertCityFormValuesToBackEndValues(event.payload.values);

      createCity({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetCitiesBreadcrumbs("CREATE");

  return <CityUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onCityUpsertFormChange} />;
};
