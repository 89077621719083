var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React from "react";
import { useGuidedCareTeamMembersUsersQuery } from "./gql";
export const GuidedCareTeamUsersAutocomplete = props => {
    var _a, _b;
    const { label, filter, skip } = props, rest = __rest(props, ["label", "filter", "skip"]);
    const { t } = useTranslation("domains");
    const { data, loading } = useGuidedCareTeamMembersUsersQuery({
        variables: {
            id: filter === null || filter === void 0 ? void 0 : filter.teamId,
        },
        skip: props.disabled || skip,
    });
    const _data = ((_b = (_a = data === null || data === void 0 ? void 0 : data.providerGuidedCareHealthProgramTeam) === null || _a === void 0 ? void 0 : _a.teamMembers) === null || _b === void 0 ? void 0 : _b.map(item => ({
        id: item === null || item === void 0 ? void 0 : item.id,
        user: item === null || item === void 0 ? void 0 : item.user,
    }))) || [];
    const options = mapToAutocompleteOptions(_data, "id", item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.fullName; });
    return React.createElement(FormAutocomplete, Object.assign({}, rest, { loading: loading, options: options, label: label || (props.multiple ? t("Users") : t("User")) }));
};
