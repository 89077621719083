import { jsx as _jsx } from "react/jsx-runtime";
import { getDecisionPlansAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { DecisionMakerOrderDirection, DecisionSortField } from "@health/queries/types";
import { DecisionActivation } from "../../../Decisions/components";
import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
export const useDecisionListTableColumns = ({ category }) => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "Number",
                header: t("Rule Name"),
                accessor: ({ name }) => name,
                type: "string",
                disableToggleVisibility: true,
                isSortable: true,
                sortDirection: DecisionMakerOrderDirection.Asc,
                sortColumnEnum: DecisionSortField.Name,
                filter: {
                    type: "string",
                    name: "decisionName",
                },
            },
            {
                key: "plan",
                header: t("Decision Plan"),
                accessor: ({ decisionPlan }) => t(decisionPlan === null || decisionPlan === void 0 ? void 0 : decisionPlan.display),
                type: "string",
                isSortable: true,
                sortDirection: undefined,
                sortColumnEnum: DecisionSortField.DecisionPlan,
                filter: getDecisionPlansAutocompleteFilter({ name: "plan", filter: { categories: [category] } }),
            },
            {
                key: "type",
                header: t("Type"),
                accessor: ({ isScript }) => (isScript ? t("Script") : t("Dynamic")),
                type: "string",
                disableToggleVisibility: true,
                isSortable: true,
                sortDirection: undefined,
                sortColumnEnum: DecisionSortField.Type,
            },
            {
                key: "active",
                header: t("Active"),
                accessor: ({ id, isActive }) => _jsx(DecisionActivation, { id: id, isActive: !!isActive }),
                type: "string",
                hideFromSettings: true,
                filter: getAutocompleteEnumFilter("YesNo", "isActive"),
            },
        ];
    }, []);
};
