import React, { FC } from "react";
import { SvgIcon, type SvgIconProps, useTheme } from "../../base/mui";

export const LocationNewIcon: FC<SvgIconProps> = ({ fill, width, height, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "14px"}
      height={height ? height : "18px"}
      viewBox='0 0 14.485 17.772'
      {...props}
    >
      <path
        id='icons8-location_1_'
        data-name='icons8-location (1)'
        d='M11.242,2A7.242,7.242,0,0,0,4,9.242c0,4.763,4.709,7.043,5.3,7.573a6.116,6.116,0,0,1,1.3,2.445.66.66,0,0,0,1.285,0,6.12,6.12,0,0,1,1.3-2.445c.59-.531,5.3-2.811,5.3-7.573A7.242,7.242,0,0,0,11.242,2Zm0,9.217a1.975,1.975,0,1,1,1.975-1.975A1.975,1.975,0,0,1,11.242,11.217Z'
        transform='translate(-4 -2)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
