import { z } from "zod";

export const BrandUpsertFormSchema = z.object({
  name: z.string().min(3).max(255),
  nameAr: z.string().min(3).max(255).nullish(),
  image: z.string().nullish(),
});

export type IBrandUpsertFormValues = z.infer<typeof BrandUpsertFormSchema>;

export const brandUpsertFormSchemaDefaultValues: Partial<IBrandUpsertFormValues> = {
  name: undefined,
  nameAr: undefined,
  image: undefined,
};
