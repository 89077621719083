import React, { FC } from "react";
import { SvgIcon } from "../../base/mui";
import { IconProps } from "./types";

export const Users: FC<IconProps> = ({ width = "30", height = "19", viewBox = "0 0 30 19", fill, ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <path
        id='icons8-users'
        d='M15,6c-3.182,0-4.5,2.041-4.5,4.5a4.463,4.463,0,0,0,.4,1.66.882.882,0,0,0-.357.9c.123.962.541,1.2.807,1.225a3.919,3.919,0,0,0,1.4,2.215v1.342C12,20.092,6,19.75,6,25H24c0-5.25-6-4.908-6.75-7.158V16.5a3.914,3.914,0,0,0,1.4-2.215c.266-.02.684-.263.807-1.225a.881.881,0,0,0-.357-.9,3.958,3.958,0,0,0,.4-1.66c0-1.821-.715-3.375-2.25-3.375A2.386,2.386,0,0,0,15,6Zm7.5,2a4.2,4.2,0,0,0-1.348.225A7.375,7.375,0,0,1,21.5,10.5a5.7,5.7,0,0,1-.189,1.428,3.091,3.091,0,0,1,.133,1.385,3.577,3.577,0,0,1-1.105,2.32,6.189,6.189,0,0,1-.6,1.078,3.514,3.514,0,0,0,.887,1.207v.012C22.662,18.885,26,20.49,26,25h4c0-4.958-5-4.246-5.625-6.371v-.713a3.769,3.769,0,0,0,1.17-2.092c.221-.019.57-.247.672-1.156a.879.879,0,0,0-.3-.85,4.149,4.149,0,0,0,.33-1.568c0-1.72-.6-3.187-1.875-3.187A1.971,1.971,0,0,0,22.5,8ZM7.5,8A1.971,1.971,0,0,0,5.625,9.063c-1.279,0-1.875,1.469-1.875,3.189a4.126,4.126,0,0,0,.33,1.566.876.876,0,0,0-.3.85c.1.909.451,1.139.672,1.158a3.77,3.77,0,0,0,1.17,2.092v.711C5,20.753,0,20.042,0,25H4c0-4.51,3.338-6.114,5.375-7.07v-.014a3.49,3.49,0,0,0,.887-1.205,6.206,6.206,0,0,1-.6-1.08,3.567,3.567,0,0,1-1.105-2.318,3.11,3.11,0,0,1,.137-1.4A5.612,5.612,0,0,1,8.5,10.5a7.74,7.74,0,0,1,.33-2.281A4.19,4.19,0,0,0,7.5,8Z'
        transform='translate(0 -6)'
        fill={fill ? fill : "#e67e22"}
      />
    </SvgIcon>
  );
};
