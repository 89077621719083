import { Branch, BranchOrderField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, Tooltip, Typography } from "@toolkit/ui";
import { AddressInformation } from "shared/components/AddressModel/AddressInformation.component";
import { ActiveSwitch } from "../components/ActiveSwitch.components";
import { UsersList } from "../components/UsersList.components";
import { WasfastyCredentialsDialog } from "../components/WasfastyCredentials.component";
import { WorkingHoursBranch } from "../components/WorkingHoursBranch.component";
import { getAutocompleteEnumFilter, vendorTypeOptionsMap } from "@health/enum-options";

const multiLineAccessor = (key: keyof Branch) => {
  return row => {
    const valueLines = row?.[key]?.split("\\n") || [];
    return (
      <Box sx={{ width: "130px" }}>
        <Tooltip title={row?.name ?? ""}>
          <span>
            {valueLines.map(item => (
              <Typography noWrap key={item} component={"div"}>
                {item}
              </Typography>
            ))}
          </span>
        </Tooltip>
      </Box>
    );
  };
};

export const useGetBranchesColumns = (canManageBranches: boolean): CustomTableColumnProps<Branch>[] => {
  const { t } = useTranslation("admin");
  return [
    {
      key: "nameFilter",
      header: t("Name"),
      showOnlyForFilterField: true,
      hideFromSettings: true,
      filter: {
        type: "string",
        name: "name_Icontains",
      },
    },
    {
      key: "name",
      header: t("Name"),
      isHidden: false,
      isSortable: true,
      sortDirection: undefined,
      type: "string",
      hideFromSettings: true,
      disableToggleVisibility: true,
      sortColumnEnum: BranchOrderField.Name,
      accessor: multiLineAccessor("name"),
    },
    {
      key: "description",
      header: t("Description"),
      filter: {
        type: "string",
        name: "description_Icontains",
      },
      accessor: multiLineAccessor("description"),
    },
    {
      key: "credential",
      header: t("eRxHub Credential"),
      accessor: row => <WasfastyCredentialsDialog row={row} />,
    },
    {
      key: "address",
      header: t("Address"),
      accessor: row => {
        return <AddressInformation address={row?.address} />;
      },
      isHidden: false,
    },
    {
      key: "contactNumber",
      header: t("Contact Number"),
      accessor: "contactNumber",
      filter: {
        type: "string",
        name: "contactNumber_Icontains",
      },
    },
    {
      key: "healthLicense",
      header: t("Health License"),
      accessor: "healthLicense",
    },
    {
      key: "types",
      header: t("Type"),
      accessor: ({ type }) => vendorTypeOptionsMap[type!]?.label || "-",
      filter: getAutocompleteEnumFilter("VendorType", "type"),
    },
    {
      key: "users",
      header: t("Users"),
      accessor: ({ id, vendor }) => <UsersList disabled={!canManageBranches} id={id} vendorId={vendor?.id} />,
    },
    {
      key: "active",
      header: t("Active"),
      accessor: row => <ActiveSwitch id={row?.id} isActive={row?.isActive} disabled={!row?.vendor?.isActive || !canManageBranches} />,
      isHidden: false,
      disableToggleVisibility: false,
      filter: getAutocompleteEnumFilter("YesNo", "isActive"),
    },
    {
      key: "workingHours",
      header: t("Working Hours"),
      accessor: row => <WorkingHoursBranch branch={row} />,
    },
    {
      key: "acceptsDelivery",
      header: t("Accepts Delivery"),
      showOnlyForFilterField: true,
      filter: getAutocompleteEnumFilter("YesNo", "acceptsDelivery"),
    },
  ];
};
